import { useCallback, useMemo } from 'react';
import { useLocalStorage } from '@emdgroup/react-storage';
import _ from 'lodash';
import ChangelogData from 'assets/data/changelog.json';

const useWhatsNew = () => {
  const [lastViewed, setlastViewed] = useLocalStorage<string>('whats-new');
  const entriesByVersion = useMemo(() => {
    const versioned = ChangelogData.map((entry) => {
      const version = entry.version.split('.');
      return {
        major: version[0],
        minor: version[1],
        patch: version[2],
        ...entry,
      };
    });
    return _.orderBy(
      versioned,
      ['major', 'minor', 'patch'],
      ['desc', 'desc', 'desc']
    );
  }, []);
  const getEntryByVersion = (version: string) => {
    return ChangelogData.find((entry) => entry.version === version);
  };

  const getEntrieSinceVersion = useCallback(
    (version: string) => {
      const index = entriesByVersion.findIndex(
        (entry) => entry.version === version
      );
      if (index < 0) return [];
      return entriesByVersion.slice(0, index);
    },
    [entriesByVersion]
  );

  const newEntries = useMemo(() => {
    if (!lastViewed) return entriesByVersion;
    return getEntrieSinceVersion(lastViewed);
  }, [lastViewed, getEntrieSinceVersion, entriesByVersion]);

  const hasNewEntries = newEntries.length > 0;

  const latestVersion = entriesByVersion[0].version;

  return {
    getEntryByVersion,
    getEntrieSinceVersion,
    latestVersion,
    lastViewed,
    newEntries,
    hasNewEntries,
    setlastViewed,
  };
};

export { useWhatsNew as default };
