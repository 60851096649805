import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  LdBadge,
  LdButton,
  LdIcon,
  LdModal,
  LdTypo,
} from '@emdgroup-liquid/liquid/dist/react';
import ReactTooltip from 'react-tooltip';
import { useHints } from '../../../hooks/useHints';
import ShareForm from './ShareForm';
import InvitedUsers from './InvitedUsers';
import { useDispatch } from '../../../store';
import { listInvitationsForProject } from '../../../services/projectInvitations';

interface ShareProjectProps {
  projectId?: string;
  onSubmit?: () => void;
}

const ShareProject: React.FC<ShareProjectProps> = ({ projectId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getToolTipTextById } = useHints();

  const dispatch = useDispatch();

  useEffect(() => {
    if (projectId && isModalOpen) {
      dispatch(listInvitationsForProject(projectId));
    }
  }, [projectId, isModalOpen, dispatch]);

  const handleOpenModalClick = useCallback<
    MouseEventHandler<HTMLLdButtonElement>
  >(
    (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
      setIsModalOpen(true);
    },
    [projectId]
  );

  const handleCancel = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  if (!projectId) {
    return null;
  }

  return (
    <>
      <LdButton
        mode="secondary"
        onClick={handleOpenModalClick}
        data-for="share-tooltip"
        data-tip={getToolTipTextById('Sha')}
      >
        <LdIcon name="share" aria-label="share project" />
        Share
      </LdButton>

      <ReactTooltip
        id="share-tooltip"
        type="light"
        effect="solid"
        className="tooltip"
      />

      <LdModal
        open={isModalOpen}
        onLdmodalclosed={handleCancel}
        style={{ '--ld-modal-min-inline-size': '40rem' }}
      >
        <div slot="header" className="flex items-center gap-ld-4 py-ld-8">
          <LdBadge icon="attention" size="lg" class="ld-badge--info" />
          <LdTypo variant="h4">Share the project</LdTypo>
        </div>
        <div className="relative flex flex-col gap-ld-4">
          <ShareForm projectId={projectId} />

          <InvitedUsers projectId={projectId} />

          <div
            slot="footer"
            className="flex flex-row items-end justify-end gap-x-ld-8"
          >
            <LdButton onClick={handleCancel} mode="secondary">
              Close
            </LdButton>
          </div>
        </div>
      </LdModal>
    </>
  );
};

export default ShareProject;
