import useWebSocket from 'react-use-websocket';
import { useUser } from '@emdgroup/react-auth';
import { useDispatch } from 'store';
import { addRecommendation } from 'services/recommendations';
import _ from 'lodash';
import { setProject, setProjectStatus } from 'services/projects';
import { useState } from 'react';
import { addExperiment } from 'services/experiments';

const wsEndpoint = process.env.REACT_APP_WS_ENDPOINT || '';

type Props = {
  projectId: string;
};

const useWebsocket = ({ projectId }: Props) => {
  const { authHeader } = useUser();
  const dispatch = useDispatch();
  const [isBusy, setBusy] = useState(false);

  const _handleWSMessage = (event: MessageEvent) => {
    setBusy(true);
    const data = JSON.parse(event.data);

    if (data?.['item'].projectId === projectId) {
      if (data.itemType == 'recommendation') {
        if (data.item.status == 'created')
          dispatch(
            addRecommendation({ projectId, recommendations: data.item })
          );
      } else if (data.itemType == 'project') {
        dispatch(setProject(data.item));
        dispatch(setProjectStatus({ projectId, status: data.item.status }));
      } else if (data.itemType == 'experiment') {
        if ((data.item.status = 'running'))
          dispatch(addExperiment({ projectId, experiments: data.item }));
      }
    }
    setBusy(false);
  };

  useWebSocket(wsEndpoint, {
    queryParams: {
      token: authHeader?.['Authorization'].split(' ')[1] || '',
    },
    onMessage: _handleWSMessage,
    shouldReconnect: () => true,
    heartbeat: true,
  });

  return {
    loading: isBusy,
  };
};

export default useWebsocket;
