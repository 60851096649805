import React from 'react';
import { Modal } from '@liquid-design/liquid-design-react';
import ChemicalEditor from '../../ChemicalEditor';

interface MoleculeEditorModalProps {
  isVisible: boolean;
  onClose: () => void;
  smiles?: string;
  onCancel: () => void;
  onSave: (smiles: string) => void;
}

export const MoleculeEditorModal: React.FC<MoleculeEditorModalProps> = ({
  isVisible,
  onClose,
  smiles,
  onCancel,
  onSave,
}) => {
  return (
    <Modal
      label="Molecule Editor"
      open={isVisible}
      className="editor-modal"
      onClose={onClose}
      reactModalProps={{
        style: {
          content: {
            top: 20,
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, 0)',
            padding: 0,
            width: '768px',
            // height: '500px',
          },
        },
      }}
    >
      <div className="relative flex w-full">
        <ChemicalEditor smiles={smiles} onCancel={onCancel} onSave={onSave} />
      </div>
    </Modal>
  );
};
