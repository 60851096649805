import {
  ProjectStatus,
  Experiment,
  Parameter,
  Project,
  ProjectRole,
  TargetVariable,
} from 'types/api/types';

export const calculateCombinations = (parameterSpace: Parameter[]) => {
  const total = parameterSpace.reduce((prev, curr) => {
    return prev * curr.parameterValues.length;
  }, 1);
  return total;
};

export const parseTarget = (target: TargetVariable) => {
  const copy = { ...target };
  if (typeof copy.targetWeight !== 'number') {
    copy.targetWeight = parseFloat(copy.targetWeight || '1');
  }
  return copy;
};

export const calculateProgress = ({
  project,
  experiments,
}: {
  project: { parameterSpace: Parameter[] };
  experiments: Experiment[];
}) => {
  const total = calculateCombinations(project.parameterSpace);
  const performed = experiments.length;
  return {
    performed,
    total,
  };
};

export const hasAtLeastRole = (role: ProjectRole, project?: Project) => {
  switch (role) {
    case ProjectRole.Viewer:
      return project !== undefined;
    case ProjectRole.Editor:
      return (
        project?.role === ProjectRole.Editor ||
        project?.role === ProjectRole.Owner
      );
    case ProjectRole.Owner:
      return project?.role === ProjectRole.Owner;
  }
};

export const isBusy = (project: Project) =>
  [ProjectStatus.creating, ProjectStatus.running].includes(project.status);
