import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ParameterInputCustom } from '../ParameterValuesForm/ParameterInputCustom';
import {
  ParameterType,
  SmilesParameterValue,
  TargetVariable,
} from '../../../types/api/types';
import {
  LdButton,
  LdInputMessage,
  LdLabel,
  LdOption,
  LdSelect,
} from '@emdgroup-liquid/liquid/dist/react';

type Parameter =
  | {
      parameterName: string;
      parameterType: ParameterType.smiles;
      parameterValues: SmilesParameterValue[];
    }
  | {
      parameterName: string;
      parameterType: ParameterType.numeric;
      parameterValues: number[];
    }
  | {
      parameterName: string;
      parameterType: ParameterType.category | ParameterType.custom;
      parameterValues: string[];
    };

type OnSubmitInput = {
  parameters: Record<string, any>;
  results: Record<string, any>;
};
type OnSubmitInputs = Array<OnSubmitInput>;

interface UploadInputValuesProps {
  parameters: Parameter[];
  targets: TargetVariable[];
  onSubmit: (inputs: OnSubmitInputs) => void;
  onCancel: () => void;
}

export const UploadInputValues: React.FC<UploadInputValuesProps> = ({
  parameters,
  targets,
  onSubmit,
  onCancel,
}) => {
  const [csvData, setCsvData] = useState<Record<string, string>[]>();
  const [csvDataError, setCsvDataError] = useState<string | undefined>();

  const csvHeaders = useMemo<string[] | null>(() => {
    if (!csvData?.length) {
      return null;
    }

    return Object.keys(csvData[0]);
  }, [csvData]);

  const [mappedHeaders, setMappedHeaders] = useState<MappedHeaders>();

  const inputItems = useMemo<InputItem[]>(() => {
    const result: InputItem[] = [];
    parameters.forEach((parameter) => {
      result.push({
        name: parameter.parameterName,
        type: 'parameter',
        parameterType: parameter.parameterType,
      });
    });

    targets.forEach((target) => {
      result.push({
        name: target.targetName,
        type: 'target',
      });
    });

    return result;
  }, [parameters, targets]);

  useEffect(() => {
    if (mappedHeaders || !inputItems.length || !csvHeaders?.length) {
      return;
    }

    const foundHeaders = inputItems.reduce<MappedHeaders>((acc, input) => {
      const foundHeader = csvHeaders?.find((header) => header === input.name);

      if (foundHeader) {
        acc[input.name] = foundHeader;
      }

      return acc;
    }, {});

    setMappedHeaders(foundHeaders);
  }, [csvHeaders, inputItems, mappedHeaders]);

  const errors = useMemo<Record<string, string[]>>(() => {
    if (!mappedHeaders || !csvData) {
      return {};
    }

    const entries = Object.entries(mappedHeaders);

    return entries.reduce(
      (acc, [inputItemName, csvHeader]: [string, string]) => {
        const errors: string[] = [];
        const isNotUnique = !!entries.find(
          ([name, header]) =>
            csvHeader && name !== inputItemName && csvHeader === header
        );

        if (isNotUnique) {
          errors.push('Headers should be unique');
        }

        const currentParameter = parameters.find(
          (parameter) => parameter.parameterName === inputItemName
        );

        if (currentParameter) {
          const doesNotMatchValues = csvData.find((csvItem) => {
            const csvValue = csvItem[csvHeader];

            if (currentParameter.parameterType === ParameterType.numeric) {
              return !currentParameter.parameterValues.includes(
                Number(csvValue)
              );
            }

            if (currentParameter.parameterType === ParameterType.smiles) {
              return (
                currentParameter.parameterValues.find(
                  ({ Name }) => Name === csvValue
                ) === undefined
              );
            }
            return !currentParameter.parameterValues.includes(csvValue);
          });

          if (doesNotMatchValues) {
            errors.push(
              'The value from this column do not match parameters space'
            );
          }
        }

        if (errors.length) {
          acc[inputItemName] = errors;
        }

        return acc;
      },
      {} as Record<string, string[]>
    );
  }, [csvData, mappedHeaders, parameters]);

  const allItemsSelected =
    mappedHeaders &&
    parameters.length + targets.length === Object.keys(mappedHeaders).length;

  const canSubmit = allItemsSelected && Object.keys(errors).length === 0;

  const handleSubmit = useCallback(() => {
    if (!canSubmit || !csvData || !mappedHeaders) {
      return;
    }

    type Input = {
      parameters: Record<string, any>;
      results: Record<string, any>;
    };
    type Inputs = Array<Input>;

    const inputs: Inputs = [];

    csvData.forEach((csvRow) => {
      const newInput: Input = {
        parameters: {},
        results: {},
      };

      Object.entries(csvRow).forEach(
        ([csvParameterName, csvParameterValue]) => {
          const currentParameter = parameters.find(
            (parameter) =>
              mappedHeaders[parameter.parameterName] === csvParameterName
          );

          const currentTarget = targets.find(
            (target) => mappedHeaders[target.targetName] === csvParameterName
          );

          if (!currentParameter && !currentTarget) {
            return;
          }

          if (currentParameter) {
            newInput.parameters[currentParameter.parameterName] =
              csvParameterValue;
          } else if (currentTarget) {
            newInput.results[currentTarget.targetName] =
              Number(csvParameterValue);
          }
        }
      );

      if (Object.keys(newInput.parameters).length) {
        inputs.push(newInput);
      }
    });

    onSubmit(inputs);
  }, [canSubmit, csvData, mappedHeaders, onSubmit, parameters, targets]);

  return (
    <div>
      {!!csvHeaders?.length &&
        inputItems.map((inputItem) => {
          const selectIdParts = [
            inputItem.name,
            inputItem.type,
            inputItem.parameterType,
          ].filter((i) => !!i);

          const selectId = selectIdParts.join('_');

          return (
            <div key={selectId} className="pb-4 flex flex-col items-start">
              <LdLabel position="left">
                {`${inputItem.name} (${
                  inputItem.parameterType || inputItem.type
                }):`}
                <LdSelect
                  id={selectId}
                  // size="sm"
                  className="ld-select__select select"
                  aria-label={'Test'}
                  onLdchange={(event) => {
                    setMappedHeaders({
                      ...mappedHeaders,
                      // @ts-ignore
                      [inputItem.name]: event.detail[0],
                    });
                  }}
                >
                  {csvHeaders.map((header) => (
                    <LdOption
                      key={header}
                      selected={mappedHeaders?.[inputItem.name] === header}
                      value={header}
                    >
                      {header}
                    </LdOption>
                  ))}
                </LdSelect>
              </LdLabel>
              {errors[inputItem.name]?.map((i) => (
                <div key={i}>
                  <LdInputMessage>{i}</LdInputMessage>
                </div>
              ))}
            </div>
          );
        })}
      {!csvData && (
        <ParameterInputCustom
          // TODO: fix the typing
          // @ts-ignore
          setValue={setCsvData}
          setError={setCsvDataError}
          error={csvDataError}
        />
      )}

      <div
        slot="footer"
        className="flex flex-row items-end justify-end gap-x-ld-8"
      >
        <LdButton onClick={handleSubmit} disabled={!canSubmit}>
          Submit
        </LdButton>
        <LdButton onClick={onCancel} mode="secondary">
          Cancel
        </LdButton>
      </div>
    </div>
  );
};

type MappedHeaders = Record<string, string>;

type InputItem = {
  name: string;
  type: 'parameter' | 'target';
  parameterType?: ParameterType;
};
