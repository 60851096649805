import { configureStore } from '@reduxjs/toolkit';
import projectsReducer, { reducerName } from '../services/projects';
import experimentsReducer from '../services/experiments';
import recommendationsReducer from '../services/recommendations';
import modalsReducer from '../services/modals';
import expandReducer from '../services/expand';

import inputReducer from '../services/inputs';
import projectInvitationsReducer from '../services/projectInvitations';

const store = configureStore({
  reducer: {
    [reducerName]: projectsReducer,
    /**
     * expandParameterSpace: {
     *  [projectId: string]: {
     *   selectedParameter: Parameter
     *   newParameters: {
     *    [key: string] Parameter
     *   }
     *   newParameterValues: {
     *    [parameterName: string]: ParameterValue[]
     *   }
     *  }
     * }
     */
    experiments: experimentsReducer,
    input: inputReducer,
    recommendations: recommendationsReducer,
    modals: modalsReducer,
    projectInvitations: projectInvitationsReducer,
    expand: expandReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
