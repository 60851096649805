import {
  LdBadge,
  LdButton,
  LdIcon,
  LdTableCell,
  LdTableRow,
} from '@emdgroup-liquid/liquid/dist/react';
import classNames from 'classnames';
import { tableColumns } from 'components/ChemicalSpace/TargetList/tableColumns';
import React from 'react';
import { TargetVariable } from 'types/api/types';

interface TargetTableItemRowProps {
  target: TargetVariable;
  isEditing?: boolean;
  hasError?: boolean;
  readOnly?: boolean;
  onRemoveClick?: () => void;
  onEditClick?: () => void;
}

export const TargetTableItemRow: React.FC<TargetTableItemRowProps> = ({
  target,
  isEditing,
  hasError,
  readOnly,
  onRemoveClick,
  onEditClick,
}) => {
  return (
    <LdTableRow
      className={classNames({
        'bg-rich-purple-lightest': isEditing,
        'bg-rich-red-lightest': hasError,
      })}
    >
      {tableColumns.map(({ key }) => (
        <LdTableCell className="py-1 px-4 text-left" key={key}>
          {target[key]}
        </LdTableCell>
      ))}

      {!readOnly && (
        <LdTableCell className="py-1 px-4 text-left flex items-center justify-center">
          {!isEditing ? (
            <>
              {!!onEditClick && (
                <LdButton
                  type="button"
                  onClick={() => onEditClick()}
                  size="sm"
                  className="mx-1"
                >
                  <LdIcon name="pen" />
                </LdButton>
              )}
              {!!onRemoveClick && (
                <LdButton
                  type="button"
                  onClick={() => onRemoveClick()}
                  mode="danger"
                  size="sm"
                >
                  <LdIcon name="cross" />
                </LdButton>
              )}
            </>
          ) : (
            <LdBadge>Editing...</LdBadge>
          )}
        </LdTableCell>
      )}
    </LdTableRow>
  );
};
