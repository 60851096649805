import React, { MouseEventHandler, useEffect, useMemo } from 'react';
import { LdOption, LdSelect, LdTypo } from '@emdgroup-liquid/liquid/dist/react';

import {
  Project,
  Parameter,
  ProjectStatus,
  Experiment,
  TargetVariable,
} from 'types/api/types';
import Card from '../Card';
import moment from 'moment';
import { BatchPlot } from 'components/Plots';
import { ProjectStatusBadge, RoleBadge } from 'components/Badge';
import { calculateProgress } from 'util/project';

interface Props extends Project {
  onClick?: MouseEventHandler;
  editing?: boolean;
  onEdit?: (arg0: Parameter) => void;
  experiments?: Experiment[];
}

const SpaceDetails: React.FC<Props> = ({
  name,
  batchSize,
  parameterSpace,
  createdAt,
  targets,
  status,
  onClick,
  role,
  experiments,
  children,
}) => {
  const [selectedTargetName, setSelectedTargetName] = React.useState(
    targets?.[0]?.targetName || 'yield'
  );
  const [selectedTarget, setSelectedTarget] = React.useState(targets?.[0]);
  const [selectedParamName, setSelectedParamName] = React.useState('batch');
  const [selectedParam, setSelectedParam] = React.useState(
    parameterSpace.find(
      (parameter) => parameter.parameterName === selectedParamName
    )
  );

  const progress = useMemo(
    () =>
      calculateProgress({
        project: { parameterSpace },
        experiments: experiments || [],
      }),
    [parameterSpace, experiments]
  );

  const targetNames = useMemo(
    () => targets.map(({ targetName }) => targetName).join(', '),
    [targets]
  );
  return (
    <Card
      data-test="project-item"
      onClick={onClick}
      className="border-2 border-key border-opacity-100 border-l-rich-purple-light border-double w-full"
    >
      <div className="flex flex-col justify-between w-full gap-4">
        <div className="flex flex-row justify-between">
          <LdTypo variant="h3">
            <RoleBadge role={role} />
            {name}
          </LdTypo>
          {status !== ProjectStatus.idle && (
            <ProjectStatusBadge status={status} />
          )}
        </div>
        <LdTypo variant="body-xs" className="mt-[-1rem]">
          {moment(createdAt).format('DD.MM.YYYY')}
        </LdTypo>
        <div className="grid grid-cols-3">
          <div className="gap-2 flex flex-col">
            <LdTypo variant="label-s" className="my-2">
              Recommendations per Batch:{' '}
              <span className="font-normal">{batchSize}</span>
            </LdTypo>
            <LdTypo variant="label-s" className="my-2">
              Targets: <span className="font-normal">{targetNames}</span>
            </LdTypo>
            <LdTypo variant="label-s" className="my-2">
              Progress:{' '}
              <span className="font-normal">
                {progress.performed} / {progress.total} possible Experiments
              </span>
            </LdTypo>
            <LdTypo variant="label-s">
              Plot y-axis:{' '}
              <span className="font-normal">
                {
                  <LdSelect
                    placeholder={selectedTargetName}
                    mode="ghost"
                    size="sm"
                    onClick={(e) => e.stopPropagation()}
                    onLdinput={({ detail }: { detail: string[] }) => {
                      setSelectedTargetName(detail[0]);
                      const target = targets?.find(
                        ({ targetName }) => targetName === detail[0]
                      );
                      if (target !== undefined) {
                        setSelectedTarget(target);
                      }
                    }}
                  >
                    {targetNames.split(', ').map((targetName) => (
                      <LdOption key={targetName} value={targetName}>
                        {targetName}
                      </LdOption>
                    ))}
                    <LdOption value="desirability">desirability</LdOption>
                  </LdSelect>
                }
              </span>
            </LdTypo>
            <LdTypo variant="label-s">
              Plot x-axis:{' '}
              <span className="font-normal">
                {
                  <LdSelect
                    placeholder={selectedParamName}
                    mode="ghost"
                    size="sm"
                    onClick={(e) => e.stopPropagation()}
                    onLdinput={({ detail }: { detail: string[] }) => {
                      setSelectedParamName(detail[0]);
                      setSelectedParam(
                        parameterSpace.find(
                          (parameter) => parameter.parameterName === detail[0]
                        )
                      );
                    }}
                  >
                    <LdOption value="batch">batch</LdOption>
                    {parameterSpace.map((parameter) => (
                      <LdOption
                        key={parameter.parameterName}
                        value={parameter.parameterName}
                      >
                        {parameter.parameterName}
                      </LdOption>
                    ))}
                  </LdSelect>
                }
              </span>
            </LdTypo>
          </div>
          <div className="mt-[-5rem] col-span-2">
            {experiments !== undefined && (
              <BatchPlot
                experiments={experiments}
                selectedTarget={selectedTarget}
                selectedTargetName={selectedTargetName}
                selectedParam={selectedParam}
                selectedParamName={selectedParamName}
                targets={targets}
              />
            )}
          </div>
        </div>
        {children}
      </div>
    </Card>
  );
};

export default SpaceDetails;
