import {
  LdButton,
  LdIcon,
  LdInput,
  LdOption,
  LdSelect,
  LdTableCell,
  LdTableRow,
} from '@emdgroup-liquid/liquid/dist/react';
import { tableColumns } from 'components/ChemicalSpace/TargetList/tableColumns';
import { Targets } from 'components/ChemicalSpace/TargetList/types';
import { nanoid } from 'nanoid';
import React, { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TargetVariable } from 'types/api/types';

interface TargetTableFormRowProps {
  targets: Targets;
  editingTarget?: boolean;
  onAddClick: () => void;
  onEditClick: () => void;
  onEditCancelClick: () => void;
}

export const TargetTableAddRow: React.FC<TargetTableFormRowProps> = ({
  targets,
  editingTarget,
  onAddClick,
  onEditClick,
  onEditCancelClick,
}) => {
  const [isDirty, setDirty] = useState(false);
  const { getValues, trigger, setValue, formState, control } =
    useFormContext<TargetVariable>();

  const { errors } = formState;
  const errorEntries = Object.entries(errors);

  const isDisabled = useMemo(() => {
    return errorEntries.length > 0;
  }, [errorEntries]);

  const nanoId = nanoid();

  const clearDirty = (cb: () => void) => {
    setDirty(false);
    cb();
  };

  return (
    <LdTableRow>
      {tableColumns.map((column) => (
        <LdTableCell className="py-1 px-4 text-left" key={column.key}>
          <Controller
            control={control}
            name={column.key}
            rules={column.registerOptions?.(
              { getValues },
              targets,
              editingTarget
            )}
            render={({ field, fieldState, ...prps }) => {
              return column.inputType === 'select' ? (
                <LdSelect
                  name={`select_${nanoId}`}
                  id={`select_${nanoId}`}
                  key={`select_${nanoId}`}
                  onLdinput={({ detail }) => {
                    setValue(field.name, detail[0]);
                    trigger();
                    setDirty(true);
                  }}
                  invalid={!!fieldState.error}
                >
                  {column.options.map((option) => {
                    return (
                      <LdOption
                        key={option}
                        selected={field.value === option}
                        value={option}
                      >
                        {option}
                      </LdOption>
                    );
                  })}
                </LdSelect>
              ) : (
                <LdInput
                  name={column.key}
                  type={column.inputType}
                  value={field.value as string}
                  onLdinput={(ev) => {
                    setValue(field.name, ev.detail);
                    trigger();
                    setDirty(true);
                  }}
                  invalid={!!fieldState.error}
                />
              );
            }}
          />
        </LdTableCell>
      ))}

      <LdTableCell className="py-1 px-4 text-left flex flex-col items-center justify-center">
        <div className="flex flex-0 items-center justify-center">
          {!editingTarget ? (
            <>
              <LdButton
                onClick={() => clearDirty(onAddClick)}
                size="sm"
                disabled={isDisabled}
                type="button"
                className="mx-1"
                name="btn-add-target"
              >
                <span className="text-lg">+</span>
              </LdButton>
              <LdButton
                type="button"
                disabled={!isDirty}
                onClick={() => clearDirty(onEditCancelClick)}
                mode="danger"
                size="sm"
              >
                <LdIcon name="cross" />
              </LdButton>
            </>
          ) : (
            <>
              <LdButton
                onClick={() => clearDirty(onEditClick)}
                size="sm"
                className="mx-1"
                disabled={isDisabled}
                type={'button'}
              >
                <LdIcon size={'sm'} name="checkmark" />
              </LdButton>
              <LdButton
                type="button"
                onClick={() => clearDirty(onEditCancelClick)}
                mode="danger"
                size="sm"
              >
                <LdIcon name="cross" />
              </LdButton>
            </>
          )}
        </div>
      </LdTableCell>
    </LdTableRow>
  );
};
