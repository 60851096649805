import React, { useMemo, useState } from 'react';
import {
  LdButton,
  LdIcon,
  LdTable,
  LdTableHead,
  LdTableBody,
  LdTableHeader,
  LdTableRow,
  LdTableCell,
  LdModal,
  LdBadge,
  LdTypo,
} from '@emdgroup-liquid/liquid/dist/react';
import { ParameterRecommendation, Project } from 'types/api/types';
import Card from 'components/Card';
import LoadingOverlay from './LoadingOverlay';
import { useHints } from 'hooks/useHints';
import useRecommendations from '../hooks/useRecommendations';
import { isBusy as isProjectBusy } from 'util/project';
import {
  FileTypes,
  SUPPORTED_FILE_TYPES,
} from '../../../components/ChemicalSpace/UploadDropZone/FileTypes';

interface Props {
  selectedProject: Project;
  disabled?: boolean;
}

export const RecommendationsList: React.FC<Props> = ({
  selectedProject,
  disabled,
}) => {
  const [selectedFileType, updateFileType] = useState(SUPPORTED_FILE_TYPES.CSV);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const { recommendations, selectRecommendation, exportRecommendations } =
    useRecommendations(selectedProject.projectId);
  const { getToolTipById } = useHints();

  const isBusy = useMemo(() => {
    return isProjectBusy(selectedProject);
  }, [selectedProject]);

  const getParameterValues = (
    parameters: ParameterRecommendation,
    parameterName: string,
    index: number
  ) => {
    const value = (parameters as { [key: string]: any })[parameterName];

    return (
      value ??
      (
        Object.values(parameters) as {
          [key: string]: any;
        }
      )[index]
    );
  };
  const onExport = () => {
    exportRecommendations(selectedFileType);
    setExportModalOpen(false);
  };

  const exportFileModal = useMemo(
    () => (
      <LdModal
        blurryBackdrop
        open={exportModalOpen}
        onLdmodalclosed={() => setExportModalOpen(false)}
        style={{ '--ld-modal-min-inline-size': '40rem' }}
      >
        <div slot="header" className="flex items-center gap-ld-4 py-ld-8">
          <LdBadge icon="attention" size="lg" class="ld-badge--info" />
          <LdTypo variant="h4">Export file type</LdTypo>
        </div>
        <div className="flex-col">
          <LdTypo variant="body-l">
            Please select the file type you want to export
          </LdTypo>
          <FileTypes
            selectedType={selectedFileType}
            updateSelectedFileType={updateFileType}
          />

          <div
            slot="footer"
            className="flex flex-row items-end justify-end gap-x-ld-8"
          >
            <LdButton
              onClick={onExport}
              data-for="export-tooltip"
              disabled={!selectedFileType}
            >
              <LdIcon name="cloud-download" />
              Export
            </LdButton>
          </div>
        </div>
      </LdModal>
    ),
    [exportModalOpen, selectedFileType]
  );

  return (
    <Card className="p-4 mt-8 relative">
      <>
        {isBusy && <LoadingOverlay />}
        <div className="w-full text-right">
          {!isBusy && getToolTipById('Exp_7')}
        </div>
        <div className={`w-full ${isBusy && 'blur-sm'}`}>
          <LdTable className={`table-auto min-w-full ${isBusy && 'blur-sm'}`}>
            <LdTableHead>
              <LdTableRow>
                {selectedProject.parameterSpace.map((p) => (
                  <LdTableHeader
                    className="px-4 text-left"
                    key={`recommendation_header_${p.parameterName}`}
                  >
                    {p.parameterName}
                  </LdTableHeader>
                ))}
                <LdTableHeader />
              </LdTableRow>
            </LdTableHead>
            <LdTableBody className="divide-y divide-sensitive-grey-darker">
              {recommendations.map(({ recommendationId, parameters }) => (
                <LdTableRow key={recommendationId}>
                  {selectedProject.parameterSpace.map((p, index) => (
                    <LdTableCell
                      key={`${recommendationId}_${p.parameterName}`}
                      className="px-4"
                    >
                      {getParameterValues(parameters, p.parameterName, index)}
                    </LdTableCell>
                  ))}
                  <LdTableCell className="flex justify-end">
                    <div
                      data-name="select-recommendation"
                      className="flex w-max justfiy-end gap-2"
                    >
                      <LdButton
                        size="sm"
                        slot="trigger"
                        disabled={disabled}
                        onClick={
                          !disabled
                            ? () => selectRecommendation(recommendationId)
                            : undefined
                        }
                      >
                        <LdIcon name="test-tube" />
                      </LdButton>
                      {/* <LdButton
                    size="sm"
                    slot="trigger"
                    mode="danger"
                    onClick={handleRejectRecommendation(
                      recommendationId
                    )}
                  >
                    <LdIcon name="bin" />
                  </LdButton> */}
                    </div>
                  </LdTableCell>
                </LdTableRow>
              ))}
            </LdTableBody>
          </LdTable>

          <div className="flex justify-end gap-4 w-full mt-ld-8">
            <LdButton
              onClick={() => setExportModalOpen(true)}
              data-for="export-tooltip"
            >
              <LdIcon name="cloud-download" />
              Export
            </LdButton>
          </div>
        </div>
      </>
      {exportFileModal}
    </Card>
  );
};
