import React, { MouseEventHandler } from 'react';
import {
  LdButton,
  LdCard,
  LdIcon,
  LdLabel,
  LdTypo,
} from '@emdgroup-liquid/liquid/dist/react';

import Card from 'components/Card';

import {
  CustomParameterValue,
  Parameter,
  ParameterType,
  SmilesParameterValue,
  NumericalContinuousParameter,
} from 'types/api/types';
import { CustomParameterTable } from './CustomParameterTable';
import { SmilesParameterTable } from './SmilesParameterTable';

interface ParameterDetailsProps extends Parameter {
  onDelete: MouseEventHandler;
  onEdit: MouseEventHandler;
  id: string;
}

const ParameterDetails: React.FC<ParameterDetailsProps> = ({
  id,
  parameterName,
  parameterValues,
  parameterType,
  activeValues,
  onDelete,
  onEdit,
}) => (
  <Card className=" p-4 w-full">
    <div className="flex flex-row w-full items-center gap-ld-8">
      <div className="flex flex-col flex-1 justify-start items-start gap-4 w-full">
        <div className="flex w-full justify-between">
          <LdLabel position="left" className="flex flex-row align-middle">
            <span className="flex">Name:</span>
            <LdTypo className="flex break-all">{parameterName}</LdTypo>
          </LdLabel>
          <div className="flex flex-row">
            <LdButton mode="danger" onClick={onDelete}>
              <LdIcon name="cross" aria-label="remove parameter" />
            </LdButton>
            <LdButton onClick={onEdit} className="ml-2">
              <LdIcon name="pen" aria-label="edit parameter" />
            </LdButton>
          </div>
        </div>
        <div className="flex-grow w-full">
          <LdLabel>Values:</LdLabel>
          <div className="grid grid-cols-1 divide-y divide-sensitive-grey-darker mt-3">
            {parameterType === ParameterType.smiles && (
              <SmilesParameterTable
                id={id}
                values={parameterValues as SmilesParameterValue[]}
              />
            )}
            {parameterType === ParameterType.numeric && (
              <div className="grid grid-cols-10 gap-2">
                {parameterValues.map((value) => (
                  <LdCard
                    key={`${parameterName}_${value}`}
                    className="text-center justify-center"
                  >
                    <LdTypo>{value}</LdTypo>
                  </LdCard>
                ))}
              </div>
            )}
            {parameterType === ParameterType.category ||
            parameterType === ParameterType.task ? (
              <div className="grid grid-cols-6 gap-2">
                {parameterValues.map((value) => (
                  <LdCard
                    key={`${parameterName}_${value}`}
                    className="text-center justify-center min-w-fit-content"
                  >
                    <LdTypo
                      variant="body-s"
                      className="max-w-[10rem]"
                      style={{ 'overflow-wrap': 'anywhere' }}
                    >
                      {value}
                    </LdTypo>
                  </LdCard>
                ))}
              </div>
            ) : null}
            {parameterType === ParameterType.custom && (
              <CustomParameterTable
                values={parameterValues as CustomParameterValue[]}
                name={parameterName}
              />
            )}
            {parameterType === ParameterType.task &&
              activeValues &&
              activeValues.length > 0 && (
                <div className="flex-grow w-full">
                  <LdLabel>Active Values</LdLabel>
                  <div className="grid grid-cols-6 gap-2">
                    {activeValues.map((value) => (
                      <LdCard
                        key={`${parameterName}_${value}`}
                        className="text-center justify-center min-w-fit-content"
                      >
                        <LdTypo
                          variant="body-s"
                          className="max-w-[10rem]"
                          style={{ 'overflow-wrap': 'anywhere' }}
                        >
                          {value}
                        </LdTypo>
                      </LdCard>
                    ))}
                  </div>
                </div>
              )}
            {parameterType === ParameterType.task &&
              (!activeValues || activeValues.length === 0) && (
                <div className="flex-grow w-full">
                  <LdLabel>No active value selected</LdLabel>
                </div>
              )}
            {parameterType === ParameterType.continuous && (
              <div className="grid grid-cols-2 gap-4">
                <LdCard
                  key={`lowerBound_${parameterName}_${parameterType}`}
                  className="text-center justify-center min-w-fit-content"
                >
                  <LdLabel>
                    Lower Bound
                    <LdTypo
                      variant="body-s"
                      className="max-w-[10rem]"
                      style={{ 'overflow-wrap': 'anywhere' }}
                    >
                      {(parameterValues as NumericalContinuousParameter)[0]}
                    </LdTypo>
                  </LdLabel>
                </LdCard>
                <LdCard className="text-center justify-center">
                  <LdLabel>
                    Upper Bound
                    <LdTypo
                      variant="body-s"
                      className="max-w-[10rem]"
                      style={{ 'overflow-wrap': 'anywhere' }}
                    >
                      {(parameterValues as NumericalContinuousParameter)[1]}
                    </LdTypo>
                  </LdLabel>
                </LdCard>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </Card>
);

export default ParameterDetails;
