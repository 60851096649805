import React, { useEffect, useMemo, useState, useRef } from 'react';
import { nanoid } from 'nanoid';
import { useNavigate, Link } from 'react-router-dom';
import { LightBox } from '@liquid-design/liquid-design-react';
import {
  LdButton,
  LdAccordion,
  LdAccordionSection,
  LdAccordionPanel,
  LdAccordionToggle,
  LdTypo,
  LdBadge,
  LdModal,
} from '@emdgroup-liquid/liquid/dist/react';
import {
  ExpertOptions,
  Parameter,
  ProjectStatus,
  TargetVariable,
} from 'types/api/types';
import { ParametersObject, StateStatus } from 'types/app/types';
import AppLayout from '../../layouts/AppLayout';
import {
  AddParameterForm,
  ParameterDetails,
  SpaceForm,
} from '../../components/ChemicalSpace';

import { useDispatch, useSelector } from 'store';
import ExpertOptionsForm from 'components/ChemicalSpace/ExpertOptionsForm';
import useNotification, { NotificationType } from 'hooks/useNotification';
import { TargetsList } from 'components/ChemicalSpace/TargetList/TargetsList';
import { calculateCombinations } from 'util/project';
import { useHints } from 'hooks/useHints';
import Disclaimer from 'layouts/Disclaimer';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import useClientinfo from 'hooks/useClientinfo';
import { useProjectToClone } from 'pages/Project/hooks/useProjectToClone';
import { createProject } from 'services/projects';
import { SpaceFormRef } from 'components/ChemicalSpace/SpaceForm';

const { REACT_APP_COMBINATION_THRESHOLD = '40000' } = process.env;

const sortedId = () => {
  const timestamp = Date.now();
  return `${timestamp}_${nanoid()}`;
};

const CreatePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { wrapLabel } = useHints();
  const { trackPageView, trackEvent } = useMatomo();
  const { isChrome } = useClientinfo();
  const spaceFormRef = useRef<SpaceFormRef>(null);

  const projectToClone = useProjectToClone();

  useEffect(() => {
    if (!projectToClone) {
      return;
    }

    setProject({
      name: projectToClone.name,
      batchSize: projectToClone.batchSize,
      status: ProjectStatus.draft,
    });

    setTargets(
      projectToClone.targets.reduce<Record<string, TargetVariable>>(
        (acc, target) => {
          acc[target.targetName] = target;
          return acc;
        },
        {}
      )
    );
    setParameters(
      projectToClone.parameterSpace.reduce<Record<string, Parameter>>(
        (acc, parameter) => {
          acc[parameter.parameterName] = parameter;
          return acc;
        },
        {}
      )
    );
    setExpertOptions(projectToClone.expertOptions);
  }, [projectToClone]);

  useEffect(() => {
    trackPageView({
      documentTitle: 'New Project',
    });
  }, []);

  type ModalType = 'parameter' | 'combinationWarning' | 'batchSizeWarning';
  const { status, error } = useSelector((state) => state.projects);
  const [modal, setModal] = useState<ModalType | null>(null);
  const [project, setProject] = useState<
    | {
        name: string;
        batchSize: number;
        status?: string;
      }
    | undefined
  >({ status: ProjectStatus.draft, name: '', batchSize: 0 });
  const [parameters, setParameters] = useState<ParametersObject>({});
  const [targets, setTargets] = useState<{
    [key: string]: TargetVariable;
  }>({});
  const [expertOptions, setExpertOptions] = useState<ExpertOptions>();
  const { clearNotifications, sendNotification } = useNotification();
  const [editParameter, setEditParameter] = useState<string | null>(null);
  const isLoading = status === StateStatus.loading;

  const handleEditParameter = (id: string) => {
    setEditParameter(id);
    setModal('parameter');
  };

  const targetList = useMemo(() => Object.values(targets), [targets]);

  const combinationAmount: number = useMemo(() => {
    return calculateCombinations(Object.values(parameters));
  }, [parameters]);

  const handleAddParameter = (parameter: Parameter) => {
    const isEditing = !!editParameter;
    setParameters((old) => {
      const parameterId = isEditing ? editParameter : nanoid();
      return { ...old, [parameterId]: parameter };
    });
    setEditParameter(null);
    setModal(null);
  };

  const handleRemoveParameter = (id: string) => {
    setParameters(({ ...old }) => {
      delete old[id];
      return old;
    });
  };

  const handleAddTarget = (target: TargetVariable) => {
    setTargets((old) => ({ ...old, [sortedId()]: target }));
  };
  const handleEditTarget = (targetKey: string, target: TargetVariable) => {
    setTargets((old) => ({ ...old, [targetKey]: target }));
  };

  const handleRemoveTarget = (id: string) => {
    setTargets((old) => {
      delete old[id];
      return { ...old };
    });
  };

  const isSubmitDisabled = useMemo(() => {
    const targetKeys: Array<keyof TargetVariable> = [
      'targetName',
      'targetObjective',
      'targetWeight',
      'lowerBound',
      'upperBound',
    ];

    const targetsHaveError =
      Object.keys(targets).length > 1 &&
      Object.values(targets).some((target) => {
        return targetKeys.some(
          (key) => target[key] === undefined || !`${target[key]}`
        );
      });

    return targetsHaveError;
  }, [targets]);

  const handleCreateProject = async (p: {
    name: string;
    batchSize: number;
  }) => {
    if (!targetList.length) return;
    if (combinationAmount <= p.batchSize) {
      setProject(p);
      setModal('batchSizeWarning');
      spaceFormRef.current?.setError('batchSize', {
        message: `Must be smaller than ${combinationAmount}`,
      });
    } else if (combinationAmount > parseInt(REACT_APP_COMBINATION_THRESHOLD)) {
      setProject(p);
      setModal('combinationWarning');
    } else {
      submitCreation(p);
    }
  };

  const submitCreation = async (project: {
    name: string;
    batchSize: number;
  }) => {
    trackEvent({ category: 'project', action: 'create-project' });
    clearNotifications();
    setModal(null);
    setProject(undefined);
    try {
      const response = await dispatch(
        createProject({
          ...project,
          targets: targetList,
          parameterSpace: Object.values(parameters),
          expertOptions,
        })
      );
      if (status === StateStatus.succeeded) {
        navigate(`/projects/${response.payload.projectId}`);
      } else if (error) {
        console.log('error catched' + error);
        sendNotification({ content: error, type: NotificationType.alert });
      }
    } catch (err) {
      let errorText = typeof err === 'string' ? err : (err as Error).toString();
      sendNotification({ content: errorText, type: NotificationType.alert });
    }
  };

  return (
    <AppLayout>
      <div id="upload" className="relative flex max-w-[1024px] mx-auto">
        <div className="relative flex flex-col items-start w-full max-h-screen min-h-screen pt-8 pl-4">
          {!isChrome && <Disclaimer />}
          <div className="flex justify-start items-center mb-5 gap-ld-4 pt-ld-4">
            <Link to={`/projects`} className="flex hover:underline">
              <LdTypo variant="body-s">Create Project</LdTypo>
            </Link>
          </div>
          <SpaceForm
            ref={spaceFormRef}
            onSubmit={handleCreateProject}
            isSubmitDisabled={isSubmitDisabled}
            loading={isLoading}
            parameterCount={Object.keys(parameters).length}
            targetsCount={Object.keys(targets).length}
            possibleCombinations={combinationAmount}
            projectToClone={projectToClone}
            actionArea={
              <LdButton
                type="button"
                mode="ghost"
                onClick={() => setModal('parameter')}
                disabled={isLoading}
              >
                <span className="text-xl font-light">+</span>
                Add Parameter
              </LdButton>
            }
          >
            <div className="flex w-full flex-col">
              <TargetsList
                targets={targets}
                onRemoveTarget={handleRemoveTarget}
                onAddTarget={handleAddTarget}
                onEditTarget={handleEditTarget}
              />

              <LdAccordion rounded detached tone="dark">
                <LdAccordionSection>
                  <LdAccordionToggle>
                    {wrapLabel('Cre_5', 'Advanced Options')}
                  </LdAccordionToggle>
                  <LdAccordionPanel>
                    <div className="py-ld-16 p-ld-24">
                      <ExpertOptionsForm
                        projectToClone={projectToClone}
                        onChange={setExpertOptions}
                      />
                    </div>
                  </LdAccordionPanel>
                </LdAccordionSection>
              </LdAccordion>
            </div>
          </SpaceForm>
          {Object.keys(parameters).length > 0 && (
            <LdTypo variant="h3" className="mb-ld-8 mt-ld-16">
              Parameters
            </LdTypo>
          )}
          <div className="flex flex-col gap-ld-16 w-full pb-ld-32">
            {Object.entries(parameters).map(([key, parameter]) => {
              return (
                <ParameterDetails
                  key={key}
                  id={key}
                  {...parameter}
                  onDelete={() => handleRemoveParameter(key)}
                  onEdit={() => handleEditParameter(key)}
                />
              );
            })}
          </div>
        </div>
        <LightBox
          label="Add Parameter"
          open={modal === 'parameter'}
          onClose={() => setModal(null)}
        >
          <div className="relative flex w-full max-h-[30rem] h-[60vh]">
            <AddParameterForm
              projectStatus={project?.status}
              onCancel={() => {
                setEditParameter(null);
                setModal(null);
              }}
              data-test="upload-card-drop"
              // className={`left-0 right-0 ml-auto mr-auto absolute transform ease-out transition`}
              onSubmit={handleAddParameter}
              parameter={editParameter ? parameters[editParameter] : undefined}
              values={
                editParameter
                  ? parameters[editParameter].parameterValues
                  : undefined
              }
            />
          </div>
        </LightBox>
        <LdModal cancelable={false} open={modal === 'combinationWarning'}>
          <div slot="header" className="flex items-center gap-ld-4 py-ld-8">
            <LdBadge icon="attention" size="lg" class="ld-badge--warning" />
            <LdTypo variant="h4">Warning</LdTypo>
          </div>
          <div className="relative flex w-full flex-col gap-ld-4">
            <LdTypo>
              With the given parameters and their values there are{' '}
              {combinationAmount} possible combinations. It is generally
              expected that at least 1% of them (corresponding to{' '}
              {Math.round(combinationAmount / 100)} for the current parameter
              space) have to be measued to get the underlying model to
              understand and become useful. Consider reducing or redesigning the
              parameter space.
            </LdTypo>
            <div
              slot="footer"
              className="flex flex-row items-end justify-end gap-x-ld-8"
            >
              <LdButton onClick={() => setModal(null)} mode="secondary">
                Go Back
              </LdButton>
              <LdButton
                onClick={() => submitCreation(project!)}
                mode="highlight"
              >
                Ignore
              </LdButton>
            </div>
          </div>
        </LdModal>
        <LdModal cancelable={false} open={modal === 'batchSizeWarning'}>
          <div slot="header" className="flex items-center gap-ld-4 py-ld-8">
            <LdBadge icon="attention" size="lg" class="ld-badge--danger" />
            <LdTypo variant="h4">Error</LdTypo>
          </div>
          <div className="relative flex w-full flex-col gap-ld-4">
            <LdTypo>
              You are requesting {project?.batchSize} recommendations per batch,
              which exceeds the number of {combinationAmount} possible
              combinations. Change &quot;Recommendations per Batch&quot; to a
              number smaller than {combinationAmount}.
            </LdTypo>
            <div
              slot="footer"
              className="flex flex-row items-end justify-end gap-x-ld-8"
            >
              <LdButton onClick={() => setModal(null)} mode="secondary">
                Got it!
              </LdButton>
            </div>
          </div>
        </LdModal>
      </div>
    </AppLayout>
  );
};

export default CreatePage;
