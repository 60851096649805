import React, {
  FormEvent,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  LdButton,
  LdInput,
  LdInputMessage,
  LdLabel,
} from '@emdgroup-liquid/liquid/dist/react';
import { ErrorOption, useForm } from 'react-hook-form';

import { toSafeInteger } from 'lodash';
import { ToWords } from 'to-words';

import { useHints } from 'hooks/useHints';
import { Project } from 'types/api/types';

import Card from '../Card';
import { MIN_PARAMETER_COUNT, MIN_TARGET_COUNT } from 'config';

const toWords = new ToWords();

interface Props {
  children?: string | JSX.Element | JSX.Element[];
  actionArea?: string | JSX.Element | JSX.Element[];
  loading?: boolean;
  parameterCount?: number;
  targetsCount?: number;
  onSubmit: (arg0: { name: string; batchSize: number }) => void;
  onCancel?: () => void;
  projectToClone?: Project;
  isSubmitDisabled?: boolean;
  possibleCombinations?: number;
}

export interface SpaceFormRef {
  setError: (
    name: 'parameterCount' | 'projectName' | 'batchSize' | 'targetsCount',
    error: ErrorOption
  ) => void;
}

interface FormData {
  projectName?: string;
  batchSize?: string;
  parameterCount?: number;
  targetsCount?: number;
}

const SpaceForm = forwardRef<SpaceFormRef, Props>(
  (
    {
      loading = false,
      onSubmit,
      onCancel,
      parameterCount,
      targetsCount,
      possibleCombinations,
      children,
      actionArea,
      projectToClone,
      isSubmitDisabled,
    },
    ref
  ) => {
    const { wrapLabel } = useHints();
    const {
      formState,
      getValues,
      register,
      setValue,
      trigger,
      clearErrors,
      watch,
      setError,
    } = useForm<FormData>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        parameterCount: parameterCount,
        targetsCount: targetsCount,
      },
    });

    useImperativeHandle(ref, () => ({
      setError: (
        name: 'parameterCount' | 'projectName' | 'batchSize' | 'targetsCount',
        error: ErrorOption
      ) => setError(name, error, { shouldFocus: true }),
    }));

    const { errors, dirtyFields } = formState;
    const isFormDirty = formState.submitCount > 0;

    useEffect(() => {
      if (!projectToClone) {
        return;
      }

      if (projectToClone.name && projectToClone.batchSize) {
        setValue('projectName', projectToClone.name);
        setValue('batchSize', `${projectToClone.batchSize}`);
      }
    }, [projectToClone, setValue]);

    const watchedProjectName = watch('projectName');
    const watchedBatchSize = watch('batchSize');

    useEffect(() => {
      setValue('parameterCount', parameterCount || 0);
      if (parameterCount !== undefined && parameterCount >= MIN_PARAMETER_COUNT)
        clearErrors('parameterCount');
    }, [parameterCount]);

    useEffect(() => {
      setValue('targetsCount', targetsCount || 0);
      if (targetsCount) clearErrors('targetsCount');
    }, [targetsCount]);

    useEffect(() => {
      if (possibleCombinations && watchedBatchSize) {
        trigger('batchSize');
      }
    }, [possibleCombinations]);

    const handleSubmit = async (e: FormEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const isValid = await trigger();

      if (!isValid) return;

      const { projectName, batchSize } = getValues();

      onSubmit({
        name: projectName!,
        batchSize: parseInt(batchSize!),
      });
    };

    const handleCancel = () => {
      if (onCancel) onCancel();
    };
    return (
      <Card className=" w-full">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <input
            type="hidden"
            {...register('parameterCount', {
              required: true,
              validate: (value) =>
                value !== undefined && value >= MIN_PARAMETER_COUNT,
            })}
          />

          <input
            type="hidden"
            {...register('targetsCount', {
              required: true,
              validate: (value) =>
                value !== undefined && value >= MIN_TARGET_COUNT,
            })}
          />

          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-ld-24 mb-ld-16">
            <LdLabel>
              {wrapLabel('Cre_1', 'Name')}
              <LdInput
                placeholder="e.g. New Project"
                type="text"
                tone="dark"
                value={watchedProjectName}
                {...register('projectName', {
                  required: true,
                })}
                onInput={(ev) => {
                  setValue(
                    'projectName',
                    (ev.target as HTMLLdInputElement).value,
                    {
                      shouldValidate: isFormDirty || dirtyFields.projectName,
                    }
                  );
                }}
                onBlur={(ev) => {
                  setValue('projectName', ev.target.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                invalid={Boolean(errors.projectName)}
              />
              {!!errors.projectName && (
                <LdInputMessage>Project Name is required</LdInputMessage>
              )}
            </LdLabel>
            <LdLabel>
              {wrapLabel('Cre_2', 'Recommendations per Batch')}
              <LdInput
                tone="dark"
                type="number"
                placeholder="e.g. 5 "
                value={watchedBatchSize}
                {...register('batchSize', {
                  required: true,
                  validate: (value) =>
                    !possibleCombinations ||
                    toSafeInteger(value) < possibleCombinations ||
                    `Must be smaller than ${possibleCombinations} (# possible combinations)`,
                })}
                onInput={(ev) => {
                  setValue(
                    'batchSize',
                    (ev.target as HTMLLdInputElement).value,
                    {
                      shouldValidate: isFormDirty || dirtyFields.batchSize,
                    }
                  );
                }}
                onBlur={(ev) => {
                  setValue('batchSize', ev.target.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                invalid={Boolean(errors.batchSize)}
              />
              {!!errors.batchSize && (
                <LdInputMessage>
                  {errors.batchSize.message ||
                    'Recommendations per Batch is required'}
                </LdInputMessage>
              )}
            </LdLabel>
          </div>
          {errors.targetsCount && (
            <LdInputMessage className="mb-ld-16">
              At least {toWords.convert(MIN_TARGET_COUNT).toLowerCase()} target
              {MIN_TARGET_COUNT === 1 ? ' ' : 's '}
              is required
            </LdInputMessage>
          )}
          <div className="flex w-full mb-ld-16">{children}</div>
          {errors.parameterCount && (
            <LdInputMessage className="mb-ld-16">
              At least {toWords.convert(MIN_PARAMETER_COUNT).toLowerCase()}{' '}
              parameter{MIN_PARAMETER_COUNT === 1 ? ' ' : 's '} is required
            </LdInputMessage>
          )}
          <div className="w-full flex justify-between">
            <div className="l-0">{actionArea}</div>
            <div className="flex flex-grow-0" />
            <div className="flex items-end gap-ld-16">
              {onCancel && (
                <LdButton
                  onClick={handleCancel}
                  mode="secondary"
                  type="button"
                  disabled={loading === true}
                >
                  Cancel
                </LdButton>
              )}
              <LdButton
                name="btn-submit-create-project"
                onClick={handleSubmit}
                type="button"
                progress={loading === true ? 'pending' : undefined}
                disabled={isSubmitDisabled}
              >
                Submit
              </LdButton>
            </div>
          </div>
        </form>
      </Card>
    );
  }
);

SpaceForm.displayName = 'SpaceForm';

export default SpaceForm;
