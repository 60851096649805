/* eslint-disable import/named */
import {
  createAsyncThunk,
  createSlice,
  AnyAction,
  AsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { RecommendationStatus, Recommendation } from 'types/api/types';
import { StateStatus } from 'types/app/types';
import { del, get } from 'util/api';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;

export interface SelectableRecommendation extends Recommendation {
  isSelected?: boolean;
}

export interface RecommendationsState {
  recommendations: { [key: string]: SelectableRecommendation[] };
  status: StateStatus;
  error: string | null;
}

export const initialState: RecommendationsState = {
  recommendations: {},
  status: StateStatus.idle,
  error: null,
};

function isPendingAction(action: AnyAction): action is PendingAction {
  return (
    action.type.endsWith('/pending') &&
    action.type.startsWith('recommendations/')
  );
}
function isRejectedAction(action: AnyAction): action is RejectedAction {
  return (
    action.type.endsWith('/rejected') &&
    action.type.startsWith('recommendations/')
  );
}

export const listRecommendationsForProject = createAsyncThunk(
  'recommendations/listRecommendationsForProject',
  async (payload: { projectId: string; batch?: number }) => {
    const { projectId, batch } = payload;
    const url = `/project/${projectId}/recommendation?batch=${
      batch || 'latest'
    }`;
    const data = await get(url);
    return {
      projectId,
      data: (data as Recommendation[]).map((recommendation) => ({
        ...recommendation,
        isSelected: false,
      })),
    };
  }
);

export const rejectRecommendation = createAsyncThunk(
  'recommendations/rejectRecommendation',
  async ({
    projectId,
    recommendationId,
  }: {
    projectId: string;
    recommendationId: string;
  }) => {
    await del(`/project/${projectId}/recommendation/${recommendationId}`);
    return {
      projectId,
      recommendationId,
    };
  }
);

function updateItem(
  state: WritableDraft<RecommendationsState>,
  action: {
    payload: { projectId: string; recommendationId: string; updates: any };
  }
) {
  const { projectId, recommendationId, updates } = action.payload;
  const match = state.recommendations[projectId].find(
    (recommendation) => recommendation.recommendationId === recommendationId
  );
  if (match !== undefined) {
    Object.assign(match, updates);
  }
}

export const recommendationsSlice = createSlice({
  name: 'recommendations',
  initialState,
  reducers: {
    updateRecommendation: updateItem,
    setRecommendations: (
      state,
      action: PayloadAction<{
        projectId: string;
        recommendations: Recommendation[];
      }>
    ) => {
      const { projectId, recommendations } = action.payload;
      state.recommendations[projectId] = recommendations;
    },
    addRecommendation: (
      state,
      action: PayloadAction<{
        projectId: string;
        recommendations: Recommendation;
      }>
    ) => {
      const { projectId, recommendations } = action.payload;

      if (!state.recommendations[projectId])
        state.recommendations[projectId] = [];
      const exist = state.recommendations[projectId].find(
        (e) => e.recommendationId == recommendations.recommendationId
      );
      if (!exist) state.recommendations[projectId].push(recommendations);
    },
    selectRecommendation(
      state: WritableDraft<RecommendationsState>,
      action: {
        payload: { projectId: string; recommendationId: string };
      }
    ) {
      const { projectId, recommendationId } = action.payload;
      updateItem(state, {
        payload: {
          projectId,
          recommendationId,
          updates: { isSelected: true },
        },
      });
    },
    unselectRecommendation(
      state: WritableDraft<RecommendationsState>,
      action: {
        payload: { projectId: string; recommendationId: string };
      }
    ) {
      const { projectId, recommendationId } = action.payload;
      updateItem(state, {
        payload: {
          projectId,
          recommendationId,
          updates: { isSelected: false },
        },
      });
    },
    removeRecommendations: (
      state: WritableDraft<RecommendationsState>,
      action: {
        payload: { projectId: string; recommendationIds: string[] };
      }
    ) => {
      const { projectId, recommendationIds } = action.payload;
      state.recommendations[projectId] = state.recommendations[
        projectId
      ].filter(
        ({ recommendationId }) =>
          recommendationIds.indexOf(recommendationId) === -1
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      listRecommendationsForProject.fulfilled,
      (
        state,
        { payload }: { payload: { projectId: string; data: Recommendation[] } }
      ) => {
        state.status = StateStatus.succeeded;
        state.recommendations[payload.projectId] = payload.data;
      }
    );
    builder.addCase(rejectRecommendation.fulfilled, (state, action) => {
      state.status = StateStatus.loading;
      const { projectId, recommendationId } = action.payload;
      updateItem(state, {
        payload: {
          projectId,
          recommendationId,
          updates: { status: RecommendationStatus.rejected },
        },
      });
    });
    builder
      .addMatcher(isPendingAction, (state) => {
        state.status = StateStatus.loading;
        state.error = null;
      })
      .addMatcher(isRejectedAction, (state, action) => {
        state.status = StateStatus.failed;
        state.error = (action.error as Error).message;
      });
  },
});

export const selectRecommendationsForProject =
  (projectId: string) =>
  ({
    recommendations: { recommendations },
  }: {
    recommendations: RecommendationsState;
  }) =>
    recommendations[projectId]?.filter(({ isSelected }) => !isSelected) || [];
export const {
  updateRecommendation,
  selectRecommendation,
  unselectRecommendation,
  removeRecommendations,
  setRecommendations,
  addRecommendation,
} = recommendationsSlice.actions;
export default recommendationsSlice.reducer;
