import { LdTypo, LdTooltip } from '@emdgroup-liquid/liquid/dist/react';
import TooltipData from 'assets/data/tooltips.json';

export const useHints = () => {
  const getToolTipTextById = (id: string) =>
    TooltipData.find(
      ({ HintID, Picture_ID }) => HintID === id || Picture_ID === id
    )?.Text;
  const getToolTipById = (id: string) => (
    <LdTooltip arrow className="h-1">
      <LdTypo className="p-ld-8" variant="body-xs">
        {getToolTipTextById(id)}
      </LdTypo>
    </LdTooltip>
  );
  const wrapLabel = (id: string, label: string) => (
    <span className="flex gap-2">
      {label} {getToolTipById(id)}
    </span>
  );
  return {
    tooltips: TooltipData,
    getToolTipById,
    wrapLabel,
    getToolTipTextById,
  };
};
