var __defProp = Object.defineProperty;
var __markAsModule = (target) => __defProp(target, "__esModule", { value: true });
var __commonJS = (cb, mod) => function __require() {
  return mod || (0, cb[Object.keys(cb)[0]])((mod = { exports: {} }).exports, mod), mod.exports;
};
var __export = (target, all) => {
  __markAsModule(target);
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};

// node_modules/fast-deep-equal/index.js
var require_fast_deep_equal = __commonJS({
  "node_modules/fast-deep-equal/index.js"(exports2, module2) {
    "use strict";
    module2.exports = function equal(a, b) {
      if (a === b)
        return true;
      if (a && b && typeof a == "object" && typeof b == "object") {
        if (a.constructor !== b.constructor)
          return false;
        var length, i, keys;
        if (Array.isArray(a)) {
          length = a.length;
          if (length != b.length)
            return false;
          for (i = length; i-- !== 0; )
            if (!equal(a[i], b[i]))
              return false;
          return true;
        }
        if (a.constructor === RegExp)
          return a.source === b.source && a.flags === b.flags;
        if (a.valueOf !== Object.prototype.valueOf)
          return a.valueOf() === b.valueOf();
        if (a.toString !== Object.prototype.toString)
          return a.toString() === b.toString();
        keys = Object.keys(a);
        length = keys.length;
        if (length !== Object.keys(b).length)
          return false;
        for (i = length; i-- !== 0; )
          if (!Object.prototype.hasOwnProperty.call(b, keys[i]))
            return false;
        for (i = length; i-- !== 0; ) {
          var key = keys[i];
          if (!equal(a[key], b[key]))
            return false;
        }
        return true;
      }
      return a !== a && b !== b;
    };
  }
});

// node_modules/@emdgroup/tapir/node_modules/ajv/dist/runtime/equal.js
var require_equal = __commonJS({
  "node_modules/@emdgroup/tapir/node_modules/ajv/dist/runtime/equal.js"(exports2) {
    "use strict";
    Object.defineProperty(exports2, "__esModule", { value: true });
    var equal = require_fast_deep_equal();
    equal.code = 'require("ajv/dist/runtime/equal").default';
    exports2.default = equal;
  }
});

// api/src/types/types.ajv.ts
__export(exports, {
  AcquisitionFunctionType: () => AcquisitionFunctionType,
  BatchQueryParameterStringValue: () => BatchQueryParameterStringValue,
  ExperimentStatus: () => ExperimentStatus,
  InitialGuessMethodType: () => InitialGuessMethodType,
  ParameterType: () => ParameterType,
  ProjectRole: () => ProjectRole,
  ProjectStatus: () => ProjectStatus,
  RecommendationStatus: () => RecommendationStatus,
  SurrogateModelType: () => SurrogateModelType,
  assertAcquisitionFunctionType: () => assertAcquisitionFunctionType,
  assertAnonymous1: () => assertAnonymous1,
  assertAnonymous10: () => assertAnonymous10,
  assertAnonymous10Json: () => assertAnonymous10Json,
  assertAnonymous11: () => assertAnonymous11,
  assertAnonymous11Json: () => assertAnonymous11Json,
  assertAnonymous12: () => assertAnonymous12,
  assertAnonymous15: () => assertAnonymous15,
  assertAnonymous15Json: () => assertAnonymous15Json,
  assertAnonymous16: () => assertAnonymous16,
  assertAnonymous16Json: () => assertAnonymous16Json,
  assertAnonymous17: () => assertAnonymous17,
  assertAnonymous20: () => assertAnonymous20,
  assertAnonymous20Json: () => assertAnonymous20Json,
  assertAnonymous21: () => assertAnonymous21,
  assertAnonymous21Json: () => assertAnonymous21Json,
  assertAnonymous22: () => assertAnonymous22,
  assertAnonymous22Json: () => assertAnonymous22Json,
  assertAnonymous23: () => assertAnonymous23,
  assertAnonymous24: () => assertAnonymous24,
  assertAnonymous24Json: () => assertAnonymous24Json,
  assertAnonymous25: () => assertAnonymous25,
  assertAnonymous25Json: () => assertAnonymous25Json,
  assertAnonymous26: () => assertAnonymous26,
  assertAnonymous26Json: () => assertAnonymous26Json,
  assertAnonymous27: () => assertAnonymous27,
  assertAnonymous27Json: () => assertAnonymous27Json,
  assertAnonymous28: () => assertAnonymous28,
  assertAnonymous29: () => assertAnonymous29,
  assertAnonymous29Json: () => assertAnonymous29Json,
  assertAnonymous30: () => assertAnonymous30,
  assertAnonymous30Json: () => assertAnonymous30Json,
  assertAnonymous31: () => assertAnonymous31,
  assertAnonymous31Json: () => assertAnonymous31Json,
  assertAnonymous32: () => assertAnonymous32,
  assertAnonymous32Json: () => assertAnonymous32Json,
  assertAnonymous33: () => assertAnonymous33,
  assertAnonymous33Json: () => assertAnonymous33Json,
  assertAnonymous34: () => assertAnonymous34,
  assertAnonymous35: () => assertAnonymous35,
  assertAnonymous35Json: () => assertAnonymous35Json,
  assertAnonymous36: () => assertAnonymous36,
  assertAnonymous36Json: () => assertAnonymous36Json,
  assertAnonymous37: () => assertAnonymous37,
  assertAnonymous38: () => assertAnonymous38,
  assertAnonymous38Json: () => assertAnonymous38Json,
  assertAnonymous39: () => assertAnonymous39,
  assertAnonymous39Json: () => assertAnonymous39Json,
  assertAnonymous40: () => assertAnonymous40,
  assertAnonymous40Json: () => assertAnonymous40Json,
  assertAnonymous41: () => assertAnonymous41,
  assertAnonymous42: () => assertAnonymous42,
  assertAnonymous42Json: () => assertAnonymous42Json,
  assertAnonymous43: () => assertAnonymous43,
  assertAnonymous43Json: () => assertAnonymous43Json,
  assertAnonymous44: () => assertAnonymous44,
  assertAnonymous44Json: () => assertAnonymous44Json,
  assertAnonymous45: () => assertAnonymous45,
  assertAnonymous45Json: () => assertAnonymous45Json,
  assertAnonymous46: () => assertAnonymous46,
  assertAnonymous46Json: () => assertAnonymous46Json,
  assertAnonymous6: () => assertAnonymous6,
  assertAnonymous9: () => assertAnonymous9,
  assertAnonymous9Json: () => assertAnonymous9Json,
  assertBatchQueryParameterNumericValue: () => assertBatchQueryParameterNumericValue,
  assertBatchQueryParameterStringValue: () => assertBatchQueryParameterStringValue,
  assertBatchQueryParameterValue: () => assertBatchQueryParameterValue,
  assertChangeHistoryRequest: () => assertChangeHistoryRequest,
  assertChangeHistoryRequestJson: () => assertChangeHistoryRequestJson,
  assertChangeHistoryRequestJsonDelete: () => assertChangeHistoryRequestJsonDelete,
  assertChangeHistoryRequestJsonDeleteItem: () => assertChangeHistoryRequestJsonDeleteItem,
  assertChangeHistoryRequestJsonModify: () => assertChangeHistoryRequestJsonModify,
  assertChangeHistoryRequestJsonModifyItem: () => assertChangeHistoryRequestJsonModifyItem,
  assertChangeHistoryRequestJsonModifyItemResults: () => assertChangeHistoryRequestJsonModifyItemResults,
  assertChangeHistoryRequestPathParameters: () => assertChangeHistoryRequestPathParameters,
  assertChangeHistoryResponse: () => assertChangeHistoryResponse,
  assertChangeInviteStatusRequest: () => assertChangeInviteStatusRequest,
  assertChangeInviteStatusRequestJson: () => assertChangeInviteStatusRequestJson,
  assertChangeInviteStatusRequestPathParameters: () => assertChangeInviteStatusRequestPathParameters,
  assertChangeInviteStatusResponse: () => assertChangeInviteStatusResponse,
  assertCloseProjectRequest: () => assertCloseProjectRequest,
  assertCloseProjectRequestPathParameters: () => assertCloseProjectRequestPathParameters,
  assertCloseProjectResponse: () => assertCloseProjectResponse,
  assertCloseProjectResponseJson: () => assertCloseProjectResponseJson,
  assertCreateExperimentsRequest: () => assertCreateExperimentsRequest,
  assertCreateExperimentsRequestJson: () => assertCreateExperimentsRequestJson,
  assertCreateExperimentsRequestJsonItem: () => assertCreateExperimentsRequestJsonItem,
  assertCreateExperimentsRequestJsonItemResults: () => assertCreateExperimentsRequestJsonItemResults,
  assertCreateExperimentsRequestPathParameters: () => assertCreateExperimentsRequestPathParameters,
  assertCreateExperimentsResponse: () => assertCreateExperimentsResponse,
  assertCreateFeedbackRequest: () => assertCreateFeedbackRequest,
  assertCreateFeedbackRequestJson: () => assertCreateFeedbackRequestJson,
  assertCreateFeedbackResponse: () => assertCreateFeedbackResponse,
  assertCreateFeedbackResponseJson: () => assertCreateFeedbackResponseJson,
  assertCreateProjectRequest: () => assertCreateProjectRequest,
  assertCreateProjectRequestJson: () => assertCreateProjectRequestJson,
  assertCreateProjectResponse: () => assertCreateProjectResponse,
  assertCreateRecommendationsRequest: () => assertCreateRecommendationsRequest,
  assertCreateRecommendationsRequestJson: () => assertCreateRecommendationsRequestJson,
  assertCreateRecommendationsRequestPathParameters: () => assertCreateRecommendationsRequestPathParameters,
  assertCreateRecommendationsRequestQueryStringParameters: () => assertCreateRecommendationsRequestQueryStringParameters,
  assertCreateRecommendationsResponse: () => assertCreateRecommendationsResponse,
  assertCustomParameterValue: () => assertCustomParameterValue,
  assertDeleteInviteRequest: () => assertDeleteInviteRequest,
  assertDeleteInviteRequestPathParameters: () => assertDeleteInviteRequestPathParameters,
  assertDeleteInviteResponse: () => assertDeleteInviteResponse,
  assertDeleteInviteResponseJson: () => assertDeleteInviteResponseJson,
  assertDeleteProjectRequest: () => assertDeleteProjectRequest,
  assertDeleteProjectRequestJson: () => assertDeleteProjectRequestJson,
  assertDeleteProjectResponse: () => assertDeleteProjectResponse,
  assertExpandChemicalSpaceRequest: () => assertExpandChemicalSpaceRequest,
  assertExpandChemicalSpaceRequestJson: () => assertExpandChemicalSpaceRequestJson,
  assertExpandChemicalSpaceRequestJsonExperiments: () => assertExpandChemicalSpaceRequestJsonExperiments,
  assertExpandChemicalSpaceRequestPathParameters: () => assertExpandChemicalSpaceRequestPathParameters,
  assertExpandChemicalSpaceResponse: () => assertExpandChemicalSpaceResponse,
  assertExperiment: () => assertExperiment,
  assertExperimentResults: () => assertExperimentResults,
  assertExperimentStatus: () => assertExperimentStatus,
  assertExpertOptions: () => assertExpertOptions,
  assertGetBaybeConfigsRequest: () => assertGetBaybeConfigsRequest,
  assertGetBaybeConfigsRequestPathParameters: () => assertGetBaybeConfigsRequestPathParameters,
  assertGetBaybeConfigsResponse: () => assertGetBaybeConfigsResponse,
  assertGetInviteByIdRequest: () => assertGetInviteByIdRequest,
  assertGetInviteByIdRequestPathParameters: () => assertGetInviteByIdRequestPathParameters,
  assertGetInviteByIdResponse: () => assertGetInviteByIdResponse,
  assertGetProjectByIdRequest: () => assertGetProjectByIdRequest,
  assertGetProjectByIdRequestPathParameters: () => assertGetProjectByIdRequestPathParameters,
  assertGetProjectByIdResponse: () => assertGetProjectByIdResponse,
  assertGetUserRequest: () => assertGetUserRequest,
  assertGetUserResponse: () => assertGetUserResponse,
  assertInitialGuessMethodType: () => assertInitialGuessMethodType,
  assertListExperimentsRequest: () => assertListExperimentsRequest,
  assertListExperimentsRequestPathParameters: () => assertListExperimentsRequestPathParameters,
  assertListExperimentsResponse: () => assertListExperimentsResponse,
  assertListInvitesRequest: () => assertListInvitesRequest,
  assertListInvitesRequestPathParameters: () => assertListInvitesRequestPathParameters,
  assertListInvitesResponse: () => assertListInvitesResponse,
  assertListProjectsRequest: () => assertListProjectsRequest,
  assertListProjectsResponse: () => assertListProjectsResponse,
  assertListRecommendationsRequest: () => assertListRecommendationsRequest,
  assertListRecommendationsRequestPathParameters: () => assertListRecommendationsRequestPathParameters,
  assertListRecommendationsRequestQueryStringParameters: () => assertListRecommendationsRequestQueryStringParameters,
  assertListRecommendationsResponse: () => assertListRecommendationsResponse,
  assertListUserInvitesRequest: () => assertListUserInvitesRequest,
  assertListUserInvitesRequestQueryStringParameters: () => assertListUserInvitesRequestQueryStringParameters,
  assertListUserInvitesResponse: () => assertListUserInvitesResponse,
  assertNumericParameterValue: () => assertNumericParameterValue,
  assertNumericalContinuousParameter: () => assertNumericalContinuousParameter,
  assertParameter: () => assertParameter,
  assertParameterRecommendation: () => assertParameterRecommendation,
  assertParameterRecommendationValue: () => assertParameterRecommendationValue,
  assertParameterType: () => assertParameterType,
  assertParameterValue: () => assertParameterValue,
  assertProject: () => assertProject,
  assertProjectInvite: () => assertProjectInvite,
  assertProjectRole: () => assertProjectRole,
  assertProjectStatus: () => assertProjectStatus,
  assertRecommendation: () => assertRecommendation,
  assertRecommendationStatus: () => assertRecommendationStatus,
  assertRejectRecommendationRequest: () => assertRejectRecommendationRequest,
  assertRejectRecommendationRequestPathParameters: () => assertRejectRecommendationRequestPathParameters,
  assertRejectRecommendationResponse: () => assertRejectRecommendationResponse,
  assertReopenProjectRequest: () => assertReopenProjectRequest,
  assertReopenProjectRequestPathParameters: () => assertReopenProjectRequestPathParameters,
  assertReopenProjectResponse: () => assertReopenProjectResponse,
  assertReopenProjectResponseJson: () => assertReopenProjectResponseJson,
  assertSendInviteRequest: () => assertSendInviteRequest,
  assertSendInviteRequestJson: () => assertSendInviteRequestJson,
  assertSendInviteRequestPathParameters: () => assertSendInviteRequestPathParameters,
  assertSendInviteResponse: () => assertSendInviteResponse,
  assertSmilesParameterValue: () => assertSmilesParameterValue,
  assertStringParameterValue: () => assertStringParameterValue,
  assertSurrogateModelType: () => assertSurrogateModelType,
  assertTargetVariable: () => assertTargetVariable,
  assertUser: () => assertUser,
  routes: () => routes,
  validators: () => validators
});
function assertUser(data) {
  if (exports.isUser(data))
    return;
  throw new ValidationError(exports.isUser.errors);
}
function assertBatchQueryParameterStringValue(data) {
  if (exports.isBatchQueryParameterStringValue(data))
    return;
  throw new ValidationError(exports.isBatchQueryParameterStringValue.errors);
}
var BatchQueryParameterStringValue;
(function(BatchQueryParameterStringValue2) {
  BatchQueryParameterStringValue2["all"] = "all";
  BatchQueryParameterStringValue2["latest"] = "latest";
})(BatchQueryParameterStringValue || (BatchQueryParameterStringValue = {}));
function assertBatchQueryParameterNumericValue(data) {
  if (exports.isBatchQueryParameterNumericValue(data))
    return;
  throw new ValidationError(exports.isBatchQueryParameterNumericValue.errors);
}
function assertBatchQueryParameterValue(data) {
  if (exports.isBatchQueryParameterValue(data))
    return;
  throw new ValidationError(exports.isBatchQueryParameterValue.errors);
}
function assertProjectStatus(data) {
  if (exports.isProjectStatus(data))
    return;
  throw new ValidationError(exports.isProjectStatus.errors);
}
var ProjectStatus;
(function(ProjectStatus2) {
  ProjectStatus2["creating"] = "creating";
  ProjectStatus2["draft"] = "draft";
  ProjectStatus2["idle"] = "idle";
  ProjectStatus2["error"] = "error";
  ProjectStatus2["running"] = "running";
  ProjectStatus2["closed"] = "closed";
})(ProjectStatus || (ProjectStatus = {}));
function assertExperimentStatus(data) {
  if (exports.isExperimentStatus(data))
    return;
  throw new ValidationError(exports.isExperimentStatus.errors);
}
var ExperimentStatus;
(function(ExperimentStatus2) {
  ExperimentStatus2["creating"] = "creating";
  ExperimentStatus2["running"] = "running";
  ExperimentStatus2["finished"] = "finished";
  ExperimentStatus2["error"] = "error";
})(ExperimentStatus || (ExperimentStatus = {}));
function assertRecommendationStatus(data) {
  if (exports.isRecommendationStatus(data))
    return;
  throw new ValidationError(exports.isRecommendationStatus.errors);
}
var RecommendationStatus;
(function(RecommendationStatus2) {
  RecommendationStatus2["created"] = "created";
  RecommendationStatus2["rejected"] = "rejected";
  RecommendationStatus2["finished"] = "finished";
  RecommendationStatus2["error"] = "error";
})(RecommendationStatus || (RecommendationStatus = {}));
function assertParameterType(data) {
  if (exports.isParameterType(data))
    return;
  throw new ValidationError(exports.isParameterType.errors);
}
var ParameterType;
(function(ParameterType2) {
  ParameterType2["smiles"] = "smiles";
  ParameterType2["numeric"] = "numeric";
  ParameterType2["category"] = "category";
  ParameterType2["custom"] = "custom";
  ParameterType2["task"] = "task";
  ParameterType2["continuous"] = "continuous";
})(ParameterType || (ParameterType = {}));
function assertSmilesParameterValue(data) {
  if (exports.isSmilesParameterValue(data))
    return;
  throw new ValidationError(exports.isSmilesParameterValue.errors);
}
function assertStringParameterValue(data) {
  if (exports.isStringParameterValue(data))
    return;
  throw new ValidationError(exports.isStringParameterValue.errors);
}
function assertNumericParameterValue(data) {
  if (exports.isNumericParameterValue(data))
    return;
  throw new ValidationError(exports.isNumericParameterValue.errors);
}
function assertCustomParameterValue(data) {
  if (exports.isCustomParameterValue(data))
    return;
  throw new ValidationError(exports.isCustomParameterValue.errors);
}
function assertNumericalContinuousParameter(data) {
  if (exports.isNumericalContinuousParameter(data))
    return;
  throw new ValidationError(exports.isNumericalContinuousParameter.errors);
}
function assertAnonymous1(data) {
  if (exports.isAnonymous1(data))
    return;
  throw new ValidationError(exports.isAnonymous1.errors);
}
function assertParameterValue(data) {
  if (exports.isParameterValue(data))
    return;
  throw new ValidationError(exports.isParameterValue.errors);
}
function assertParameterRecommendationValue(data) {
  if (exports.isParameterRecommendationValue(data))
    return;
  throw new ValidationError(exports.isParameterRecommendationValue.errors);
}
function assertParameter(data) {
  if (exports.isParameter(data))
    return;
  throw new ValidationError(exports.isParameter.errors);
}
function assertParameterRecommendation(data) {
  if (exports.isParameterRecommendation(data))
    return;
  throw new ValidationError(exports.isParameterRecommendation.errors);
}
function assertAcquisitionFunctionType(data) {
  if (exports.isAcquisitionFunctionType(data))
    return;
  throw new ValidationError(exports.isAcquisitionFunctionType.errors);
}
var AcquisitionFunctionType;
(function(AcquisitionFunctionType2) {
  AcquisitionFunctionType2["EI"] = "EI";
  AcquisitionFunctionType2["TS"] = "TS";
  AcquisitionFunctionType2["PI"] = "PI";
  AcquisitionFunctionType2["UCB"] = "UCB";
  AcquisitionFunctionType2["MeanMax"] = "MeanMax";
  AcquisitionFunctionType2["VarMax"] = "VarMax";
})(AcquisitionFunctionType || (AcquisitionFunctionType = {}));
function assertInitialGuessMethodType(data) {
  if (exports.isInitialGuessMethodType(data))
    return;
  throw new ValidationError(exports.isInitialGuessMethodType.errors);
}
var InitialGuessMethodType;
(function(InitialGuessMethodType2) {
  InitialGuessMethodType2["rand"] = "rand";
  InitialGuessMethodType2["pam"] = "pam";
  InitialGuessMethodType2["kmeans"] = "kmeans";
})(InitialGuessMethodType || (InitialGuessMethodType = {}));
function assertSurrogateModelType(data) {
  if (exports.isSurrogateModelType(data))
    return;
  throw new ValidationError(exports.isSurrogateModelType.errors);
}
var SurrogateModelType;
(function(SurrogateModelType2) {
  SurrogateModelType2["GP"] = "GP";
  SurrogateModelType2["RF"] = "RF";
  SurrogateModelType2["Linear"] = "Linear";
})(SurrogateModelType || (SurrogateModelType = {}));
function assertExpertOptions(data) {
  if (exports.isExpertOptions(data))
    return;
  throw new ValidationError(exports.isExpertOptions.errors);
}
function assertTargetVariable(data) {
  if (exports.isTargetVariable(data))
    return;
  throw new ValidationError(exports.isTargetVariable.errors);
}
function assertProjectRole(data) {
  if (exports.isProjectRole(data))
    return;
  throw new ValidationError(exports.isProjectRole.errors);
}
var ProjectRole;
(function(ProjectRole2) {
  ProjectRole2["Owner"] = "Owner";
  ProjectRole2["Editor"] = "Editor";
  ProjectRole2["Viewer"] = "Viewer";
})(ProjectRole || (ProjectRole = {}));
function assertProjectInvite(data) {
  if (exports.isProjectInvite(data))
    return;
  throw new ValidationError(exports.isProjectInvite.errors);
}
function assertProject(data) {
  if (exports.isProject(data))
    return;
  throw new ValidationError(exports.isProject.errors);
}
function assertExperimentResults(data) {
  if (exports.isExperimentResults(data))
    return;
  throw new ValidationError(exports.isExperimentResults.errors);
}
function assertExperiment(data) {
  if (exports.isExperiment(data))
    return;
  throw new ValidationError(exports.isExperiment.errors);
}
function assertRecommendation(data) {
  if (exports.isRecommendation(data))
    return;
  throw new ValidationError(exports.isRecommendation.errors);
}
function assertGetUserResponse(data) {
  if (exports.isGetUserResponse(data))
    return;
  throw new ValidationError(exports.isGetUserResponse.errors);
}
function assertGetUserRequest(data) {
  if (exports.isGetUserRequest(data))
    return;
  throw new ValidationError(exports.isGetUserRequest.errors);
}
function assertListUserInvitesResponse(data) {
  if (exports.isListUserInvitesResponse(data))
    return;
  throw new ValidationError(exports.isListUserInvitesResponse.errors);
}
function assertListUserInvitesRequestQueryStringParameters(data) {
  if (exports.isListUserInvitesRequestQueryStringParameters(data))
    return;
  throw new ValidationError(exports.isListUserInvitesRequestQueryStringParameters.errors);
}
function assertListUserInvitesRequest(data) {
  if (exports.isListUserInvitesRequest(data))
    return;
  throw new ValidationError(exports.isListUserInvitesRequest.errors);
}
function assertListProjectsResponse(data) {
  if (exports.isListProjectsResponse(data))
    return;
  throw new ValidationError(exports.isListProjectsResponse.errors);
}
function assertListProjectsRequest(data) {
  if (exports.isListProjectsRequest(data))
    return;
  throw new ValidationError(exports.isListProjectsRequest.errors);
}
function assertAnonymous6(data) {
  if (exports.isAnonymous6(data))
    return;
  throw new ValidationError(exports.isAnonymous6.errors);
}
function assertAnonymous9Json(data) {
  if (exports.isAnonymous9Json(data))
    return;
  throw new ValidationError(exports.isAnonymous9Json.errors);
}
function assertAnonymous9(data) {
  if (exports.isAnonymous9(data))
    return;
  throw new ValidationError(exports.isAnonymous9.errors);
}
function assertCreateProjectResponse(data) {
  if (exports.isCreateProjectResponse(data))
    return;
  throw new ValidationError(exports.isCreateProjectResponse.errors);
}
function assertCreateProjectRequestJson(data) {
  if (exports.isCreateProjectRequestJson(data))
    return;
  throw new ValidationError(exports.isCreateProjectRequestJson.errors);
}
function assertCreateProjectRequest(data) {
  if (exports.isCreateProjectRequest(data))
    return;
  throw new ValidationError(exports.isCreateProjectRequest.errors);
}
function assertAnonymous10Json(data) {
  if (exports.isAnonymous10Json(data))
    return;
  throw new ValidationError(exports.isAnonymous10Json.errors);
}
function assertAnonymous10(data) {
  if (exports.isAnonymous10(data))
    return;
  throw new ValidationError(exports.isAnonymous10.errors);
}
function assertAnonymous11Json(data) {
  if (exports.isAnonymous11Json(data))
    return;
  throw new ValidationError(exports.isAnonymous11Json.errors);
}
function assertAnonymous11(data) {
  if (exports.isAnonymous11(data))
    return;
  throw new ValidationError(exports.isAnonymous11.errors);
}
function assertDeleteProjectResponse(data) {
  if (exports.isDeleteProjectResponse(data))
    return;
  throw new ValidationError(exports.isDeleteProjectResponse.errors);
}
function assertDeleteProjectRequestJson(data) {
  if (exports.isDeleteProjectRequestJson(data))
    return;
  throw new ValidationError(exports.isDeleteProjectRequestJson.errors);
}
function assertDeleteProjectRequest(data) {
  if (exports.isDeleteProjectRequest(data))
    return;
  throw new ValidationError(exports.isDeleteProjectRequest.errors);
}
function assertAnonymous12(data) {
  if (exports.isAnonymous12(data))
    return;
  throw new ValidationError(exports.isAnonymous12.errors);
}
function assertAnonymous15Json(data) {
  if (exports.isAnonymous15Json(data))
    return;
  throw new ValidationError(exports.isAnonymous15Json.errors);
}
function assertAnonymous15(data) {
  if (exports.isAnonymous15(data))
    return;
  throw new ValidationError(exports.isAnonymous15.errors);
}
function assertAnonymous16Json(data) {
  if (exports.isAnonymous16Json(data))
    return;
  throw new ValidationError(exports.isAnonymous16Json.errors);
}
function assertAnonymous16(data) {
  if (exports.isAnonymous16(data))
    return;
  throw new ValidationError(exports.isAnonymous16.errors);
}
function assertGetProjectByIdResponse(data) {
  if (exports.isGetProjectByIdResponse(data))
    return;
  throw new ValidationError(exports.isGetProjectByIdResponse.errors);
}
function assertGetProjectByIdRequestPathParameters(data) {
  if (exports.isGetProjectByIdRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isGetProjectByIdRequestPathParameters.errors);
}
function assertGetProjectByIdRequest(data) {
  if (exports.isGetProjectByIdRequest(data))
    return;
  throw new ValidationError(exports.isGetProjectByIdRequest.errors);
}
function assertAnonymous17(data) {
  if (exports.isAnonymous17(data))
    return;
  throw new ValidationError(exports.isAnonymous17.errors);
}
function assertAnonymous20Json(data) {
  if (exports.isAnonymous20Json(data))
    return;
  throw new ValidationError(exports.isAnonymous20Json.errors);
}
function assertAnonymous20(data) {
  if (exports.isAnonymous20(data))
    return;
  throw new ValidationError(exports.isAnonymous20.errors);
}
function assertAnonymous21Json(data) {
  if (exports.isAnonymous21Json(data))
    return;
  throw new ValidationError(exports.isAnonymous21Json.errors);
}
function assertAnonymous21(data) {
  if (exports.isAnonymous21(data))
    return;
  throw new ValidationError(exports.isAnonymous21.errors);
}
function assertAnonymous22Json(data) {
  if (exports.isAnonymous22Json(data))
    return;
  throw new ValidationError(exports.isAnonymous22Json.errors);
}
function assertAnonymous22(data) {
  if (exports.isAnonymous22(data))
    return;
  throw new ValidationError(exports.isAnonymous22.errors);
}
function assertExpandChemicalSpaceResponse(data) {
  if (exports.isExpandChemicalSpaceResponse(data))
    return;
  throw new ValidationError(exports.isExpandChemicalSpaceResponse.errors);
}
function assertExpandChemicalSpaceRequestPathParameters(data) {
  if (exports.isExpandChemicalSpaceRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isExpandChemicalSpaceRequestPathParameters.errors);
}
function assertExpandChemicalSpaceRequestJsonExperiments(data) {
  if (exports.isExpandChemicalSpaceRequestJsonExperiments(data))
    return;
  throw new ValidationError(exports.isExpandChemicalSpaceRequestJsonExperiments.errors);
}
function assertExpandChemicalSpaceRequestJson(data) {
  if (exports.isExpandChemicalSpaceRequestJson(data))
    return;
  throw new ValidationError(exports.isExpandChemicalSpaceRequestJson.errors);
}
function assertExpandChemicalSpaceRequest(data) {
  if (exports.isExpandChemicalSpaceRequest(data))
    return;
  throw new ValidationError(exports.isExpandChemicalSpaceRequest.errors);
}
function assertCloseProjectResponseJson(data) {
  if (exports.isCloseProjectResponseJson(data))
    return;
  throw new ValidationError(exports.isCloseProjectResponseJson.errors);
}
function assertCloseProjectResponse(data) {
  if (exports.isCloseProjectResponse(data))
    return;
  throw new ValidationError(exports.isCloseProjectResponse.errors);
}
function assertCloseProjectRequestPathParameters(data) {
  if (exports.isCloseProjectRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isCloseProjectRequestPathParameters.errors);
}
function assertCloseProjectRequest(data) {
  if (exports.isCloseProjectRequest(data))
    return;
  throw new ValidationError(exports.isCloseProjectRequest.errors);
}
function assertReopenProjectResponseJson(data) {
  if (exports.isReopenProjectResponseJson(data))
    return;
  throw new ValidationError(exports.isReopenProjectResponseJson.errors);
}
function assertReopenProjectResponse(data) {
  if (exports.isReopenProjectResponse(data))
    return;
  throw new ValidationError(exports.isReopenProjectResponse.errors);
}
function assertReopenProjectRequestPathParameters(data) {
  if (exports.isReopenProjectRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isReopenProjectRequestPathParameters.errors);
}
function assertReopenProjectRequest(data) {
  if (exports.isReopenProjectRequest(data))
    return;
  throw new ValidationError(exports.isReopenProjectRequest.errors);
}
function assertListInvitesResponse(data) {
  if (exports.isListInvitesResponse(data))
    return;
  throw new ValidationError(exports.isListInvitesResponse.errors);
}
function assertListInvitesRequestPathParameters(data) {
  if (exports.isListInvitesRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isListInvitesRequestPathParameters.errors);
}
function assertListInvitesRequest(data) {
  if (exports.isListInvitesRequest(data))
    return;
  throw new ValidationError(exports.isListInvitesRequest.errors);
}
function assertSendInviteResponse(data) {
  if (exports.isSendInviteResponse(data))
    return;
  throw new ValidationError(exports.isSendInviteResponse.errors);
}
function assertSendInviteRequestPathParameters(data) {
  if (exports.isSendInviteRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isSendInviteRequestPathParameters.errors);
}
function assertSendInviteRequestJson(data) {
  if (exports.isSendInviteRequestJson(data))
    return;
  throw new ValidationError(exports.isSendInviteRequestJson.errors);
}
function assertSendInviteRequest(data) {
  if (exports.isSendInviteRequest(data))
    return;
  throw new ValidationError(exports.isSendInviteRequest.errors);
}
function assertGetInviteByIdResponse(data) {
  if (exports.isGetInviteByIdResponse(data))
    return;
  throw new ValidationError(exports.isGetInviteByIdResponse.errors);
}
function assertGetInviteByIdRequestPathParameters(data) {
  if (exports.isGetInviteByIdRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isGetInviteByIdRequestPathParameters.errors);
}
function assertGetInviteByIdRequest(data) {
  if (exports.isGetInviteByIdRequest(data))
    return;
  throw new ValidationError(exports.isGetInviteByIdRequest.errors);
}
function assertAnonymous23(data) {
  if (exports.isAnonymous23(data))
    return;
  throw new ValidationError(exports.isAnonymous23.errors);
}
function assertAnonymous24Json(data) {
  if (exports.isAnonymous24Json(data))
    return;
  throw new ValidationError(exports.isAnonymous24Json.errors);
}
function assertAnonymous24(data) {
  if (exports.isAnonymous24(data))
    return;
  throw new ValidationError(exports.isAnonymous24.errors);
}
function assertChangeInviteStatusResponse(data) {
  if (exports.isChangeInviteStatusResponse(data))
    return;
  throw new ValidationError(exports.isChangeInviteStatusResponse.errors);
}
function assertChangeInviteStatusRequestPathParameters(data) {
  if (exports.isChangeInviteStatusRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isChangeInviteStatusRequestPathParameters.errors);
}
function assertChangeInviteStatusRequestJson(data) {
  if (exports.isChangeInviteStatusRequestJson(data))
    return;
  throw new ValidationError(exports.isChangeInviteStatusRequestJson.errors);
}
function assertChangeInviteStatusRequest(data) {
  if (exports.isChangeInviteStatusRequest(data))
    return;
  throw new ValidationError(exports.isChangeInviteStatusRequest.errors);
}
function assertDeleteInviteResponseJson(data) {
  if (exports.isDeleteInviteResponseJson(data))
    return;
  throw new ValidationError(exports.isDeleteInviteResponseJson.errors);
}
function assertDeleteInviteResponse(data) {
  if (exports.isDeleteInviteResponse(data))
    return;
  throw new ValidationError(exports.isDeleteInviteResponse.errors);
}
function assertDeleteInviteRequestPathParameters(data) {
  if (exports.isDeleteInviteRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isDeleteInviteRequestPathParameters.errors);
}
function assertDeleteInviteRequest(data) {
  if (exports.isDeleteInviteRequest(data))
    return;
  throw new ValidationError(exports.isDeleteInviteRequest.errors);
}
function assertAnonymous25Json(data) {
  if (exports.isAnonymous25Json(data))
    return;
  throw new ValidationError(exports.isAnonymous25Json.errors);
}
function assertAnonymous25(data) {
  if (exports.isAnonymous25(data))
    return;
  throw new ValidationError(exports.isAnonymous25.errors);
}
function assertAnonymous26Json(data) {
  if (exports.isAnonymous26Json(data))
    return;
  throw new ValidationError(exports.isAnonymous26Json.errors);
}
function assertAnonymous26(data) {
  if (exports.isAnonymous26(data))
    return;
  throw new ValidationError(exports.isAnonymous26.errors);
}
function assertAnonymous27Json(data) {
  if (exports.isAnonymous27Json(data))
    return;
  throw new ValidationError(exports.isAnonymous27Json.errors);
}
function assertAnonymous27(data) {
  if (exports.isAnonymous27(data))
    return;
  throw new ValidationError(exports.isAnonymous27.errors);
}
function assertCreateRecommendationsResponse(data) {
  if (exports.isCreateRecommendationsResponse(data))
    return;
  throw new ValidationError(exports.isCreateRecommendationsResponse.errors);
}
function assertCreateRecommendationsRequestPathParameters(data) {
  if (exports.isCreateRecommendationsRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isCreateRecommendationsRequestPathParameters.errors);
}
function assertCreateRecommendationsRequestQueryStringParameters(data) {
  if (exports.isCreateRecommendationsRequestQueryStringParameters(data))
    return;
  throw new ValidationError(exports.isCreateRecommendationsRequestQueryStringParameters.errors);
}
function assertCreateRecommendationsRequestJson(data) {
  if (exports.isCreateRecommendationsRequestJson(data))
    return;
  throw new ValidationError(exports.isCreateRecommendationsRequestJson.errors);
}
function assertCreateRecommendationsRequest(data) {
  if (exports.isCreateRecommendationsRequest(data))
    return;
  throw new ValidationError(exports.isCreateRecommendationsRequest.errors);
}
function assertAnonymous28(data) {
  if (exports.isAnonymous28(data))
    return;
  throw new ValidationError(exports.isAnonymous28.errors);
}
function assertAnonymous29Json(data) {
  if (exports.isAnonymous29Json(data))
    return;
  throw new ValidationError(exports.isAnonymous29Json.errors);
}
function assertAnonymous29(data) {
  if (exports.isAnonymous29(data))
    return;
  throw new ValidationError(exports.isAnonymous29.errors);
}
function assertAnonymous30Json(data) {
  if (exports.isAnonymous30Json(data))
    return;
  throw new ValidationError(exports.isAnonymous30Json.errors);
}
function assertAnonymous30(data) {
  if (exports.isAnonymous30(data))
    return;
  throw new ValidationError(exports.isAnonymous30.errors);
}
function assertListRecommendationsResponse(data) {
  if (exports.isListRecommendationsResponse(data))
    return;
  throw new ValidationError(exports.isListRecommendationsResponse.errors);
}
function assertListRecommendationsRequestPathParameters(data) {
  if (exports.isListRecommendationsRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isListRecommendationsRequestPathParameters.errors);
}
function assertListRecommendationsRequestQueryStringParameters(data) {
  if (exports.isListRecommendationsRequestQueryStringParameters(data))
    return;
  throw new ValidationError(exports.isListRecommendationsRequestQueryStringParameters.errors);
}
function assertListRecommendationsRequest(data) {
  if (exports.isListRecommendationsRequest(data))
    return;
  throw new ValidationError(exports.isListRecommendationsRequest.errors);
}
function assertAnonymous31Json(data) {
  if (exports.isAnonymous31Json(data))
    return;
  throw new ValidationError(exports.isAnonymous31Json.errors);
}
function assertAnonymous31(data) {
  if (exports.isAnonymous31(data))
    return;
  throw new ValidationError(exports.isAnonymous31.errors);
}
function assertAnonymous32Json(data) {
  if (exports.isAnonymous32Json(data))
    return;
  throw new ValidationError(exports.isAnonymous32Json.errors);
}
function assertAnonymous32(data) {
  if (exports.isAnonymous32(data))
    return;
  throw new ValidationError(exports.isAnonymous32.errors);
}
function assertAnonymous33Json(data) {
  if (exports.isAnonymous33Json(data))
    return;
  throw new ValidationError(exports.isAnonymous33Json.errors);
}
function assertAnonymous33(data) {
  if (exports.isAnonymous33(data))
    return;
  throw new ValidationError(exports.isAnonymous33.errors);
}
function assertRejectRecommendationResponse(data) {
  if (exports.isRejectRecommendationResponse(data))
    return;
  throw new ValidationError(exports.isRejectRecommendationResponse.errors);
}
function assertRejectRecommendationRequestPathParameters(data) {
  if (exports.isRejectRecommendationRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isRejectRecommendationRequestPathParameters.errors);
}
function assertRejectRecommendationRequest(data) {
  if (exports.isRejectRecommendationRequest(data))
    return;
  throw new ValidationError(exports.isRejectRecommendationRequest.errors);
}
function assertAnonymous34(data) {
  if (exports.isAnonymous34(data))
    return;
  throw new ValidationError(exports.isAnonymous34.errors);
}
function assertAnonymous35Json(data) {
  if (exports.isAnonymous35Json(data))
    return;
  throw new ValidationError(exports.isAnonymous35Json.errors);
}
function assertAnonymous35(data) {
  if (exports.isAnonymous35(data))
    return;
  throw new ValidationError(exports.isAnonymous35.errors);
}
function assertAnonymous36Json(data) {
  if (exports.isAnonymous36Json(data))
    return;
  throw new ValidationError(exports.isAnonymous36Json.errors);
}
function assertAnonymous36(data) {
  if (exports.isAnonymous36(data))
    return;
  throw new ValidationError(exports.isAnonymous36.errors);
}
function assertListExperimentsResponse(data) {
  if (exports.isListExperimentsResponse(data))
    return;
  throw new ValidationError(exports.isListExperimentsResponse.errors);
}
function assertListExperimentsRequestPathParameters(data) {
  if (exports.isListExperimentsRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isListExperimentsRequestPathParameters.errors);
}
function assertListExperimentsRequest(data) {
  if (exports.isListExperimentsRequest(data))
    return;
  throw new ValidationError(exports.isListExperimentsRequest.errors);
}
function assertAnonymous37(data) {
  if (exports.isAnonymous37(data))
    return;
  throw new ValidationError(exports.isAnonymous37.errors);
}
function assertAnonymous38Json(data) {
  if (exports.isAnonymous38Json(data))
    return;
  throw new ValidationError(exports.isAnonymous38Json.errors);
}
function assertAnonymous38(data) {
  if (exports.isAnonymous38(data))
    return;
  throw new ValidationError(exports.isAnonymous38.errors);
}
function assertAnonymous39Json(data) {
  if (exports.isAnonymous39Json(data))
    return;
  throw new ValidationError(exports.isAnonymous39Json.errors);
}
function assertAnonymous39(data) {
  if (exports.isAnonymous39(data))
    return;
  throw new ValidationError(exports.isAnonymous39.errors);
}
function assertAnonymous40Json(data) {
  if (exports.isAnonymous40Json(data))
    return;
  throw new ValidationError(exports.isAnonymous40Json.errors);
}
function assertAnonymous40(data) {
  if (exports.isAnonymous40(data))
    return;
  throw new ValidationError(exports.isAnonymous40.errors);
}
function assertCreateExperimentsResponse(data) {
  if (exports.isCreateExperimentsResponse(data))
    return;
  throw new ValidationError(exports.isCreateExperimentsResponse.errors);
}
function assertCreateExperimentsRequestPathParameters(data) {
  if (exports.isCreateExperimentsRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isCreateExperimentsRequestPathParameters.errors);
}
function assertCreateExperimentsRequestJsonItemResults(data) {
  if (exports.isCreateExperimentsRequestJsonItemResults(data))
    return;
  throw new ValidationError(exports.isCreateExperimentsRequestJsonItemResults.errors);
}
function assertCreateExperimentsRequestJsonItem(data) {
  if (exports.isCreateExperimentsRequestJsonItem(data))
    return;
  throw new ValidationError(exports.isCreateExperimentsRequestJsonItem.errors);
}
function assertCreateExperimentsRequestJson(data) {
  if (exports.isCreateExperimentsRequestJson(data))
    return;
  throw new ValidationError(exports.isCreateExperimentsRequestJson.errors);
}
function assertCreateExperimentsRequest(data) {
  if (exports.isCreateExperimentsRequest(data))
    return;
  throw new ValidationError(exports.isCreateExperimentsRequest.errors);
}
function assertAnonymous41(data) {
  if (exports.isAnonymous41(data))
    return;
  throw new ValidationError(exports.isAnonymous41.errors);
}
function assertAnonymous42Json(data) {
  if (exports.isAnonymous42Json(data))
    return;
  throw new ValidationError(exports.isAnonymous42Json.errors);
}
function assertAnonymous42(data) {
  if (exports.isAnonymous42(data))
    return;
  throw new ValidationError(exports.isAnonymous42.errors);
}
function assertAnonymous43Json(data) {
  if (exports.isAnonymous43Json(data))
    return;
  throw new ValidationError(exports.isAnonymous43Json.errors);
}
function assertAnonymous43(data) {
  if (exports.isAnonymous43(data))
    return;
  throw new ValidationError(exports.isAnonymous43.errors);
}
function assertChangeHistoryResponse(data) {
  if (exports.isChangeHistoryResponse(data))
    return;
  throw new ValidationError(exports.isChangeHistoryResponse.errors);
}
function assertChangeHistoryRequestPathParameters(data) {
  if (exports.isChangeHistoryRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isChangeHistoryRequestPathParameters.errors);
}
function assertChangeHistoryRequestJsonDeleteItem(data) {
  if (exports.isChangeHistoryRequestJsonDeleteItem(data))
    return;
  throw new ValidationError(exports.isChangeHistoryRequestJsonDeleteItem.errors);
}
function assertChangeHistoryRequestJsonDelete(data) {
  if (exports.isChangeHistoryRequestJsonDelete(data))
    return;
  throw new ValidationError(exports.isChangeHistoryRequestJsonDelete.errors);
}
function assertChangeHistoryRequestJsonModifyItemResults(data) {
  if (exports.isChangeHistoryRequestJsonModifyItemResults(data))
    return;
  throw new ValidationError(exports.isChangeHistoryRequestJsonModifyItemResults.errors);
}
function assertChangeHistoryRequestJsonModifyItem(data) {
  if (exports.isChangeHistoryRequestJsonModifyItem(data))
    return;
  throw new ValidationError(exports.isChangeHistoryRequestJsonModifyItem.errors);
}
function assertChangeHistoryRequestJsonModify(data) {
  if (exports.isChangeHistoryRequestJsonModify(data))
    return;
  throw new ValidationError(exports.isChangeHistoryRequestJsonModify.errors);
}
function assertChangeHistoryRequestJson(data) {
  if (exports.isChangeHistoryRequestJson(data))
    return;
  throw new ValidationError(exports.isChangeHistoryRequestJson.errors);
}
function assertChangeHistoryRequest(data) {
  if (exports.isChangeHistoryRequest(data))
    return;
  throw new ValidationError(exports.isChangeHistoryRequest.errors);
}
function assertAnonymous44Json(data) {
  if (exports.isAnonymous44Json(data))
    return;
  throw new ValidationError(exports.isAnonymous44Json.errors);
}
function assertAnonymous44(data) {
  if (exports.isAnonymous44(data))
    return;
  throw new ValidationError(exports.isAnonymous44.errors);
}
function assertAnonymous45Json(data) {
  if (exports.isAnonymous45Json(data))
    return;
  throw new ValidationError(exports.isAnonymous45Json.errors);
}
function assertAnonymous45(data) {
  if (exports.isAnonymous45(data))
    return;
  throw new ValidationError(exports.isAnonymous45.errors);
}
function assertAnonymous46Json(data) {
  if (exports.isAnonymous46Json(data))
    return;
  throw new ValidationError(exports.isAnonymous46Json.errors);
}
function assertAnonymous46(data) {
  if (exports.isAnonymous46(data))
    return;
  throw new ValidationError(exports.isAnonymous46.errors);
}
function assertGetBaybeConfigsResponse(data) {
  if (exports.isGetBaybeConfigsResponse(data))
    return;
  throw new ValidationError(exports.isGetBaybeConfigsResponse.errors);
}
function assertGetBaybeConfigsRequestPathParameters(data) {
  if (exports.isGetBaybeConfigsRequestPathParameters(data))
    return;
  throw new ValidationError(exports.isGetBaybeConfigsRequestPathParameters.errors);
}
function assertGetBaybeConfigsRequest(data) {
  if (exports.isGetBaybeConfigsRequest(data))
    return;
  throw new ValidationError(exports.isGetBaybeConfigsRequest.errors);
}
function assertCreateFeedbackResponseJson(data) {
  if (exports.isCreateFeedbackResponseJson(data))
    return;
  throw new ValidationError(exports.isCreateFeedbackResponseJson.errors);
}
function assertCreateFeedbackResponse(data) {
  if (exports.isCreateFeedbackResponse(data))
    return;
  throw new ValidationError(exports.isCreateFeedbackResponse.errors);
}
function assertCreateFeedbackRequestJson(data) {
  if (exports.isCreateFeedbackRequestJson(data))
    return;
  throw new ValidationError(exports.isCreateFeedbackRequestJson.errors);
}
function assertCreateFeedbackRequest(data) {
  if (exports.isCreateFeedbackRequest(data))
    return;
  throw new ValidationError(exports.isCreateFeedbackRequest.errors);
}
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidationError = void 0;
var isNumber = new RegExp(/^\d+$/);
var ValidationError = class extends Error {
  constructor(errors) {
    super();
    this.name = "ValidationError";
    this.errors = this.translate([...errors]);
  }
  translate(input) {
    const errors = [];
    for (const error of input) {
      const [_, rawField, nested] = error.instancePath.split("/");
      const field = rawField !== void 0 && rawField.length && !isNumber.test(rawField) ? { field: rawField } : {};
      if (nested || isNumber.test(rawField)) {
        const err = this.translate([{
          ...error,
          instancePath: "/"
        }]);
        errors.push({
          name: "Nested",
          ...field,
          index: parseInt(nested !== null && nested !== void 0 ? nested : rawField, 10),
          errors: err
        });
      } else if (error.keyword === "enum") {
        errors.push({
          name: "Enum",
          ...field
        });
      } else if (error.keyword === "required") {
        errors.push({
          name: "Required",
          field: error.params.missingProperty
        });
      } else if (error.keyword === "type") {
        errors.push({
          name: "TypeMismatch",
          expected: error.params.type,
          ...field
        });
      } else if (error.keyword === "additionalProperties") {
        errors.push({
          name: "AdditionalProperties",
          actual: [error.params.additionalProperty],
          expected: [],
          ...field
        });
      }
    }
    return errors;
  }
  toString() {
    return JSON.stringify(this.errors);
  }
};
exports.ValidationError = ValidationError;
exports.isUser = validate21;
function validate21(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.email === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "email" }, message: "must have required property 'email'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.userId !== void 0) {
      if (typeof data.userId !== "string") {
        const err1 = { instancePath: instancePath + "/userId", schemaPath: "#/properties/userId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
    if (data.email !== void 0) {
      if (typeof data.email !== "string") {
        const err2 = { instancePath: instancePath + "/email", schemaPath: "#/properties/email/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.name !== void 0) {
      if (typeof data.name !== "string") {
        const err3 = { instancePath: instancePath + "/name", schemaPath: "#/properties/name/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate21.errors = vErrors;
  return errors === 0;
}
exports.isBatchQueryParameterStringValue = validate22;
var schema7 = { "type": "string", "enum": ["all", "latest"], "default": "latest" };
var func0 = require_equal().default;
function validate22(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "all" || data === "latest")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema7.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate22.errors = vErrors;
  return errors === 0;
}
exports.isBatchQueryParameterNumericValue = validate23;
function validate23(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(typeof data == "number" && isFinite(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "number" }, message: "must be number" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate23.errors = vErrors;
  return errors === 0;
}
exports.isBatchQueryParameterValue = validate24;
function validate24(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  const _errs0 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs1 = errors;
  if (!validate22(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate22.errors : vErrors.concat(validate22.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs1 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs2 = errors;
  if (!validate23(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate23.errors : vErrors.concat(validate23.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
  }
  if (!valid0) {
    const err0 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  } else {
    errors = _errs0;
    if (vErrors !== null) {
      if (_errs0) {
        vErrors.length = _errs0;
      } else {
        vErrors = null;
      }
    }
  }
  validate24.errors = vErrors;
  return errors === 0;
}
exports.isProjectStatus = validate27;
var schema10 = { "type": "string", "enum": ["creating", "draft", "idle", "error", "running", "closed"] };
function validate27(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "creating" || data === "draft" || data === "idle" || data === "error" || data === "running" || data === "closed")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema10.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate27.errors = vErrors;
  return errors === 0;
}
exports.isExperimentStatus = validate28;
var schema11 = { "type": "string", "enum": ["creating", "running", "finished", "error"] };
function validate28(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "creating" || data === "running" || data === "finished" || data === "error")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema11.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate28.errors = vErrors;
  return errors === 0;
}
exports.isRecommendationStatus = validate29;
var schema12 = { "type": "string", "enum": ["created", "rejected", "finished", "error"] };
function validate29(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "created" || data === "rejected" || data === "finished" || data === "error")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema12.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate29.errors = vErrors;
  return errors === 0;
}
exports.isParameterType = validate30;
var schema13 = { "type": "string", "enum": ["smiles", "numeric", "category", "custom", "task", "continuous"], "example": "numeric" };
function validate30(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "smiles" || data === "numeric" || data === "category" || data === "custom" || data === "task" || data === "continuous")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema13.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate30.errors = vErrors;
  return errors === 0;
}
exports.isSmilesParameterValue = validate31;
function validate31(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.Name === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "Name" }, message: "must have required property 'Name'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    for (const key0 in data) {
      if (!(key0 === "Name" || key0 === "SMILES")) {
        const err1 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
    if (data.Name !== void 0) {
      if (typeof data.Name !== "string") {
        const err2 = { instancePath: instancePath + "/Name", schemaPath: "#/properties/Name/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.SMILES !== void 0) {
      if (typeof data.SMILES !== "string") {
        const err3 = { instancePath: instancePath + "/SMILES", schemaPath: "#/properties/SMILES/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate31.errors = vErrors;
  return errors === 0;
}
exports.isStringParameterValue = validate32;
function validate32(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate32.errors = vErrors;
  return errors === 0;
}
exports.isNumericParameterValue = validate33;
function validate33(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(typeof data == "number" && isFinite(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "number" }, message: "must be number" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate33.errors = vErrors;
  return errors === 0;
}
exports.isCustomParameterValue = validate34;
function validate34(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      if (typeof data[key0] !== "string") {
        const err0 = { instancePath: instancePath + "/" + key0.replace(/~/g, "~0").replace(/\//g, "~1"), schemaPath: "#/additionalProperties/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err0];
        } else {
          vErrors.push(err0);
        }
        errors++;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate34.errors = vErrors;
  return errors === 0;
}
exports.isNumericalContinuousParameter = validate35;
function validate35(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (Array.isArray(data)) {
    const len0 = data.length;
    for (let i0 = 0; i0 < len0; i0++) {
      let data0 = data[i0];
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err0 = { instancePath: instancePath + "/" + i0, schemaPath: "#/items/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err0];
        } else {
          vErrors.push(err0);
        }
        errors++;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "array" }, message: "must be array" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err2 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
  }
  validate35.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous1 = validate36;
function validate36(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
  } else {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate36.errors = vErrors;
  return errors === 0;
}
exports.isParameterValue = validate37;
function validate37(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  const _errs0 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs1 = errors;
  if (!(typeof data == "number" && isFinite(data))) {
    const err0 = { instancePath, schemaPath: "#/oneOf/0/type", keyword: "type", params: { type: "number" }, message: "must be number" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  var _valid0 = _errs1 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (typeof data !== "string") {
    const err1 = { instancePath, schemaPath: "#/oneOf/1/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
    const _errs5 = errors;
    if (!validate36(data, { instancePath, parentData, parentDataProperty, rootData })) {
      vErrors = vErrors === null ? validate36.errors : vErrors.concat(validate36.errors);
      errors = vErrors.length;
    }
    var _valid0 = _errs5 === errors;
    if (_valid0 && valid0) {
      valid0 = false;
      passing0 = [passing0, 2];
    } else {
      if (_valid0) {
        valid0 = true;
        passing0 = 2;
      }
    }
  }
  if (!valid0) {
    const err2 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  } else {
    errors = _errs0;
    if (vErrors !== null) {
      if (_errs0) {
        vErrors.length = _errs0;
      } else {
        vErrors = null;
      }
    }
  }
  validate37.errors = vErrors;
  return errors === 0;
}
exports.isParameterRecommendationValue = validate39;
function validate39(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  const _errs0 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs1 = errors;
  if (!validate32(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate32.errors : vErrors.concat(validate32.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs1 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs2 = errors;
  if (!validate33(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate33.errors : vErrors.concat(validate33.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
  }
  if (!valid0) {
    const err0 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  } else {
    errors = _errs0;
    if (vErrors !== null) {
      if (_errs0) {
        vErrors.length = _errs0;
      } else {
        vErrors = null;
      }
    }
  }
  validate39.errors = vErrors;
  return errors === 0;
}
exports.isParameter = validate42;
function validate42(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.parameterName === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "parameterName" }, message: "must have required property 'parameterName'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.parameterType === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "parameterType" }, message: "must have required property 'parameterType'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.parameterValues === void 0) {
      const err2 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "parameterValues" }, message: "must have required property 'parameterValues'" };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.parameterName !== void 0) {
      if (typeof data.parameterName !== "string") {
        const err3 = { instancePath: instancePath + "/parameterName", schemaPath: "#/properties/parameterName/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.parameterType !== void 0) {
      if (!validate30(data.parameterType, { instancePath: instancePath + "/parameterType", parentData: data, parentDataProperty: "parameterType", rootData })) {
        vErrors = vErrors === null ? validate30.errors : vErrors.concat(validate30.errors);
        errors = vErrors.length;
      }
    }
    if (data.parameterValues !== void 0) {
      let data2 = data.parameterValues;
      if (Array.isArray(data2)) {
        const len0 = data2.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate37(data2[i0], { instancePath: instancePath + "/parameterValues/" + i0, parentData: data2, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate37.errors : vErrors.concat(validate37.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err4 = { instancePath: instancePath + "/parameterValues", schemaPath: "#/properties/parameterValues/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
    if (data.activeValues !== void 0) {
      let data4 = data.activeValues;
      if (Array.isArray(data4)) {
        const len1 = data4.length;
        for (let i1 = 0; i1 < len1; i1++) {
          if (!validate37(data4[i1], { instancePath: instancePath + "/activeValues/" + i1, parentData: data4, parentDataProperty: i1, rootData })) {
            vErrors = vErrors === null ? validate37.errors : vErrors.concat(validate37.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err5 = { instancePath: instancePath + "/activeValues", schemaPath: "#/properties/activeValues/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err5];
        } else {
          vErrors.push(err5);
        }
        errors++;
      }
    }
  } else {
    const err6 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err6];
    } else {
      vErrors.push(err6);
    }
    errors++;
  }
  validate42.errors = vErrors;
  return errors === 0;
}
exports.isParameterRecommendation = validate46;
function validate46(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      if (!validate39(data[key0], { instancePath: instancePath + "/" + key0.replace(/~/g, "~0").replace(/\//g, "~1"), parentData: data, parentDataProperty: key0, rootData })) {
        vErrors = vErrors === null ? validate39.errors : vErrors.concat(validate39.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate46.errors = vErrors;
  return errors === 0;
}
exports.isAcquisitionFunctionType = validate48;
var schema24 = { "type": "string", "default": "EI", "enum": ["EI", "TS", "PI", "UCB", "MeanMax", "VarMax"] };
function validate48(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "EI" || data === "TS" || data === "PI" || data === "UCB" || data === "MeanMax" || data === "VarMax")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema24.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate48.errors = vErrors;
  return errors === 0;
}
exports.isInitialGuessMethodType = validate49;
var schema25 = { "type": "string", "default": "rand", "enum": ["rand", "pam", "kmeans"] };
function validate49(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "rand" || data === "pam" || data === "kmeans")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema25.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate49.errors = vErrors;
  return errors === 0;
}
exports.isSurrogateModelType = validate50;
var schema26 = { "type": "string", "default": "GP", "enum": ["GP", "RF", "Linear"] };
function validate50(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "GP" || data === "RF" || data === "Linear")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema26.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate50.errors = vErrors;
  return errors === 0;
}
exports.isExpertOptions = validate51;
function validate51(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.acquisitionFunction !== void 0) {
      if (!validate48(data.acquisitionFunction, { instancePath: instancePath + "/acquisitionFunction", parentData: data, parentDataProperty: "acquisitionFunction", rootData })) {
        vErrors = vErrors === null ? validate48.errors : vErrors.concat(validate48.errors);
        errors = vErrors.length;
      }
    }
    if (data.initialGuessMethod !== void 0) {
      if (!validate49(data.initialGuessMethod, { instancePath: instancePath + "/initialGuessMethod", parentData: data, parentDataProperty: "initialGuessMethod", rootData })) {
        vErrors = vErrors === null ? validate49.errors : vErrors.concat(validate49.errors);
        errors = vErrors.length;
      }
    }
    if (data.surrogateModel !== void 0) {
      if (!validate50(data.surrogateModel, { instancePath: instancePath + "/surrogateModel", parentData: data, parentDataProperty: "surrogateModel", rootData })) {
        vErrors = vErrors === null ? validate50.errors : vErrors.concat(validate50.errors);
        errors = vErrors.length;
      }
    }
  }
  validate51.errors = vErrors;
  return errors === 0;
}
exports.isTargetVariable = validate55;
var schema28 = { "type": "object", "properties": { "targetName": { "type": "string", "example": "yield" }, "targetWeight": { "type": "number", "example": 1 }, "targetObjective": { "type": "string", "enum": ["Max", "Min", "Match"], "default": "Max" }, "lowerBound": { "type": "number", "example": 45 }, "upperBound": { "type": "number", "example": 100 } }, "required": ["targetName"] };
function validate55(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.targetName === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "targetName" }, message: "must have required property 'targetName'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.targetName !== void 0) {
      if (typeof data.targetName !== "string") {
        const err1 = { instancePath: instancePath + "/targetName", schemaPath: "#/properties/targetName/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
    if (data.targetWeight !== void 0) {
      let data1 = data.targetWeight;
      if (!(typeof data1 == "number" && isFinite(data1))) {
        const err2 = { instancePath: instancePath + "/targetWeight", schemaPath: "#/properties/targetWeight/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.targetObjective !== void 0) {
      let data2 = data.targetObjective;
      if (typeof data2 !== "string") {
        const err3 = { instancePath: instancePath + "/targetObjective", schemaPath: "#/properties/targetObjective/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
      if (!(data2 === "Max" || data2 === "Min" || data2 === "Match")) {
        const err4 = { instancePath: instancePath + "/targetObjective", schemaPath: "#/properties/targetObjective/enum", keyword: "enum", params: { allowedValues: schema28.properties.targetObjective.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
    if (data.lowerBound !== void 0) {
      let data3 = data.lowerBound;
      if (!(typeof data3 == "number" && isFinite(data3))) {
        const err5 = { instancePath: instancePath + "/lowerBound", schemaPath: "#/properties/lowerBound/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err5];
        } else {
          vErrors.push(err5);
        }
        errors++;
      }
    }
    if (data.upperBound !== void 0) {
      let data4 = data.upperBound;
      if (!(typeof data4 == "number" && isFinite(data4))) {
        const err6 = { instancePath: instancePath + "/upperBound", schemaPath: "#/properties/upperBound/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err6];
        } else {
          vErrors.push(err6);
        }
        errors++;
      }
    }
  } else {
    const err7 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err7];
    } else {
      vErrors.push(err7);
    }
    errors++;
  }
  validate55.errors = vErrors;
  return errors === 0;
}
exports.isProjectRole = validate56;
var schema29 = { "type": "string", "default": "Owner", "enum": ["Owner", "Editor", "Viewer"] };
function validate56(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (typeof data !== "string") {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "string" }, message: "must be string" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (!(data === "Owner" || data === "Editor" || data === "Viewer")) {
    const err1 = { instancePath, schemaPath: "#/enum", keyword: "enum", params: { allowedValues: schema29.enum }, message: "must be equal to one of the allowed values" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate56.errors = vErrors;
  return errors === 0;
}
exports.isProjectInvite = validate57;
var schema30 = { "type": "object", "required": ["projectId", "inviteId", "projectName", "role", "email", "status", "sender", "createdAt", "expiresAt"], "properties": { "projectId": { "type": "string", "example": "00016244156fdcec3f9adb08746e" }, "inviteId": { "type": "string", "example": "00016244156fdcec3f9adb08746e" }, "userId": { "type": "string", "example": "qmTI1YzkBFK5-prw3AuYNw" }, "projectName": { "type": "string", "example": "Test Project" }, "role": { "$ref": "#/components/schemas/ProjectRole" }, "email": { "type": "string" }, "invitee": { "$ref": "#/components/schemas/User" }, "sender": { "$ref": "#/components/schemas/User" }, "createdAt": { "type": "string" }, "expiresAt": { "type": "string" }, "status": { "type": "string", "enum": ["pending", "accepted", "rejected", "expired"] } } };
function validate57(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.inviteId === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "inviteId" }, message: "must have required property 'inviteId'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.projectName === void 0) {
      const err2 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectName" }, message: "must have required property 'projectName'" };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.role === void 0) {
      const err3 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "role" }, message: "must have required property 'role'" };
      if (vErrors === null) {
        vErrors = [err3];
      } else {
        vErrors.push(err3);
      }
      errors++;
    }
    if (data.email === void 0) {
      const err4 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "email" }, message: "must have required property 'email'" };
      if (vErrors === null) {
        vErrors = [err4];
      } else {
        vErrors.push(err4);
      }
      errors++;
    }
    if (data.status === void 0) {
      const err5 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "status" }, message: "must have required property 'status'" };
      if (vErrors === null) {
        vErrors = [err5];
      } else {
        vErrors.push(err5);
      }
      errors++;
    }
    if (data.sender === void 0) {
      const err6 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "sender" }, message: "must have required property 'sender'" };
      if (vErrors === null) {
        vErrors = [err6];
      } else {
        vErrors.push(err6);
      }
      errors++;
    }
    if (data.createdAt === void 0) {
      const err7 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "createdAt" }, message: "must have required property 'createdAt'" };
      if (vErrors === null) {
        vErrors = [err7];
      } else {
        vErrors.push(err7);
      }
      errors++;
    }
    if (data.expiresAt === void 0) {
      const err8 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "expiresAt" }, message: "must have required property 'expiresAt'" };
      if (vErrors === null) {
        vErrors = [err8];
      } else {
        vErrors.push(err8);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err9 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err9];
        } else {
          vErrors.push(err9);
        }
        errors++;
      }
    }
    if (data.inviteId !== void 0) {
      if (typeof data.inviteId !== "string") {
        const err10 = { instancePath: instancePath + "/inviteId", schemaPath: "#/properties/inviteId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err10];
        } else {
          vErrors.push(err10);
        }
        errors++;
      }
    }
    if (data.userId !== void 0) {
      if (typeof data.userId !== "string") {
        const err11 = { instancePath: instancePath + "/userId", schemaPath: "#/properties/userId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err11];
        } else {
          vErrors.push(err11);
        }
        errors++;
      }
    }
    if (data.projectName !== void 0) {
      if (typeof data.projectName !== "string") {
        const err12 = { instancePath: instancePath + "/projectName", schemaPath: "#/properties/projectName/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err12];
        } else {
          vErrors.push(err12);
        }
        errors++;
      }
    }
    if (data.role !== void 0) {
      if (!validate56(data.role, { instancePath: instancePath + "/role", parentData: data, parentDataProperty: "role", rootData })) {
        vErrors = vErrors === null ? validate56.errors : vErrors.concat(validate56.errors);
        errors = vErrors.length;
      }
    }
    if (data.email !== void 0) {
      if (typeof data.email !== "string") {
        const err13 = { instancePath: instancePath + "/email", schemaPath: "#/properties/email/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err13];
        } else {
          vErrors.push(err13);
        }
        errors++;
      }
    }
    if (data.invitee !== void 0) {
      if (!validate21(data.invitee, { instancePath: instancePath + "/invitee", parentData: data, parentDataProperty: "invitee", rootData })) {
        vErrors = vErrors === null ? validate21.errors : vErrors.concat(validate21.errors);
        errors = vErrors.length;
      }
    }
    if (data.sender !== void 0) {
      if (!validate21(data.sender, { instancePath: instancePath + "/sender", parentData: data, parentDataProperty: "sender", rootData })) {
        vErrors = vErrors === null ? validate21.errors : vErrors.concat(validate21.errors);
        errors = vErrors.length;
      }
    }
    if (data.createdAt !== void 0) {
      if (typeof data.createdAt !== "string") {
        const err14 = { instancePath: instancePath + "/createdAt", schemaPath: "#/properties/createdAt/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err14];
        } else {
          vErrors.push(err14);
        }
        errors++;
      }
    }
    if (data.expiresAt !== void 0) {
      if (typeof data.expiresAt !== "string") {
        const err15 = { instancePath: instancePath + "/expiresAt", schemaPath: "#/properties/expiresAt/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err15];
        } else {
          vErrors.push(err15);
        }
        errors++;
      }
    }
    if (data.status !== void 0) {
      let data10 = data.status;
      if (typeof data10 !== "string") {
        const err16 = { instancePath: instancePath + "/status", schemaPath: "#/properties/status/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err16];
        } else {
          vErrors.push(err16);
        }
        errors++;
      }
      if (!(data10 === "pending" || data10 === "accepted" || data10 === "rejected" || data10 === "expired")) {
        const err17 = { instancePath: instancePath + "/status", schemaPath: "#/properties/status/enum", keyword: "enum", params: { allowedValues: schema30.properties.status.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err17];
        } else {
          vErrors.push(err17);
        }
        errors++;
      }
    }
  } else {
    const err18 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err18];
    } else {
      vErrors.push(err18);
    }
    errors++;
  }
  validate57.errors = vErrors;
  return errors === 0;
}
exports.isProject = validate61;
function validate61(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.userId === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "userId" }, message: "must have required property 'userId'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.projectOwner === void 0) {
      const err2 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectOwner" }, message: "must have required property 'projectOwner'" };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.name === void 0) {
      const err3 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "name" }, message: "must have required property 'name'" };
      if (vErrors === null) {
        vErrors = [err3];
      } else {
        vErrors.push(err3);
      }
      errors++;
    }
    if (data.batchSize === void 0) {
      const err4 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "batchSize" }, message: "must have required property 'batchSize'" };
      if (vErrors === null) {
        vErrors = [err4];
      } else {
        vErrors.push(err4);
      }
      errors++;
    }
    if (data.targets === void 0) {
      const err5 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "targets" }, message: "must have required property 'targets'" };
      if (vErrors === null) {
        vErrors = [err5];
      } else {
        vErrors.push(err5);
      }
      errors++;
    }
    if (data.currentBatch === void 0) {
      const err6 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "currentBatch" }, message: "must have required property 'currentBatch'" };
      if (vErrors === null) {
        vErrors = [err6];
      } else {
        vErrors.push(err6);
      }
      errors++;
    }
    if (data.parameterSpace === void 0) {
      const err7 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "parameterSpace" }, message: "must have required property 'parameterSpace'" };
      if (vErrors === null) {
        vErrors = [err7];
      } else {
        vErrors.push(err7);
      }
      errors++;
    }
    if (data.createdAt === void 0) {
      const err8 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "createdAt" }, message: "must have required property 'createdAt'" };
      if (vErrors === null) {
        vErrors = [err8];
      } else {
        vErrors.push(err8);
      }
      errors++;
    }
    if (data.changedAt === void 0) {
      const err9 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "changedAt" }, message: "must have required property 'changedAt'" };
      if (vErrors === null) {
        vErrors = [err9];
      } else {
        vErrors.push(err9);
      }
      errors++;
    }
    if (data.role === void 0) {
      const err10 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "role" }, message: "must have required property 'role'" };
      if (vErrors === null) {
        vErrors = [err10];
      } else {
        vErrors.push(err10);
      }
      errors++;
    }
    if (data.status === void 0) {
      const err11 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "status" }, message: "must have required property 'status'" };
      if (vErrors === null) {
        vErrors = [err11];
      } else {
        vErrors.push(err11);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err12 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err12];
        } else {
          vErrors.push(err12);
        }
        errors++;
      }
    }
    if (data.userId !== void 0) {
      if (typeof data.userId !== "string") {
        const err13 = { instancePath: instancePath + "/userId", schemaPath: "#/properties/userId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err13];
        } else {
          vErrors.push(err13);
        }
        errors++;
      }
    }
    if (data.projectOwner !== void 0) {
      if (typeof data.projectOwner !== "string") {
        const err14 = { instancePath: instancePath + "/projectOwner", schemaPath: "#/properties/projectOwner/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err14];
        } else {
          vErrors.push(err14);
        }
        errors++;
      }
    }
    if (data.role !== void 0) {
      if (!validate56(data.role, { instancePath: instancePath + "/role", parentData: data, parentDataProperty: "role", rootData })) {
        vErrors = vErrors === null ? validate56.errors : vErrors.concat(validate56.errors);
        errors = vErrors.length;
      }
    }
    if (data.name !== void 0) {
      if (typeof data.name !== "string") {
        const err15 = { instancePath: instancePath + "/name", schemaPath: "#/properties/name/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err15];
        } else {
          vErrors.push(err15);
        }
        errors++;
      }
    }
    if (data.batchSize !== void 0) {
      let data5 = data.batchSize;
      if (!(typeof data5 == "number" && isFinite(data5))) {
        const err16 = { instancePath: instancePath + "/batchSize", schemaPath: "#/properties/batchSize/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err16];
        } else {
          vErrors.push(err16);
        }
        errors++;
      }
    }
    if (data.targets !== void 0) {
      let data6 = data.targets;
      if (Array.isArray(data6)) {
        const len0 = data6.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate55(data6[i0], { instancePath: instancePath + "/targets/" + i0, parentData: data6, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate55.errors : vErrors.concat(validate55.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err17 = { instancePath: instancePath + "/targets", schemaPath: "#/properties/targets/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err17];
        } else {
          vErrors.push(err17);
        }
        errors++;
      }
    }
    if (data.currentBatch !== void 0) {
      let data8 = data.currentBatch;
      if (!(typeof data8 == "number" && isFinite(data8))) {
        const err18 = { instancePath: instancePath + "/currentBatch", schemaPath: "#/properties/currentBatch/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err18];
        } else {
          vErrors.push(err18);
        }
        errors++;
      }
    }
    if (data.parameterSpace !== void 0) {
      let data9 = data.parameterSpace;
      if (Array.isArray(data9)) {
        const len1 = data9.length;
        for (let i1 = 0; i1 < len1; i1++) {
          if (!validate42(data9[i1], { instancePath: instancePath + "/parameterSpace/" + i1, parentData: data9, parentDataProperty: i1, rootData })) {
            vErrors = vErrors === null ? validate42.errors : vErrors.concat(validate42.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err19 = { instancePath: instancePath + "/parameterSpace", schemaPath: "#/properties/parameterSpace/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err19];
        } else {
          vErrors.push(err19);
        }
        errors++;
      }
    }
    if (data.expertOptions !== void 0) {
      if (!validate51(data.expertOptions, { instancePath: instancePath + "/expertOptions", parentData: data, parentDataProperty: "expertOptions", rootData })) {
        vErrors = vErrors === null ? validate51.errors : vErrors.concat(validate51.errors);
        errors = vErrors.length;
      }
    }
    if (data.createdAt !== void 0) {
      if (typeof data.createdAt !== "string") {
        const err20 = { instancePath: instancePath + "/createdAt", schemaPath: "#/properties/createdAt/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err20];
        } else {
          vErrors.push(err20);
        }
        errors++;
      }
    }
    if (data.changedAt !== void 0) {
      if (typeof data.changedAt !== "string") {
        const err21 = { instancePath: instancePath + "/changedAt", schemaPath: "#/properties/changedAt/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err21];
        } else {
          vErrors.push(err21);
        }
        errors++;
      }
    }
    if (data.changedBy !== void 0) {
      if (!validate21(data.changedBy, { instancePath: instancePath + "/changedBy", parentData: data, parentDataProperty: "changedBy", rootData })) {
        vErrors = vErrors === null ? validate21.errors : vErrors.concat(validate21.errors);
        errors = vErrors.length;
      }
    }
    if (data.status !== void 0) {
      if (!validate27(data.status, { instancePath: instancePath + "/status", parentData: data, parentDataProperty: "status", rootData })) {
        vErrors = vErrors === null ? validate27.errors : vErrors.concat(validate27.errors);
        errors = vErrors.length;
      }
    }
    if (data.error !== void 0) {
      let data16 = data.error;
      if (typeof data16 !== "string" && data16 !== null) {
        const err22 = { instancePath: instancePath + "/error", schemaPath: "#/properties/error/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err22];
        } else {
          vErrors.push(err22);
        }
        errors++;
      }
    }
  } else {
    const err23 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err23];
    } else {
      vErrors.push(err23);
    }
    errors++;
  }
  validate61.errors = vErrors;
  return errors === 0;
}
exports.isExperimentResults = validate68;
function validate68(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      let data0 = data[key0];
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err0 = { instancePath: instancePath + "/" + key0.replace(/~/g, "~0").replace(/\//g, "~1"), schemaPath: "#/additionalProperties/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err0];
        } else {
          vErrors.push(err0);
        }
        errors++;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate68.errors = vErrors;
  return errors === 0;
}
exports.isExperiment = validate69;
function validate69(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.experimentId === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "experimentId" }, message: "must have required property 'experimentId'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.batch === void 0) {
      const err2 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "batch" }, message: "must have required property 'batch'" };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.parameters === void 0) {
      const err3 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "parameters" }, message: "must have required property 'parameters'" };
      if (vErrors === null) {
        vErrors = [err3];
      } else {
        vErrors.push(err3);
      }
      errors++;
    }
    if (data.results === void 0) {
      const err4 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "results" }, message: "must have required property 'results'" };
      if (vErrors === null) {
        vErrors = [err4];
      } else {
        vErrors.push(err4);
      }
      errors++;
    }
    if (data.createdAt === void 0) {
      const err5 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "createdAt" }, message: "must have required property 'createdAt'" };
      if (vErrors === null) {
        vErrors = [err5];
      } else {
        vErrors.push(err5);
      }
      errors++;
    }
    if (data.changedAt === void 0) {
      const err6 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "changedAt" }, message: "must have required property 'changedAt'" };
      if (vErrors === null) {
        vErrors = [err6];
      } else {
        vErrors.push(err6);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err7 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err7];
        } else {
          vErrors.push(err7);
        }
        errors++;
      }
    }
    if (data.experimentId !== void 0) {
      if (typeof data.experimentId !== "string") {
        const err8 = { instancePath: instancePath + "/experimentId", schemaPath: "#/properties/experimentId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err8];
        } else {
          vErrors.push(err8);
        }
        errors++;
      }
    }
    if (data.recommendationId !== void 0) {
      if (typeof data.recommendationId !== "string") {
        const err9 = { instancePath: instancePath + "/recommendationId", schemaPath: "#/properties/recommendationId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err9];
        } else {
          vErrors.push(err9);
        }
        errors++;
      }
    }
    if (data.batch !== void 0) {
      let data3 = data.batch;
      if (!(typeof data3 == "number" && isFinite(data3))) {
        const err10 = { instancePath: instancePath + "/batch", schemaPath: "#/properties/batch/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err10];
        } else {
          vErrors.push(err10);
        }
        errors++;
      }
    }
    if (data.parameters !== void 0) {
      if (!validate46(data.parameters, { instancePath: instancePath + "/parameters", parentData: data, parentDataProperty: "parameters", rootData })) {
        vErrors = vErrors === null ? validate46.errors : vErrors.concat(validate46.errors);
        errors = vErrors.length;
      }
    }
    if (data.results !== void 0) {
      if (!validate68(data.results, { instancePath: instancePath + "/results", parentData: data, parentDataProperty: "results", rootData })) {
        vErrors = vErrors === null ? validate68.errors : vErrors.concat(validate68.errors);
        errors = vErrors.length;
      }
    }
    if (data.createdAt !== void 0) {
      if (typeof data.createdAt !== "string") {
        const err11 = { instancePath: instancePath + "/createdAt", schemaPath: "#/properties/createdAt/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err11];
        } else {
          vErrors.push(err11);
        }
        errors++;
      }
    }
    if (data.changedAt !== void 0) {
      if (typeof data.changedAt !== "string") {
        const err12 = { instancePath: instancePath + "/changedAt", schemaPath: "#/properties/changedAt/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err12];
        } else {
          vErrors.push(err12);
        }
        errors++;
      }
    }
    if (data.changedBy !== void 0) {
      if (!validate21(data.changedBy, { instancePath: instancePath + "/changedBy", parentData: data, parentDataProperty: "changedBy", rootData })) {
        vErrors = vErrors === null ? validate21.errors : vErrors.concat(validate21.errors);
        errors = vErrors.length;
      }
    }
    if (data.status !== void 0) {
      if (!validate28(data.status, { instancePath: instancePath + "/status", parentData: data, parentDataProperty: "status", rootData })) {
        vErrors = vErrors === null ? validate28.errors : vErrors.concat(validate28.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err13 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err13];
    } else {
      vErrors.push(err13);
    }
    errors++;
  }
  validate69.errors = vErrors;
  return errors === 0;
}
exports.isRecommendation = validate74;
function validate74(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.recommendationId === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "recommendationId" }, message: "must have required property 'recommendationId'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.parameters === void 0) {
      const err2 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "parameters" }, message: "must have required property 'parameters'" };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.createdAt === void 0) {
      const err3 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "createdAt" }, message: "must have required property 'createdAt'" };
      if (vErrors === null) {
        vErrors = [err3];
      } else {
        vErrors.push(err3);
      }
      errors++;
    }
    if (data.changedAt === void 0) {
      const err4 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "changedAt" }, message: "must have required property 'changedAt'" };
      if (vErrors === null) {
        vErrors = [err4];
      } else {
        vErrors.push(err4);
      }
      errors++;
    }
    if (data.status === void 0) {
      const err5 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "status" }, message: "must have required property 'status'" };
      if (vErrors === null) {
        vErrors = [err5];
      } else {
        vErrors.push(err5);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err6 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err6];
        } else {
          vErrors.push(err6);
        }
        errors++;
      }
    }
    if (data.recommendationId !== void 0) {
      if (typeof data.recommendationId !== "string") {
        const err7 = { instancePath: instancePath + "/recommendationId", schemaPath: "#/properties/recommendationId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err7];
        } else {
          vErrors.push(err7);
        }
        errors++;
      }
    }
    if (data.parameters !== void 0) {
      if (!validate46(data.parameters, { instancePath: instancePath + "/parameters", parentData: data, parentDataProperty: "parameters", rootData })) {
        vErrors = vErrors === null ? validate46.errors : vErrors.concat(validate46.errors);
        errors = vErrors.length;
      }
    }
    if (data.createdAt !== void 0) {
      if (typeof data.createdAt !== "string") {
        const err8 = { instancePath: instancePath + "/createdAt", schemaPath: "#/properties/createdAt/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err8];
        } else {
          vErrors.push(err8);
        }
        errors++;
      }
    }
    if (data.changedAt !== void 0) {
      if (typeof data.changedAt !== "string") {
        const err9 = { instancePath: instancePath + "/changedAt", schemaPath: "#/properties/changedAt/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err9];
        } else {
          vErrors.push(err9);
        }
        errors++;
      }
    }
    if (data.status !== void 0) {
      if (!validate29(data.status, { instancePath: instancePath + "/status", parentData: data, parentDataProperty: "status", rootData })) {
        vErrors = vErrors === null ? validate29.errors : vErrors.concat(validate29.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err10 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err10];
    } else {
      vErrors.push(err10);
    }
    errors++;
  }
  validate74.errors = vErrors;
  return errors === 0;
}
exports.isGetUserResponse = validate77;
var schema35 = { "type": "object", "description": "Success", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "$ref": "#/components/schemas/User" } } };
function validate77(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema35.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate21(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate21.errors : vErrors.concat(validate21.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate77.errors = vErrors;
  return errors === 0;
}
exports.isGetUserRequest = validate79;
function validate79(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate79.errors = vErrors;
  return errors === 0;
}
exports.isListUserInvitesResponse = validate80;
var schema37 = { "type": "object", "description": "ok", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "type": "array", "items": { "$ref": "#/components/schemas/ProjectInvite" } } } };
function validate80(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema37.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      let data1 = data.json;
      if (Array.isArray(data1)) {
        const len0 = data1.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate57(data1[i0], { instancePath: instancePath + "/json/" + i0, parentData: data1, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate57.errors : vErrors.concat(validate57.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err4 = { instancePath: instancePath + "/json", schemaPath: "#/properties/json/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
  } else {
    const err5 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err5];
    } else {
      vErrors.push(err5);
    }
    errors++;
  }
  validate80.errors = vErrors;
  return errors === 0;
}
exports.isListUserInvitesRequestQueryStringParameters = validate82;
var schema38 = { "type": "object", "required": [], "properties": { "sortBy": { "type": "string", "default": "createdAt", "enum": ["createdAt", "expiresAt", "role", "projectId"] }, "order": { "type": "string", "default": "desc", "enum": ["asc", "desc"] } }, "nullable": true };
function validate82(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data)) && data !== null) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.sortBy !== void 0) {
      let data0 = data.sortBy;
      if (typeof data0 !== "string") {
        const err1 = { instancePath: instancePath + "/sortBy", schemaPath: "#/properties/sortBy/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === "createdAt" || data0 === "expiresAt" || data0 === "role" || data0 === "projectId")) {
        const err2 = { instancePath: instancePath + "/sortBy", schemaPath: "#/properties/sortBy/enum", keyword: "enum", params: { allowedValues: schema38.properties.sortBy.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.order !== void 0) {
      let data1 = data.order;
      if (typeof data1 !== "string") {
        const err3 = { instancePath: instancePath + "/order", schemaPath: "#/properties/order/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
      if (!(data1 === "asc" || data1 === "desc")) {
        const err4 = { instancePath: instancePath + "/order", schemaPath: "#/properties/order/enum", keyword: "enum", params: { allowedValues: schema38.properties.order.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
  }
  validate82.errors = vErrors;
  return errors === 0;
}
exports.isListUserInvitesRequest = validate83;
function validate83(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.queryStringParameters !== void 0) {
      if (!validate82(data.queryStringParameters, { instancePath: instancePath + "/queryStringParameters", parentData: data, parentDataProperty: "queryStringParameters", rootData })) {
        vErrors = vErrors === null ? validate82.errors : vErrors.concat(validate82.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate83.errors = vErrors;
  return errors === 0;
}
exports.isListProjectsResponse = validate85;
var schema40 = { "type": "object", "description": "Success", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "type": "array", "items": { "$ref": "#/components/schemas/Project" } } } };
function validate85(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema40.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      let data1 = data.json;
      if (Array.isArray(data1)) {
        const len0 = data1.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate61(data1[i0], { instancePath: instancePath + "/json/" + i0, parentData: data1, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate61.errors : vErrors.concat(validate61.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err4 = { instancePath: instancePath + "/json", schemaPath: "#/properties/json/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
  } else {
    const err5 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err5];
    } else {
      vErrors.push(err5);
    }
    errors++;
  }
  validate85.errors = vErrors;
  return errors === 0;
}
exports.isListProjectsRequest = validate87;
function validate87(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate87.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous6 = validate88;
var schema42 = { "type": "object", "description": "item created", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [201] }, "json": { "$ref": "#/components/schemas/Project" } } };
function validate88(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 201)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema42.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate61(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate61.errors : vErrors.concat(validate61.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate88.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous9Json = validate90;
function validate90(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate90.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous9 = validate91;
var schema44 = { "type": "object", "description": "invalid input, object invalid", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [400] }, "json": { "$ref": "#/components/schemas/Anonymous9Json" } } };
function validate91(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 400)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema44.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate90(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate90.errors : vErrors.concat(validate90.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate91.errors = vErrors;
  return errors === 0;
}
exports.isCreateProjectResponse = validate93;
function validate93(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate88(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate88.errors : vErrors.concat(validate88.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate91(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate91.errors : vErrors.concat(validate91.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate93.errors = vErrors;
  return errors === 0;
}
exports.isCreateProjectRequestJson = validate96;
function validate96(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.name === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "name" }, message: "must have required property 'name'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.batchSize === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "batchSize" }, message: "must have required property 'batchSize'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.parameterSpace === void 0) {
      const err2 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "parameterSpace" }, message: "must have required property 'parameterSpace'" };
      if (vErrors === null) {
        vErrors = [err2];
      } else {
        vErrors.push(err2);
      }
      errors++;
    }
    if (data.name !== void 0) {
      if (typeof data.name !== "string") {
        const err3 = { instancePath: instancePath + "/name", schemaPath: "#/properties/name/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.batchSize !== void 0) {
      let data1 = data.batchSize;
      if (!(typeof data1 == "number" && (!(data1 % 1) && !isNaN(data1)) && isFinite(data1))) {
        const err4 = { instancePath: instancePath + "/batchSize", schemaPath: "#/properties/batchSize/type", keyword: "type", params: { type: "integer" }, message: "must be integer" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
    if (data.targets !== void 0) {
      let data2 = data.targets;
      if (Array.isArray(data2)) {
        const len0 = data2.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate55(data2[i0], { instancePath: instancePath + "/targets/" + i0, parentData: data2, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate55.errors : vErrors.concat(validate55.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err5 = { instancePath: instancePath + "/targets", schemaPath: "#/properties/targets/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err5];
        } else {
          vErrors.push(err5);
        }
        errors++;
      }
    }
    if (data.expertOptions !== void 0) {
      if (!validate51(data.expertOptions, { instancePath: instancePath + "/expertOptions", parentData: data, parentDataProperty: "expertOptions", rootData })) {
        vErrors = vErrors === null ? validate51.errors : vErrors.concat(validate51.errors);
        errors = vErrors.length;
      }
    }
    if (data.parameterSpace !== void 0) {
      let data5 = data.parameterSpace;
      if (Array.isArray(data5)) {
        const len1 = data5.length;
        for (let i1 = 0; i1 < len1; i1++) {
          if (!validate42(data5[i1], { instancePath: instancePath + "/parameterSpace/" + i1, parentData: data5, parentDataProperty: i1, rootData })) {
            vErrors = vErrors === null ? validate42.errors : vErrors.concat(validate42.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err6 = { instancePath: instancePath + "/parameterSpace", schemaPath: "#/properties/parameterSpace/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err6];
        } else {
          vErrors.push(err6);
        }
        errors++;
      }
    }
  } else {
    const err7 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err7];
    } else {
      vErrors.push(err7);
    }
    errors++;
  }
  validate96.errors = vErrors;
  return errors === 0;
}
exports.isCreateProjectRequest = validate100;
function validate100(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.json === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json !== void 0) {
      if (!validate96(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate96.errors : vErrors.concat(validate96.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate100.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous10Json = validate102;
function validate102(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate102.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous10 = validate103;
var schema49 = { "type": "object", "description": "item deleted", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "$ref": "#/components/schemas/Anonymous10Json" } } };
function validate103(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema49.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate102(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate102.errors : vErrors.concat(validate102.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate103.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous11Json = validate105;
function validate105(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate105.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous11 = validate106;
var schema51 = { "type": "object", "description": "invalid input, object invalid", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [400] }, "json": { "$ref": "#/components/schemas/Anonymous11Json" } } };
function validate106(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 400)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema51.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate105(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate105.errors : vErrors.concat(validate105.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate106.errors = vErrors;
  return errors === 0;
}
exports.isDeleteProjectResponse = validate108;
function validate108(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate103(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate103.errors : vErrors.concat(validate103.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate106(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate106.errors : vErrors.concat(validate106.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate108.errors = vErrors;
  return errors === 0;
}
exports.isDeleteProjectRequestJson = validate111;
function validate111(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate111.errors = vErrors;
  return errors === 0;
}
exports.isDeleteProjectRequest = validate112;
function validate112(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.json === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json !== void 0) {
      if (!validate111(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate111.errors : vErrors.concat(validate111.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate112.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous12 = validate114;
var schema55 = { "type": "object", "description": "Project for provided id", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "$ref": "#/components/schemas/Project" } } };
function validate114(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema55.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate61(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate61.errors : vErrors.concat(validate61.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate114.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous15Json = validate116;
function validate116(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate116.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous15 = validate117;
var schema57 = { "type": "object", "description": "User is not authorized to view project", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [403] }, "json": { "$ref": "#/components/schemas/Anonymous15Json" } } };
function validate117(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 403)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema57.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate116(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate116.errors : vErrors.concat(validate116.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate117.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous16Json = validate119;
function validate119(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate119.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous16 = validate120;
var schema59 = { "type": "object", "description": "no project for provided id", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [404] }, "json": { "$ref": "#/components/schemas/Anonymous16Json" } } };
function validate120(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 404)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema59.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate119(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate119.errors : vErrors.concat(validate119.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate120.errors = vErrors;
  return errors === 0;
}
exports.isGetProjectByIdResponse = validate122;
function validate122(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate114(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate114.errors : vErrors.concat(validate114.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate117(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate117.errors : vErrors.concat(validate117.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
    const _errs4 = errors;
    if (!validate120(data, { instancePath, parentData, parentDataProperty, rootData })) {
      vErrors = vErrors === null ? validate120.errors : vErrors.concat(validate120.errors);
      errors = vErrors.length;
    }
    var _valid0 = _errs4 === errors;
    if (_valid0 && valid0) {
      valid0 = false;
      passing0 = [passing0, 2];
    } else {
      if (_valid0) {
        valid0 = true;
        passing0 = 2;
      }
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate122.errors = vErrors;
  return errors === 0;
}
exports.isGetProjectByIdRequestPathParameters = validate126;
function validate126(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate126.errors = vErrors;
  return errors === 0;
}
exports.isGetProjectByIdRequest = validate127;
function validate127(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate126(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate126.errors : vErrors.concat(validate126.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate127.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous17 = validate129;
var schema63 = { "type": "object", "description": "Chemical Space was expanded successfully", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "$ref": "#/components/schemas/Project" } } };
function validate129(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema63.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate61(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate61.errors : vErrors.concat(validate61.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate129.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous20Json = validate131;
function validate131(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate131.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous20 = validate132;
var schema65 = { "type": "object", "description": "invalid input, object invalid", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [400] }, "json": { "$ref": "#/components/schemas/Anonymous20Json" } } };
function validate132(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 400)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema65.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate131(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate131.errors : vErrors.concat(validate131.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate132.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous21Json = validate134;
function validate134(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate134.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous21 = validate135;
var schema67 = { "type": "object", "description": "User is not authorized to change experiment", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [403] }, "json": { "$ref": "#/components/schemas/Anonymous21Json" } } };
function validate135(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 403)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema67.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate134(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate134.errors : vErrors.concat(validate134.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate135.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous22Json = validate137;
function validate137(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate137.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous22 = validate138;
var schema69 = { "type": "object", "description": "Project not found", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [404] }, "json": { "$ref": "#/components/schemas/Anonymous22Json" } } };
function validate138(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 404)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema69.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate137(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate137.errors : vErrors.concat(validate137.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate138.errors = vErrors;
  return errors === 0;
}
exports.isExpandChemicalSpaceResponse = validate140;
function validate140(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate129(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate129.errors : vErrors.concat(validate129.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate132(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate132.errors : vErrors.concat(validate132.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
    const _errs4 = errors;
    if (!validate135(data, { instancePath, parentData, parentDataProperty, rootData })) {
      vErrors = vErrors === null ? validate135.errors : vErrors.concat(validate135.errors);
      errors = vErrors.length;
    }
    var _valid0 = _errs4 === errors;
    if (_valid0 && valid0) {
      valid0 = false;
      passing0 = [passing0, 2];
    } else {
      if (_valid0) {
        valid0 = true;
        passing0 = 2;
      }
      const _errs5 = errors;
      if (!validate138(data, { instancePath, parentData, parentDataProperty, rootData })) {
        vErrors = vErrors === null ? validate138.errors : vErrors.concat(validate138.errors);
        errors = vErrors.length;
      }
      var _valid0 = _errs5 === errors;
      if (_valid0 && valid0) {
        valid0 = false;
        passing0 = [passing0, 3];
      } else {
        if (_valid0) {
          valid0 = true;
          passing0 = 3;
        }
      }
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate140.errors = vErrors;
  return errors === 0;
}
exports.isExpandChemicalSpaceRequestPathParameters = validate145;
function validate145(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate145.errors = vErrors;
  return errors === 0;
}
exports.isExpandChemicalSpaceRequestJsonExperiments = validate146;
function validate146(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      if (!validate46(data[key0], { instancePath: instancePath + "/" + key0.replace(/~/g, "~0").replace(/\//g, "~1"), parentData: data, parentDataProperty: key0, rootData })) {
        vErrors = vErrors === null ? validate46.errors : vErrors.concat(validate46.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate146.errors = vErrors;
  return errors === 0;
}
exports.isExpandChemicalSpaceRequestJson = validate148;
function validate148(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.parameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "parameters" }, message: "must have required property 'parameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.parameters !== void 0) {
      let data0 = data.parameters;
      if (Array.isArray(data0)) {
        const len0 = data0.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate42(data0[i0], { instancePath: instancePath + "/parameters/" + i0, parentData: data0, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate42.errors : vErrors.concat(validate42.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err1 = { instancePath: instancePath + "/parameters", schemaPath: "#/properties/parameters/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
    if (data.experiments !== void 0) {
      if (!validate146(data.experiments, { instancePath: instancePath + "/experiments", parentData: data, parentDataProperty: "experiments", rootData })) {
        vErrors = vErrors === null ? validate146.errors : vErrors.concat(validate146.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate148.errors = vErrors;
  return errors === 0;
}
exports.isExpandChemicalSpaceRequest = validate151;
function validate151(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate145(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate145.errors : vErrors.concat(validate145.errors);
        errors = vErrors.length;
      }
    }
    if (data.json !== void 0) {
      if (!validate148(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate148.errors : vErrors.concat(validate148.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate151.errors = vErrors;
  return errors === 0;
}
exports.isCloseProjectResponseJson = validate154;
function validate154(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate154.errors = vErrors;
  return errors === 0;
}
exports.isCloseProjectResponse = validate155;
var schema76 = { "type": "object", "description": "Successfully closed", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [204] }, "json": { "$ref": "#/components/schemas/CloseProjectResponseJson" } } };
function validate155(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 204)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema76.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate154(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate154.errors : vErrors.concat(validate154.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate155.errors = vErrors;
  return errors === 0;
}
exports.isCloseProjectRequestPathParameters = validate157;
function validate157(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate157.errors = vErrors;
  return errors === 0;
}
exports.isCloseProjectRequest = validate158;
function validate158(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate157(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate157.errors : vErrors.concat(validate157.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate158.errors = vErrors;
  return errors === 0;
}
exports.isReopenProjectResponseJson = validate160;
function validate160(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate160.errors = vErrors;
  return errors === 0;
}
exports.isReopenProjectResponse = validate161;
var schema80 = { "type": "object", "description": "Successfully opened", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "$ref": "#/components/schemas/ReopenProjectResponseJson" } } };
function validate161(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema80.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate160(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate160.errors : vErrors.concat(validate160.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate161.errors = vErrors;
  return errors === 0;
}
exports.isReopenProjectRequestPathParameters = validate163;
function validate163(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate163.errors = vErrors;
  return errors === 0;
}
exports.isReopenProjectRequest = validate164;
function validate164(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate163(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate163.errors : vErrors.concat(validate163.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate164.errors = vErrors;
  return errors === 0;
}
exports.isListInvitesResponse = validate166;
var schema83 = { "type": "object", "description": "ok", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "type": "array", "items": { "$ref": "#/components/schemas/ProjectInvite" } } } };
function validate166(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema83.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      let data1 = data.json;
      if (Array.isArray(data1)) {
        const len0 = data1.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate57(data1[i0], { instancePath: instancePath + "/json/" + i0, parentData: data1, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate57.errors : vErrors.concat(validate57.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err4 = { instancePath: instancePath + "/json", schemaPath: "#/properties/json/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
  } else {
    const err5 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err5];
    } else {
      vErrors.push(err5);
    }
    errors++;
  }
  validate166.errors = vErrors;
  return errors === 0;
}
exports.isListInvitesRequestPathParameters = validate168;
function validate168(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate168.errors = vErrors;
  return errors === 0;
}
exports.isListInvitesRequest = validate169;
function validate169(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate168(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate168.errors : vErrors.concat(validate168.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate169.errors = vErrors;
  return errors === 0;
}
exports.isSendInviteResponse = validate171;
var schema86 = { "type": "object", "description": "ok", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [201] }, "json": { "$ref": "#/components/schemas/ProjectInvite" } } };
function validate171(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 201)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema86.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate57(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate57.errors : vErrors.concat(validate57.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate171.errors = vErrors;
  return errors === 0;
}
exports.isSendInviteRequestPathParameters = validate173;
function validate173(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate173.errors = vErrors;
  return errors === 0;
}
exports.isSendInviteRequestJson = validate174;
function validate174(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.role === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "role" }, message: "must have required property 'role'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.email === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "email" }, message: "must have required property 'email'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.role !== void 0) {
      if (!validate56(data.role, { instancePath: instancePath + "/role", parentData: data, parentDataProperty: "role", rootData })) {
        vErrors = vErrors === null ? validate56.errors : vErrors.concat(validate56.errors);
        errors = vErrors.length;
      }
    }
    if (data.email !== void 0) {
      if (typeof data.email !== "string") {
        const err2 = { instancePath: instancePath + "/email", schemaPath: "#/properties/email/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate174.errors = vErrors;
  return errors === 0;
}
exports.isSendInviteRequest = validate176;
function validate176(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate173(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate173.errors : vErrors.concat(validate173.errors);
        errors = vErrors.length;
      }
    }
    if (data.json !== void 0) {
      if (!validate174(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate174.errors : vErrors.concat(validate174.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate176.errors = vErrors;
  return errors === 0;
}
exports.isGetInviteByIdResponse = validate179;
var schema90 = { "type": "object", "description": "ok", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "$ref": "#/components/schemas/ProjectInvite" } } };
function validate179(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema90.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate57(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate57.errors : vErrors.concat(validate57.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate179.errors = vErrors;
  return errors === 0;
}
exports.isGetInviteByIdRequestPathParameters = validate181;
function validate181(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.inviteId === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "inviteId" }, message: "must have required property 'inviteId'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err2 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.inviteId !== void 0) {
      if (typeof data.inviteId !== "string") {
        const err3 = { instancePath: instancePath + "/inviteId", schemaPath: "#/properties/inviteId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate181.errors = vErrors;
  return errors === 0;
}
exports.isGetInviteByIdRequest = validate182;
function validate182(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate181(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate181.errors : vErrors.concat(validate181.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate182.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous23 = validate184;
var schema93 = { "type": "object", "description": "ok", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "$ref": "#/components/schemas/ProjectInvite" } } };
function validate184(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema93.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate57(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate57.errors : vErrors.concat(validate57.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate184.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous24Json = validate186;
function validate186(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate186.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous24 = validate187;
var schema95 = { "type": "object", "description": "Validation Error", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [400] }, "json": { "$ref": "#/components/schemas/Anonymous24Json" } } };
function validate187(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 400)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema95.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate186(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate186.errors : vErrors.concat(validate186.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate187.errors = vErrors;
  return errors === 0;
}
exports.isChangeInviteStatusResponse = validate189;
function validate189(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate184(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate184.errors : vErrors.concat(validate184.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate187(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate187.errors : vErrors.concat(validate187.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate189.errors = vErrors;
  return errors === 0;
}
exports.isChangeInviteStatusRequestPathParameters = validate192;
function validate192(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.inviteId === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "inviteId" }, message: "must have required property 'inviteId'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err2 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.inviteId !== void 0) {
      if (typeof data.inviteId !== "string") {
        const err3 = { instancePath: instancePath + "/inviteId", schemaPath: "#/properties/inviteId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate192.errors = vErrors;
  return errors === 0;
}
exports.isChangeInviteStatusRequestJson = validate193;
var schema98 = { "type": "object", "properties": { "action": { "type": "string", "enum": ["accept", "reject"] } } };
function validate193(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.action !== void 0) {
      let data0 = data.action;
      if (typeof data0 !== "string") {
        const err0 = { instancePath: instancePath + "/action", schemaPath: "#/properties/action/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err0];
        } else {
          vErrors.push(err0);
        }
        errors++;
      }
      if (!(data0 === "accept" || data0 === "reject")) {
        const err1 = { instancePath: instancePath + "/action", schemaPath: "#/properties/action/enum", keyword: "enum", params: { allowedValues: schema98.properties.action.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate193.errors = vErrors;
  return errors === 0;
}
exports.isChangeInviteStatusRequest = validate194;
function validate194(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate192(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate192.errors : vErrors.concat(validate192.errors);
        errors = vErrors.length;
      }
    }
    if (data.json !== void 0) {
      if (!validate193(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate193.errors : vErrors.concat(validate193.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate194.errors = vErrors;
  return errors === 0;
}
exports.isDeleteInviteResponseJson = validate197;
function validate197(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate197.errors = vErrors;
  return errors === 0;
}
exports.isDeleteInviteResponse = validate198;
var schema101 = { "type": "object", "description": "ok", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [204] }, "json": { "$ref": "#/components/schemas/DeleteInviteResponseJson" } } };
function validate198(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 204)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema101.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate197(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate197.errors : vErrors.concat(validate197.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate198.errors = vErrors;
  return errors === 0;
}
exports.isDeleteInviteRequestPathParameters = validate200;
function validate200(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.inviteId === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "inviteId" }, message: "must have required property 'inviteId'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err2 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.inviteId !== void 0) {
      if (typeof data.inviteId !== "string") {
        const err3 = { instancePath: instancePath + "/inviteId", schemaPath: "#/properties/inviteId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate200.errors = vErrors;
  return errors === 0;
}
exports.isDeleteInviteRequest = validate201;
function validate201(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate200(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate200.errors : vErrors.concat(validate200.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate201.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous25Json = validate203;
function validate203(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate203.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous25 = validate204;
var schema105 = { "type": "object", "description": "ok", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [204] }, "json": { "$ref": "#/components/schemas/Anonymous25Json" } } };
function validate204(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 204)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema105.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate203(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate203.errors : vErrors.concat(validate203.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate204.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous26Json = validate206;
function validate206(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate206.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous26 = validate207;
var schema107 = { "type": "object", "description": "User is not authorized to view project", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [403] }, "json": { "$ref": "#/components/schemas/Anonymous26Json" } } };
function validate207(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 403)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema107.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate206(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate206.errors : vErrors.concat(validate206.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate207.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous27Json = validate209;
function validate209(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate209.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous27 = validate210;
var schema109 = { "type": "object", "description": "Project not found", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [404] }, "json": { "$ref": "#/components/schemas/Anonymous27Json" } } };
function validate210(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 404)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema109.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate209(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate209.errors : vErrors.concat(validate209.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate210.errors = vErrors;
  return errors === 0;
}
exports.isCreateRecommendationsResponse = validate212;
function validate212(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate204(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate204.errors : vErrors.concat(validate204.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate207(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate207.errors : vErrors.concat(validate207.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
    const _errs4 = errors;
    if (!validate210(data, { instancePath, parentData, parentDataProperty, rootData })) {
      vErrors = vErrors === null ? validate210.errors : vErrors.concat(validate210.errors);
      errors = vErrors.length;
    }
    var _valid0 = _errs4 === errors;
    if (_valid0 && valid0) {
      valid0 = false;
      passing0 = [passing0, 2];
    } else {
      if (_valid0) {
        valid0 = true;
        passing0 = 2;
      }
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate212.errors = vErrors;
  return errors === 0;
}
exports.isCreateRecommendationsRequestPathParameters = validate216;
function validate216(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate216.errors = vErrors;
  return errors === 0;
}
exports.isCreateRecommendationsRequestQueryStringParameters = validate217;
function validate217(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data)) && data !== null) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.batch !== void 0) {
      if (!validate24(data.batch, { instancePath: instancePath + "/batch", parentData: data, parentDataProperty: "batch", rootData })) {
        vErrors = vErrors === null ? validate24.errors : vErrors.concat(validate24.errors);
        errors = vErrors.length;
      }
    }
  }
  validate217.errors = vErrors;
  return errors === 0;
}
exports.isCreateRecommendationsRequestJson = validate219;
function validate219(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.batchSize !== void 0) {
      let data0 = data.batchSize;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err0 = { instancePath: instancePath + "/batchSize", schemaPath: "#/properties/batchSize/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err0];
        } else {
          vErrors.push(err0);
        }
        errors++;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate219.errors = vErrors;
  return errors === 0;
}
exports.isCreateRecommendationsRequest = validate220;
function validate220(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate216(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate216.errors : vErrors.concat(validate216.errors);
        errors = vErrors.length;
      }
    }
    if (data.queryStringParameters !== void 0) {
      if (!validate217(data.queryStringParameters, { instancePath: instancePath + "/queryStringParameters", parentData: data, parentDataProperty: "queryStringParameters", rootData })) {
        vErrors = vErrors === null ? validate217.errors : vErrors.concat(validate217.errors);
        errors = vErrors.length;
      }
    }
    if (data.json !== void 0) {
      if (!validate219(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate219.errors : vErrors.concat(validate219.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate220.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous28 = validate224;
var schema115 = { "type": "object", "description": "List of Recommendations for next steps of a Project", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "type": "array", "items": { "$ref": "#/components/schemas/Recommendation" } } } };
function validate224(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema115.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      let data1 = data.json;
      if (Array.isArray(data1)) {
        const len0 = data1.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate74(data1[i0], { instancePath: instancePath + "/json/" + i0, parentData: data1, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate74.errors : vErrors.concat(validate74.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err4 = { instancePath: instancePath + "/json", schemaPath: "#/properties/json/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
  } else {
    const err5 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err5];
    } else {
      vErrors.push(err5);
    }
    errors++;
  }
  validate224.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous29Json = validate226;
function validate226(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate226.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous29 = validate227;
var schema117 = { "type": "object", "description": "User is not authorized to view project", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [403] }, "json": { "$ref": "#/components/schemas/Anonymous29Json" } } };
function validate227(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 403)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema117.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate226(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate226.errors : vErrors.concat(validate226.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate227.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous30Json = validate229;
function validate229(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate229.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous30 = validate230;
var schema119 = { "type": "object", "description": "Project not found", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [404] }, "json": { "$ref": "#/components/schemas/Anonymous30Json" } } };
function validate230(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 404)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema119.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate229(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate229.errors : vErrors.concat(validate229.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate230.errors = vErrors;
  return errors === 0;
}
exports.isListRecommendationsResponse = validate232;
function validate232(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate224(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate224.errors : vErrors.concat(validate224.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate227(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate227.errors : vErrors.concat(validate227.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
    const _errs4 = errors;
    if (!validate230(data, { instancePath, parentData, parentDataProperty, rootData })) {
      vErrors = vErrors === null ? validate230.errors : vErrors.concat(validate230.errors);
      errors = vErrors.length;
    }
    var _valid0 = _errs4 === errors;
    if (_valid0 && valid0) {
      valid0 = false;
      passing0 = [passing0, 2];
    } else {
      if (_valid0) {
        valid0 = true;
        passing0 = 2;
      }
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate232.errors = vErrors;
  return errors === 0;
}
exports.isListRecommendationsRequestPathParameters = validate236;
function validate236(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate236.errors = vErrors;
  return errors === 0;
}
exports.isListRecommendationsRequestQueryStringParameters = validate237;
function validate237(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data)) && data !== null) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.batch !== void 0) {
      if (!validate24(data.batch, { instancePath: instancePath + "/batch", parentData: data, parentDataProperty: "batch", rootData })) {
        vErrors = vErrors === null ? validate24.errors : vErrors.concat(validate24.errors);
        errors = vErrors.length;
      }
    }
  }
  validate237.errors = vErrors;
  return errors === 0;
}
exports.isListRecommendationsRequest = validate239;
function validate239(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate236(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate236.errors : vErrors.concat(validate236.errors);
        errors = vErrors.length;
      }
    }
    if (data.queryStringParameters !== void 0) {
      if (!validate237(data.queryStringParameters, { instancePath: instancePath + "/queryStringParameters", parentData: data, parentDataProperty: "queryStringParameters", rootData })) {
        vErrors = vErrors === null ? validate237.errors : vErrors.concat(validate237.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate239.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous31Json = validate242;
function validate242(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate242.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous31 = validate243;
var schema125 = { "type": "object", "description": "Recommendation deleted successfully", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [204] }, "json": { "$ref": "#/components/schemas/Anonymous31Json" } } };
function validate243(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 204)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema125.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate242(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate242.errors : vErrors.concat(validate242.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate243.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous32Json = validate245;
function validate245(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate245.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous32 = validate246;
var schema127 = { "type": "object", "description": "User is not authorized to view project", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [403] }, "json": { "$ref": "#/components/schemas/Anonymous32Json" } } };
function validate246(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 403)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema127.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate245(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate245.errors : vErrors.concat(validate245.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate246.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous33Json = validate248;
function validate248(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate248.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous33 = validate249;
var schema129 = { "type": "object", "description": "Project or Recommendation not found", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [404] }, "json": { "$ref": "#/components/schemas/Anonymous33Json" } } };
function validate249(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 404)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema129.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate248(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate248.errors : vErrors.concat(validate248.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate249.errors = vErrors;
  return errors === 0;
}
exports.isRejectRecommendationResponse = validate251;
function validate251(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate243(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate243.errors : vErrors.concat(validate243.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate246(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate246.errors : vErrors.concat(validate246.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
    const _errs4 = errors;
    if (!validate249(data, { instancePath, parentData, parentDataProperty, rootData })) {
      vErrors = vErrors === null ? validate249.errors : vErrors.concat(validate249.errors);
      errors = vErrors.length;
    }
    var _valid0 = _errs4 === errors;
    if (_valid0 && valid0) {
      valid0 = false;
      passing0 = [passing0, 2];
    } else {
      if (_valid0) {
        valid0 = true;
        passing0 = 2;
      }
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate251.errors = vErrors;
  return errors === 0;
}
exports.isRejectRecommendationRequestPathParameters = validate255;
function validate255(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.recommendationId === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "recommendationId" }, message: "must have required property 'recommendationId'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err2 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.recommendationId !== void 0) {
      if (typeof data.recommendationId !== "string") {
        const err3 = { instancePath: instancePath + "/recommendationId", schemaPath: "#/properties/recommendationId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate255.errors = vErrors;
  return errors === 0;
}
exports.isRejectRecommendationRequest = validate256;
function validate256(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate255(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate255.errors : vErrors.concat(validate255.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate256.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous34 = validate258;
var schema133 = { "type": "object", "description": "List of experiments for a project", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "type": "array", "items": { "$ref": "#/components/schemas/Experiment" } } } };
function validate258(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema133.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      let data1 = data.json;
      if (Array.isArray(data1)) {
        const len0 = data1.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate69(data1[i0], { instancePath: instancePath + "/json/" + i0, parentData: data1, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate69.errors : vErrors.concat(validate69.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err4 = { instancePath: instancePath + "/json", schemaPath: "#/properties/json/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
  } else {
    const err5 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err5];
    } else {
      vErrors.push(err5);
    }
    errors++;
  }
  validate258.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous35Json = validate260;
function validate260(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate260.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous35 = validate261;
var schema135 = { "type": "object", "description": "User is not authorized to view project", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [403] }, "json": { "$ref": "#/components/schemas/Anonymous35Json" } } };
function validate261(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 403)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema135.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate260(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate260.errors : vErrors.concat(validate260.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate261.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous36Json = validate263;
function validate263(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate263.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous36 = validate264;
var schema137 = { "type": "object", "description": "Project not found", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [404] }, "json": { "$ref": "#/components/schemas/Anonymous36Json" } } };
function validate264(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 404)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema137.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate263(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate263.errors : vErrors.concat(validate263.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate264.errors = vErrors;
  return errors === 0;
}
exports.isListExperimentsResponse = validate266;
function validate266(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate258(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate258.errors : vErrors.concat(validate258.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate261(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate261.errors : vErrors.concat(validate261.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
    const _errs4 = errors;
    if (!validate264(data, { instancePath, parentData, parentDataProperty, rootData })) {
      vErrors = vErrors === null ? validate264.errors : vErrors.concat(validate264.errors);
      errors = vErrors.length;
    }
    var _valid0 = _errs4 === errors;
    if (_valid0 && valid0) {
      valid0 = false;
      passing0 = [passing0, 2];
    } else {
      if (_valid0) {
        valid0 = true;
        passing0 = 2;
      }
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate266.errors = vErrors;
  return errors === 0;
}
exports.isListExperimentsRequestPathParameters = validate270;
function validate270(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate270.errors = vErrors;
  return errors === 0;
}
exports.isListExperimentsRequest = validate271;
function validate271(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate270(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate270.errors : vErrors.concat(validate270.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate271.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous37 = validate273;
var schema141 = { "type": "object", "description": "Experiments succesfully created", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [201] }, "json": { "type": "array", "items": { "$ref": "#/components/schemas/Experiment" } } } };
function validate273(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 201)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema141.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      let data1 = data.json;
      if (Array.isArray(data1)) {
        const len0 = data1.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate69(data1[i0], { instancePath: instancePath + "/json/" + i0, parentData: data1, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate69.errors : vErrors.concat(validate69.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err4 = { instancePath: instancePath + "/json", schemaPath: "#/properties/json/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
  } else {
    const err5 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err5];
    } else {
      vErrors.push(err5);
    }
    errors++;
  }
  validate273.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous38Json = validate275;
function validate275(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate275.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous38 = validate276;
var schema143 = { "type": "object", "description": "Invalid input", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [400] }, "json": { "$ref": "#/components/schemas/Anonymous38Json" } } };
function validate276(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 400)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema143.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate275(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate275.errors : vErrors.concat(validate275.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate276.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous39Json = validate278;
function validate278(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate278.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous39 = validate279;
var schema145 = { "type": "object", "description": "User is not authorized to change experiment", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [403] }, "json": { "$ref": "#/components/schemas/Anonymous39Json" } } };
function validate279(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 403)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema145.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate278(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate278.errors : vErrors.concat(validate278.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate279.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous40Json = validate281;
function validate281(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate281.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous40 = validate282;
var schema147 = { "type": "object", "description": "Experiement not found", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [404] }, "json": { "$ref": "#/components/schemas/Anonymous40Json" } } };
function validate282(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 404)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema147.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate281(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate281.errors : vErrors.concat(validate281.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate282.errors = vErrors;
  return errors === 0;
}
exports.isCreateExperimentsResponse = validate284;
function validate284(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate273(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate273.errors : vErrors.concat(validate273.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate276(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate276.errors : vErrors.concat(validate276.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
    const _errs4 = errors;
    if (!validate279(data, { instancePath, parentData, parentDataProperty, rootData })) {
      vErrors = vErrors === null ? validate279.errors : vErrors.concat(validate279.errors);
      errors = vErrors.length;
    }
    var _valid0 = _errs4 === errors;
    if (_valid0 && valid0) {
      valid0 = false;
      passing0 = [passing0, 2];
    } else {
      if (_valid0) {
        valid0 = true;
        passing0 = 2;
      }
      const _errs5 = errors;
      if (!validate282(data, { instancePath, parentData, parentDataProperty, rootData })) {
        vErrors = vErrors === null ? validate282.errors : vErrors.concat(validate282.errors);
        errors = vErrors.length;
      }
      var _valid0 = _errs5 === errors;
      if (_valid0 && valid0) {
        valid0 = false;
        passing0 = [passing0, 3];
      } else {
        if (_valid0) {
          valid0 = true;
          passing0 = 3;
        }
      }
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate284.errors = vErrors;
  return errors === 0;
}
exports.isCreateExperimentsRequestPathParameters = validate289;
function validate289(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate289.errors = vErrors;
  return errors === 0;
}
exports.isCreateExperimentsRequestJsonItemResults = validate290;
function validate290(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      let data0 = data[key0];
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err0 = { instancePath: instancePath + "/" + key0.replace(/~/g, "~0").replace(/\//g, "~1"), schemaPath: "#/additionalProperties/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err0];
        } else {
          vErrors.push(err0);
        }
        errors++;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate290.errors = vErrors;
  return errors === 0;
}
exports.isCreateExperimentsRequestJsonItem = validate291;
function validate291(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.parameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "parameters" }, message: "must have required property 'parameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.results === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "results" }, message: "must have required property 'results'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.recommendationId !== void 0) {
      if (typeof data.recommendationId !== "string") {
        const err2 = { instancePath: instancePath + "/recommendationId", schemaPath: "#/properties/recommendationId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.parameters !== void 0) {
      if (!validate46(data.parameters, { instancePath: instancePath + "/parameters", parentData: data, parentDataProperty: "parameters", rootData })) {
        vErrors = vErrors === null ? validate46.errors : vErrors.concat(validate46.errors);
        errors = vErrors.length;
      }
    }
    if (data.results !== void 0) {
      if (!validate290(data.results, { instancePath: instancePath + "/results", parentData: data, parentDataProperty: "results", rootData })) {
        vErrors = vErrors === null ? validate290.errors : vErrors.concat(validate290.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate291.errors = vErrors;
  return errors === 0;
}
exports.isCreateExperimentsRequestJson = validate294;
function validate294(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (Array.isArray(data)) {
    const len0 = data.length;
    for (let i0 = 0; i0 < len0; i0++) {
      if (!validate291(data[i0], { instancePath: instancePath + "/" + i0, parentData: data, parentDataProperty: i0, rootData })) {
        vErrors = vErrors === null ? validate291.errors : vErrors.concat(validate291.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "array" }, message: "must be array" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate294.errors = vErrors;
  return errors === 0;
}
exports.isCreateExperimentsRequest = validate296;
function validate296(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate289(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate289.errors : vErrors.concat(validate289.errors);
        errors = vErrors.length;
      }
    }
    if (data.json !== void 0) {
      if (!validate294(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate294.errors : vErrors.concat(validate294.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate296.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous41 = validate299;
var schema154 = { "type": "object", "description": "List of experiments for a project", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "type": "array", "items": { "$ref": "#/components/schemas/Experiment" } } } };
function validate299(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema154.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      let data1 = data.json;
      if (Array.isArray(data1)) {
        const len0 = data1.length;
        for (let i0 = 0; i0 < len0; i0++) {
          if (!validate69(data1[i0], { instancePath: instancePath + "/json/" + i0, parentData: data1, parentDataProperty: i0, rootData })) {
            vErrors = vErrors === null ? validate69.errors : vErrors.concat(validate69.errors);
            errors = vErrors.length;
          }
        }
      } else {
        const err4 = { instancePath: instancePath + "/json", schemaPath: "#/properties/json/type", keyword: "type", params: { type: "array" }, message: "must be array" };
        if (vErrors === null) {
          vErrors = [err4];
        } else {
          vErrors.push(err4);
        }
        errors++;
      }
    }
  } else {
    const err5 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err5];
    } else {
      vErrors.push(err5);
    }
    errors++;
  }
  validate299.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous42Json = validate301;
function validate301(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate301.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous42 = validate302;
var schema156 = { "type": "object", "description": "User is not authorized to view project", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [403] }, "json": { "$ref": "#/components/schemas/Anonymous42Json" } } };
function validate302(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 403)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema156.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate301(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate301.errors : vErrors.concat(validate301.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate302.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous43Json = validate304;
function validate304(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate304.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous43 = validate305;
var schema158 = { "type": "object", "description": "Experiement not found", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [404] }, "json": { "$ref": "#/components/schemas/Anonymous43Json" } } };
function validate305(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 404)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema158.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate304(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate304.errors : vErrors.concat(validate304.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate305.errors = vErrors;
  return errors === 0;
}
exports.isChangeHistoryResponse = validate307;
function validate307(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate299(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate299.errors : vErrors.concat(validate299.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate302(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate302.errors : vErrors.concat(validate302.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
    const _errs4 = errors;
    if (!validate305(data, { instancePath, parentData, parentDataProperty, rootData })) {
      vErrors = vErrors === null ? validate305.errors : vErrors.concat(validate305.errors);
      errors = vErrors.length;
    }
    var _valid0 = _errs4 === errors;
    if (_valid0 && valid0) {
      valid0 = false;
      passing0 = [passing0, 2];
    } else {
      if (_valid0) {
        valid0 = true;
        passing0 = 2;
      }
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate307.errors = vErrors;
  return errors === 0;
}
exports.isChangeHistoryRequestPathParameters = validate311;
function validate311(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate311.errors = vErrors;
  return errors === 0;
}
exports.isChangeHistoryRequestJsonDeleteItem = validate312;
function validate312(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.experimentId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "experimentId" }, message: "must have required property 'experimentId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.experimentId !== void 0) {
      if (typeof data.experimentId !== "string") {
        const err1 = { instancePath: instancePath + "/experimentId", schemaPath: "#/properties/experimentId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate312.errors = vErrors;
  return errors === 0;
}
exports.isChangeHistoryRequestJsonDelete = validate313;
function validate313(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (Array.isArray(data)) {
    const len0 = data.length;
    for (let i0 = 0; i0 < len0; i0++) {
      if (!validate312(data[i0], { instancePath: instancePath + "/" + i0, parentData: data, parentDataProperty: i0, rootData })) {
        vErrors = vErrors === null ? validate312.errors : vErrors.concat(validate312.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "array" }, message: "must be array" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate313.errors = vErrors;
  return errors === 0;
}
exports.isChangeHistoryRequestJsonModifyItemResults = validate315;
function validate315(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      let data0 = data[key0];
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err0 = { instancePath: instancePath + "/" + key0.replace(/~/g, "~0").replace(/\//g, "~1"), schemaPath: "#/additionalProperties/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err0];
        } else {
          vErrors.push(err0);
        }
        errors++;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate315.errors = vErrors;
  return errors === 0;
}
exports.isChangeHistoryRequestJsonModifyItem = validate316;
function validate316(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.experimentId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "experimentId" }, message: "must have required property 'experimentId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.results === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "results" }, message: "must have required property 'results'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.experimentId !== void 0) {
      if (typeof data.experimentId !== "string") {
        const err2 = { instancePath: instancePath + "/experimentId", schemaPath: "#/properties/experimentId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.results !== void 0) {
      if (!validate315(data.results, { instancePath: instancePath + "/results", parentData: data, parentDataProperty: "results", rootData })) {
        vErrors = vErrors === null ? validate315.errors : vErrors.concat(validate315.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate316.errors = vErrors;
  return errors === 0;
}
exports.isChangeHistoryRequestJsonModify = validate318;
function validate318(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (Array.isArray(data)) {
    const len0 = data.length;
    for (let i0 = 0; i0 < len0; i0++) {
      if (!validate316(data[i0], { instancePath: instancePath + "/" + i0, parentData: data, parentDataProperty: i0, rootData })) {
        vErrors = vErrors === null ? validate316.errors : vErrors.concat(validate316.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "array" }, message: "must be array" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate318.errors = vErrors;
  return errors === 0;
}
exports.isChangeHistoryRequestJson = validate320;
function validate320(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.delete !== void 0) {
      if (!validate313(data.delete, { instancePath: instancePath + "/delete", parentData: data, parentDataProperty: "delete", rootData })) {
        vErrors = vErrors === null ? validate313.errors : vErrors.concat(validate313.errors);
        errors = vErrors.length;
      }
    }
    if (data.modify !== void 0) {
      if (!validate318(data.modify, { instancePath: instancePath + "/modify", parentData: data, parentDataProperty: "modify", rootData })) {
        vErrors = vErrors === null ? validate318.errors : vErrors.concat(validate318.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate320.errors = vErrors;
  return errors === 0;
}
exports.isChangeHistoryRequest = validate323;
function validate323(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate311(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate311.errors : vErrors.concat(validate311.errors);
        errors = vErrors.length;
      }
    }
    if (data.json !== void 0) {
      if (!validate320(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate320.errors : vErrors.concat(validate320.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate323.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous44Json = validate326;
function validate326(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  validate326.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous44 = validate327;
var schema169 = { "type": "object", "description": "Returns Baybe Configs for the Project", "required": ["statusCode", "json"], "properties": { "statusCode": { "type": "number", "enum": [200] }, "json": { "$ref": "#/components/schemas/Anonymous44Json" } } };
function validate327(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
      if (!(data0 === 200)) {
        const err3 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema169.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate326(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate326.errors : vErrors.concat(validate326.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate327.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous45Json = validate329;
function validate329(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate329.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous45 = validate330;
var schema171 = { "type": "object", "description": "User is not authorized to view project configs", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [403] }, "json": { "$ref": "#/components/schemas/Anonymous45Json" } } };
function validate330(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 403)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema171.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate329(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate329.errors : vErrors.concat(validate329.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate330.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous46Json = validate332;
function validate332(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate332.errors = vErrors;
  return errors === 0;
}
exports.isAnonymous46 = validate333;
var schema173 = { "type": "object", "description": "Project not found", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [404] }, "json": { "$ref": "#/components/schemas/Anonymous46Json" } } };
function validate333(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 404)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema173.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate332(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate332.errors : vErrors.concat(validate332.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate333.errors = vErrors;
  return errors === 0;
}
exports.isGetBaybeConfigsResponse = validate335;
function validate335(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (!(data && typeof data == "object" && !Array.isArray(data))) {
    const err0 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err0];
    } else {
      vErrors.push(err0);
    }
    errors++;
  }
  const _errs1 = errors;
  let valid0 = false;
  let passing0 = null;
  const _errs2 = errors;
  if (!validate327(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate327.errors : vErrors.concat(validate327.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs2 === errors;
  if (_valid0) {
    valid0 = true;
    passing0 = 0;
  }
  const _errs3 = errors;
  if (!validate330(data, { instancePath, parentData, parentDataProperty, rootData })) {
    vErrors = vErrors === null ? validate330.errors : vErrors.concat(validate330.errors);
    errors = vErrors.length;
  }
  var _valid0 = _errs3 === errors;
  if (_valid0 && valid0) {
    valid0 = false;
    passing0 = [passing0, 1];
  } else {
    if (_valid0) {
      valid0 = true;
      passing0 = 1;
    }
    const _errs4 = errors;
    if (!validate333(data, { instancePath, parentData, parentDataProperty, rootData })) {
      vErrors = vErrors === null ? validate333.errors : vErrors.concat(validate333.errors);
      errors = vErrors.length;
    }
    var _valid0 = _errs4 === errors;
    if (_valid0 && valid0) {
      valid0 = false;
      passing0 = [passing0, 2];
    } else {
      if (_valid0) {
        valid0 = true;
        passing0 = 2;
      }
    }
  }
  if (!valid0) {
    const err1 = { instancePath, schemaPath: "#/oneOf", keyword: "oneOf", params: { passingSchemas: passing0 }, message: "must match exactly one schema in oneOf" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  } else {
    errors = _errs1;
    if (vErrors !== null) {
      if (_errs1) {
        vErrors.length = _errs1;
      } else {
        vErrors = null;
      }
    }
  }
  validate335.errors = vErrors;
  return errors === 0;
}
exports.isGetBaybeConfigsRequestPathParameters = validate339;
function validate339(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.projectId === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "projectId" }, message: "must have required property 'projectId'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.projectId !== void 0) {
      if (typeof data.projectId !== "string") {
        const err1 = { instancePath: instancePath + "/projectId", schemaPath: "#/properties/projectId/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
    }
  } else {
    const err2 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err2];
    } else {
      vErrors.push(err2);
    }
    errors++;
  }
  validate339.errors = vErrors;
  return errors === 0;
}
exports.isGetBaybeConfigsRequest = validate340;
function validate340(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.pathParameters === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "pathParameters" }, message: "must have required property 'pathParameters'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.pathParameters !== void 0) {
      if (!validate339(data.pathParameters, { instancePath: instancePath + "/pathParameters", parentData: data, parentDataProperty: "pathParameters", rootData })) {
        vErrors = vErrors === null ? validate339.errors : vErrors.concat(validate339.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate340.errors = vErrors;
  return errors === 0;
}
exports.isCreateFeedbackResponseJson = validate342;
function validate342(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    for (const key0 in data) {
      const err0 = { instancePath, schemaPath: "#/additionalProperties", keyword: "additionalProperties", params: { additionalProperty: key0 }, message: "must NOT have additional properties" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate342.errors = vErrors;
  return errors === 0;
}
exports.isCreateFeedbackResponse = validate343;
var schema178 = { "type": "object", "description": "Feedback successfully posted.", "required": ["statusCode"], "properties": { "statusCode": { "type": "number", "enum": [201] }, "json": { "$ref": "#/components/schemas/CreateFeedbackResponseJson" } } };
function validate343(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.statusCode === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "statusCode" }, message: "must have required property 'statusCode'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.statusCode !== void 0) {
      let data0 = data.statusCode;
      if (!(typeof data0 == "number" && isFinite(data0))) {
        const err1 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/type", keyword: "type", params: { type: "number" }, message: "must be number" };
        if (vErrors === null) {
          vErrors = [err1];
        } else {
          vErrors.push(err1);
        }
        errors++;
      }
      if (!(data0 === 201)) {
        const err2 = { instancePath: instancePath + "/statusCode", schemaPath: "#/properties/statusCode/enum", keyword: "enum", params: { allowedValues: schema178.properties.statusCode.enum }, message: "must be equal to one of the allowed values" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.json !== void 0) {
      if (!validate342(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate342.errors : vErrors.concat(validate342.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err3 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err3];
    } else {
      vErrors.push(err3);
    }
    errors++;
  }
  validate343.errors = vErrors;
  return errors === 0;
}
exports.isCreateFeedbackRequestJson = validate345;
function validate345(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.subject === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "subject" }, message: "must have required property 'subject'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.message === void 0) {
      const err1 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "message" }, message: "must have required property 'message'" };
      if (vErrors === null) {
        vErrors = [err1];
      } else {
        vErrors.push(err1);
      }
      errors++;
    }
    if (data.subject !== void 0) {
      if (typeof data.subject !== "string") {
        const err2 = { instancePath: instancePath + "/subject", schemaPath: "#/properties/subject/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err2];
        } else {
          vErrors.push(err2);
        }
        errors++;
      }
    }
    if (data.message !== void 0) {
      if (typeof data.message !== "string") {
        const err3 = { instancePath: instancePath + "/message", schemaPath: "#/properties/message/type", keyword: "type", params: { type: "string" }, message: "must be string" };
        if (vErrors === null) {
          vErrors = [err3];
        } else {
          vErrors.push(err3);
        }
        errors++;
      }
    }
  } else {
    const err4 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err4];
    } else {
      vErrors.push(err4);
    }
    errors++;
  }
  validate345.errors = vErrors;
  return errors === 0;
}
exports.isCreateFeedbackRequest = validate346;
function validate346(data, { instancePath = "", parentData, parentDataProperty, rootData = data } = {}) {
  let vErrors = null;
  let errors = 0;
  if (data && typeof data == "object" && !Array.isArray(data)) {
    if (data.json === void 0) {
      const err0 = { instancePath, schemaPath: "#/required", keyword: "required", params: { missingProperty: "json" }, message: "must have required property 'json'" };
      if (vErrors === null) {
        vErrors = [err0];
      } else {
        vErrors.push(err0);
      }
      errors++;
    }
    if (data.json !== void 0) {
      if (!validate345(data.json, { instancePath: instancePath + "/json", parentData: data, parentDataProperty: "json", rootData })) {
        vErrors = vErrors === null ? validate345.errors : vErrors.concat(validate345.errors);
        errors = vErrors.length;
      }
    }
  } else {
    const err1 = { instancePath, schemaPath: "#/type", keyword: "type", params: { type: "object" }, message: "must be object" };
    if (vErrors === null) {
      vErrors = [err1];
    } else {
      vErrors.push(err1);
    }
    errors++;
  }
  validate346.errors = vErrors;
  return errors === 0;
}
var validators = {
  GetUser: {
    isRequest: exports.isGetUserRequest,
    isResponse: exports.isGetUserResponse,
    assertRequest: assertGetUserRequest,
    assertResponse: assertGetUserResponse
  },
  ListUserInvites: {
    isRequest: exports.isListUserInvitesRequest,
    isResponse: exports.isListUserInvitesResponse,
    assertRequest: assertListUserInvitesRequest,
    assertResponse: assertListUserInvitesResponse
  },
  ListProjects: {
    isRequest: exports.isListProjectsRequest,
    isResponse: exports.isListProjectsResponse,
    assertRequest: assertListProjectsRequest,
    assertResponse: assertListProjectsResponse
  },
  CreateProject: {
    isRequest: exports.isCreateProjectRequest,
    isResponse: exports.isCreateProjectResponse,
    assertRequest: assertCreateProjectRequest,
    assertResponse: assertCreateProjectResponse
  },
  DeleteProject: {
    isRequest: exports.isDeleteProjectRequest,
    isResponse: exports.isDeleteProjectResponse,
    assertRequest: assertDeleteProjectRequest,
    assertResponse: assertDeleteProjectResponse
  },
  GetProjectById: {
    isRequest: exports.isGetProjectByIdRequest,
    isResponse: exports.isGetProjectByIdResponse,
    assertRequest: assertGetProjectByIdRequest,
    assertResponse: assertGetProjectByIdResponse
  },
  ExpandChemicalSpace: {
    isRequest: exports.isExpandChemicalSpaceRequest,
    isResponse: exports.isExpandChemicalSpaceResponse,
    assertRequest: assertExpandChemicalSpaceRequest,
    assertResponse: assertExpandChemicalSpaceResponse
  },
  CloseProject: {
    isRequest: exports.isCloseProjectRequest,
    isResponse: exports.isCloseProjectResponse,
    assertRequest: assertCloseProjectRequest,
    assertResponse: assertCloseProjectResponse
  },
  ReopenProject: {
    isRequest: exports.isReopenProjectRequest,
    isResponse: exports.isReopenProjectResponse,
    assertRequest: assertReopenProjectRequest,
    assertResponse: assertReopenProjectResponse
  },
  ListInvites: {
    isRequest: exports.isListInvitesRequest,
    isResponse: exports.isListInvitesResponse,
    assertRequest: assertListInvitesRequest,
    assertResponse: assertListInvitesResponse
  },
  SendInvite: {
    isRequest: exports.isSendInviteRequest,
    isResponse: exports.isSendInviteResponse,
    assertRequest: assertSendInviteRequest,
    assertResponse: assertSendInviteResponse
  },
  GetInviteById: {
    isRequest: exports.isGetInviteByIdRequest,
    isResponse: exports.isGetInviteByIdResponse,
    assertRequest: assertGetInviteByIdRequest,
    assertResponse: assertGetInviteByIdResponse
  },
  ChangeInviteStatus: {
    isRequest: exports.isChangeInviteStatusRequest,
    isResponse: exports.isChangeInviteStatusResponse,
    assertRequest: assertChangeInviteStatusRequest,
    assertResponse: assertChangeInviteStatusResponse
  },
  DeleteInvite: {
    isRequest: exports.isDeleteInviteRequest,
    isResponse: exports.isDeleteInviteResponse,
    assertRequest: assertDeleteInviteRequest,
    assertResponse: assertDeleteInviteResponse
  },
  CreateRecommendations: {
    isRequest: exports.isCreateRecommendationsRequest,
    isResponse: exports.isCreateRecommendationsResponse,
    assertRequest: assertCreateRecommendationsRequest,
    assertResponse: assertCreateRecommendationsResponse
  },
  ListRecommendations: {
    isRequest: exports.isListRecommendationsRequest,
    isResponse: exports.isListRecommendationsResponse,
    assertRequest: assertListRecommendationsRequest,
    assertResponse: assertListRecommendationsResponse
  },
  RejectRecommendation: {
    isRequest: exports.isRejectRecommendationRequest,
    isResponse: exports.isRejectRecommendationResponse,
    assertRequest: assertRejectRecommendationRequest,
    assertResponse: assertRejectRecommendationResponse
  },
  ListExperiments: {
    isRequest: exports.isListExperimentsRequest,
    isResponse: exports.isListExperimentsResponse,
    assertRequest: assertListExperimentsRequest,
    assertResponse: assertListExperimentsResponse
  },
  CreateExperiments: {
    isRequest: exports.isCreateExperimentsRequest,
    isResponse: exports.isCreateExperimentsResponse,
    assertRequest: assertCreateExperimentsRequest,
    assertResponse: assertCreateExperimentsResponse
  },
  ChangeHistory: {
    isRequest: exports.isChangeHistoryRequest,
    isResponse: exports.isChangeHistoryResponse,
    assertRequest: assertChangeHistoryRequest,
    assertResponse: assertChangeHistoryResponse
  },
  GetBaybeConfigs: {
    isRequest: exports.isGetBaybeConfigsRequest,
    isResponse: exports.isGetBaybeConfigsResponse,
    assertRequest: assertGetBaybeConfigsRequest,
    assertResponse: assertGetBaybeConfigsResponse
  },
  CreateFeedback: {
    isRequest: exports.isCreateFeedbackRequest,
    isResponse: exports.isCreateFeedbackResponse,
    assertRequest: assertCreateFeedbackRequest,
    assertResponse: assertCreateFeedbackResponse
  }
};
var routes = {
  "GET /user": "GetUser",
  "GET /invite": "ListUserInvites",
  "GET /project": "ListProjects",
  "POST /project": "CreateProject",
  "DELETE /project": "DeleteProject",
  "GET /project/{projectId}": "GetProjectById",
  "PUT /project/{projectId}": "ExpandChemicalSpace",
  "DELETE /project/{projectId}": "CloseProject",
  "POST /project/{projectId}": "ReopenProject",
  "GET /project/{projectId}/invite": "ListInvites",
  "POST /project/{projectId}/invite": "SendInvite",
  "GET /project/{projectId}/invite/{inviteId}": "GetInviteById",
  "PUT /project/{projectId}/invite/{inviteId}": "ChangeInviteStatus",
  "DELETE /project/{projectId}/invite/{inviteId}": "DeleteInvite",
  "POST /project/{projectId}/recommendation": "CreateRecommendations",
  "GET /project/{projectId}/recommendation": "ListRecommendations",
  "DELETE /project/{projectId}/recommendation/{recommendationId}": "RejectRecommendation",
  "GET /project/{projectId}/experiment": "ListExperiments",
  "POST /project/{projectId}/experiment": "CreateExperiments",
  "PUT /project/{projectId}/experiment": "ChangeHistory",
  "GET /project/{projectId}/config": "GetBaybeConfigs",
  "POST /feedback": "CreateFeedback"
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  AcquisitionFunctionType,
  BatchQueryParameterStringValue,
  ExperimentStatus,
  InitialGuessMethodType,
  ParameterType,
  ProjectRole,
  ProjectStatus,
  RecommendationStatus,
  SurrogateModelType,
  assertAcquisitionFunctionType,
  assertAnonymous1,
  assertAnonymous10,
  assertAnonymous10Json,
  assertAnonymous11,
  assertAnonymous11Json,
  assertAnonymous12,
  assertAnonymous15,
  assertAnonymous15Json,
  assertAnonymous16,
  assertAnonymous16Json,
  assertAnonymous17,
  assertAnonymous20,
  assertAnonymous20Json,
  assertAnonymous21,
  assertAnonymous21Json,
  assertAnonymous22,
  assertAnonymous22Json,
  assertAnonymous23,
  assertAnonymous24,
  assertAnonymous24Json,
  assertAnonymous25,
  assertAnonymous25Json,
  assertAnonymous26,
  assertAnonymous26Json,
  assertAnonymous27,
  assertAnonymous27Json,
  assertAnonymous28,
  assertAnonymous29,
  assertAnonymous29Json,
  assertAnonymous30,
  assertAnonymous30Json,
  assertAnonymous31,
  assertAnonymous31Json,
  assertAnonymous32,
  assertAnonymous32Json,
  assertAnonymous33,
  assertAnonymous33Json,
  assertAnonymous34,
  assertAnonymous35,
  assertAnonymous35Json,
  assertAnonymous36,
  assertAnonymous36Json,
  assertAnonymous37,
  assertAnonymous38,
  assertAnonymous38Json,
  assertAnonymous39,
  assertAnonymous39Json,
  assertAnonymous40,
  assertAnonymous40Json,
  assertAnonymous41,
  assertAnonymous42,
  assertAnonymous42Json,
  assertAnonymous43,
  assertAnonymous43Json,
  assertAnonymous44,
  assertAnonymous44Json,
  assertAnonymous45,
  assertAnonymous45Json,
  assertAnonymous46,
  assertAnonymous46Json,
  assertAnonymous6,
  assertAnonymous9,
  assertAnonymous9Json,
  assertBatchQueryParameterNumericValue,
  assertBatchQueryParameterStringValue,
  assertBatchQueryParameterValue,
  assertChangeHistoryRequest,
  assertChangeHistoryRequestJson,
  assertChangeHistoryRequestJsonDelete,
  assertChangeHistoryRequestJsonDeleteItem,
  assertChangeHistoryRequestJsonModify,
  assertChangeHistoryRequestJsonModifyItem,
  assertChangeHistoryRequestJsonModifyItemResults,
  assertChangeHistoryRequestPathParameters,
  assertChangeHistoryResponse,
  assertChangeInviteStatusRequest,
  assertChangeInviteStatusRequestJson,
  assertChangeInviteStatusRequestPathParameters,
  assertChangeInviteStatusResponse,
  assertCloseProjectRequest,
  assertCloseProjectRequestPathParameters,
  assertCloseProjectResponse,
  assertCloseProjectResponseJson,
  assertCreateExperimentsRequest,
  assertCreateExperimentsRequestJson,
  assertCreateExperimentsRequestJsonItem,
  assertCreateExperimentsRequestJsonItemResults,
  assertCreateExperimentsRequestPathParameters,
  assertCreateExperimentsResponse,
  assertCreateFeedbackRequest,
  assertCreateFeedbackRequestJson,
  assertCreateFeedbackResponse,
  assertCreateFeedbackResponseJson,
  assertCreateProjectRequest,
  assertCreateProjectRequestJson,
  assertCreateProjectResponse,
  assertCreateRecommendationsRequest,
  assertCreateRecommendationsRequestJson,
  assertCreateRecommendationsRequestPathParameters,
  assertCreateRecommendationsRequestQueryStringParameters,
  assertCreateRecommendationsResponse,
  assertCustomParameterValue,
  assertDeleteInviteRequest,
  assertDeleteInviteRequestPathParameters,
  assertDeleteInviteResponse,
  assertDeleteInviteResponseJson,
  assertDeleteProjectRequest,
  assertDeleteProjectRequestJson,
  assertDeleteProjectResponse,
  assertExpandChemicalSpaceRequest,
  assertExpandChemicalSpaceRequestJson,
  assertExpandChemicalSpaceRequestJsonExperiments,
  assertExpandChemicalSpaceRequestPathParameters,
  assertExpandChemicalSpaceResponse,
  assertExperiment,
  assertExperimentResults,
  assertExperimentStatus,
  assertExpertOptions,
  assertGetBaybeConfigsRequest,
  assertGetBaybeConfigsRequestPathParameters,
  assertGetBaybeConfigsResponse,
  assertGetInviteByIdRequest,
  assertGetInviteByIdRequestPathParameters,
  assertGetInviteByIdResponse,
  assertGetProjectByIdRequest,
  assertGetProjectByIdRequestPathParameters,
  assertGetProjectByIdResponse,
  assertGetUserRequest,
  assertGetUserResponse,
  assertInitialGuessMethodType,
  assertListExperimentsRequest,
  assertListExperimentsRequestPathParameters,
  assertListExperimentsResponse,
  assertListInvitesRequest,
  assertListInvitesRequestPathParameters,
  assertListInvitesResponse,
  assertListProjectsRequest,
  assertListProjectsResponse,
  assertListRecommendationsRequest,
  assertListRecommendationsRequestPathParameters,
  assertListRecommendationsRequestQueryStringParameters,
  assertListRecommendationsResponse,
  assertListUserInvitesRequest,
  assertListUserInvitesRequestQueryStringParameters,
  assertListUserInvitesResponse,
  assertNumericParameterValue,
  assertNumericalContinuousParameter,
  assertParameter,
  assertParameterRecommendation,
  assertParameterRecommendationValue,
  assertParameterType,
  assertParameterValue,
  assertProject,
  assertProjectInvite,
  assertProjectRole,
  assertProjectStatus,
  assertRecommendation,
  assertRecommendationStatus,
  assertRejectRecommendationRequest,
  assertRejectRecommendationRequestPathParameters,
  assertRejectRecommendationResponse,
  assertReopenProjectRequest,
  assertReopenProjectRequestPathParameters,
  assertReopenProjectResponse,
  assertReopenProjectResponseJson,
  assertSendInviteRequest,
  assertSendInviteRequestJson,
  assertSendInviteRequestPathParameters,
  assertSendInviteResponse,
  assertSmilesParameterValue,
  assertStringParameterValue,
  assertSurrogateModelType,
  assertTargetVariable,
  assertUser,
  routes,
  validators
});
