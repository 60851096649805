import * as yup from 'yup';

import {
  SmilesParameterValue,
  Parameter,
  ParameterRecommendation,
  CustomParameterValue,
  NumericalContinuousParameter,
  SurrogateModelType,
  InitialGuessMethodType,
  AcquisitionFunctionType,
  ParameterType,
  ExperimentResults,
} from '../api/types';

export interface ParameterFormEntry {
  id: string;
  value?: string | SmilesParameterValue | CustomParameterValue | number[];
}

export interface ParametersObject {
  [key: string]: Parameter;
}

// TODO: check if the enum ParameterType will work for E2E
export type ParameterTypeString =
  | 'Number'
  | 'Substance'
  | 'Category'
  | 'Custom'
  | 'Continuous'
  | 'Task';

export interface Feedback {
  subject?: string;
  message?: string;
}

export interface Bug {
  title?: string;
  steps_to_reproduce?: string;
  expected_behavior?: string;
  system_information?: string;
  attachment?: {
    name: string;
    data: string;
  };
}

export interface BugFormData {
  title?: string;
  stepsToReproduce?: string;
  expectedBehavior?: string;
  systemInformation?: string;
  attachment?: File;
}

export enum QuestionType {
  star = 'star',
  text = 'text',
}

export interface FeedbackQuestion {
  questionId: string;
  text: string;
  questionType: keyof typeof QuestionType;
  isMandatory: boolean;
}

export interface FeedbackProduct {
  productId: string;
  name: string;
  description: string;
  createdAt: string;
  questions: {
    [key: string]: FeedbackQuestion;
  };
}

export enum TargetObjective {
  Max = 'Max',
  Min = 'Min',
  Match = 'Match',
}

export enum StateStatus {
  idle = 'idle',
  loading = 'loading',
  succeeded = 'succeeded',
  failed = 'failed',
}

export interface ExperimentResult {
  recommendationId?: string;
  parameters: ParameterRecommendation;
  results: ExperimentResults;
}

export const smilesValidationResponseScheme = yup.object({
  success: yup.boolean().required(),
  error: yup.string().nullable(),
  valid: yup.boolean().nullable(),
});

export const SurroGateModelLabel = new Map<string, string>([
  [SurrogateModelType.GP, 'Gaussian Process'],
  [SurrogateModelType.RF, 'Random Forrest'],
  [SurrogateModelType.Linear, 'Linear Bayesian'],
]);

export const InitialGuessMethodLabel = new Map<string, string>([
  [InitialGuessMethodType.rand, 'Random'],
  [InitialGuessMethodType.pam, 'Partition Around Medoids (PAM)'],
  [InitialGuessMethodType.kmeans, 'K-Means'],
]);

export const AcquisitionFunctionLabel = new Map<string, string>([
  [AcquisitionFunctionType.EI, 'Expected Improvement'],
  [AcquisitionFunctionType.TS, 'Thompson-Sampling'],
  [AcquisitionFunctionType.PI, 'Probability of Improvement'],
  [AcquisitionFunctionType.UCB, 'Upper Confidence Bound'],
  [AcquisitionFunctionType.MeanMax, 'Pure Exploitation'],
  [AcquisitionFunctionType.VarMax, 'Pure Exploration'],
]);

export const ParameterTypesLabel = new Map<string, string>([
  [ParameterType.smiles, 'Substance'],
  [ParameterType.category, 'Category'],
  [ParameterType.numeric, 'Number'],
  [ParameterType.custom, 'Custom'],
  [ParameterType.continuous, 'Continuous'],
  [ParameterType.task, 'Task'],
]);

export const TargetObjectivesLabel = new Map<string, string>([
  [TargetObjective.Max, 'Max'],
  [TargetObjective.Min, 'Min'],
  [TargetObjective.Match, 'Match'],
]);

export const roleFilterItems = [
  'createdByMe',
  'Owner',
  'Editor',
  'Viewer',
] as const;

export type RoleFilter = typeof roleFilterItems[number];
