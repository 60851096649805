import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import {
  LdButton,
  LdIcon,
  LdInput,
  LdTable,
  LdTableBody,
  LdTableCell,
  LdTableHead,
  LdTableHeader,
  LdTableRow,
} from '@emdgroup-liquid/liquid/dist/react';
import Card from 'components/Card';
import LoadingOverlay from './LoadingOverlay';
import { getResult } from 'util/experiments';

import { useHints } from 'hooks/useHints';
import useRecommendations from '../hooks/useRecommendations';
import useProjectDetails from '../hooks/useProjectDetails';
import AddInputForm from './AddInputForm';
import { UploadInputValuesModal } from 'components/ChemicalSpace/UploadInputValues/UploadInputValuesModal';
import { UploadInputValues } from 'components/ChemicalSpace/UploadInputValues/UploadInputValues';
import { Parameter, TargetVariable } from 'types/api/types';
import { ParameterInputSelection } from './ParameterInputSelection';
interface Props {
  projectId: string;
  remainingExperiments?: number;
}

const ExperimentsForm: React.FC<Props> = ({
  projectId,
  remainingExperiments = 0,
}) => {
  const {
    input,
    removeInput,
    updateResults,
    updateParams,
    submit,
    addOwnBulkData,
    clearInputs,
  } = useRecommendations(projectId);

  const {
    updateParameter,
    project,
    isBusy,
    isLoading: isSubmitLoading,
  } = useProjectDetails(projectId);

  useEffect(() => {
    return () => {
      clearInputs();
    };
  }, []);

  const [isImportModalVisible, setIsImportModalVisible] = useState(false);

  const inputsArray = Object.keys(input).map((key) => ({ key, ...input[key] }));

  const { getToolTipById } = useHints();

  const handleChange = (func: (arg0: string) => void) => {
    return (e: FormEvent<HTMLLdInputElement>) => {
      const { value } = e.target as HTMLLdInputElement;
      if (value === undefined) return;
      func(value);
    };
  };

  const hasInvalidTargets = (
    inputResults: Record<string, number>,
    projectTargets: TargetVariable[]
  ): boolean =>
    !!projectTargets.find(({ targetName }) =>
      isNaN(Number(inputResults[targetName]))
    );

  const isSubmitDisabled = useMemo<boolean>(() => {
    if (!project?.targets) return true;
    if (!project?.batchSize || remainingExperiments < project?.batchSize) {
      return true;
    }

    const { targets } = project;

    return !!Object.values(input).find(({ results }) =>
      hasInvalidTargets(results as Record<string, number>, targets)
    );
  }, [input, project]);

  const [selection, setSelection] = useState<
    { [key: string]: { [name: string]: string } } | undefined
  >();

  const handleDropdownChange =
    (key: string, parameter: Parameter) =>
    (param: { value: any; isNew?: boolean | undefined }) => {
      const { value, isNew = false } = param;
      updateParams(key, parameter.parameterName, value);
      if (isNew) {
        onAddParamChange(parameter, value);
      }
    };

  const onAddParamChange = (parameter: Parameter, value: string) => {
    updateParameter(projectId, {
      ...parameter,
      parameterValues: [...parameter.parameterValues, value],
    });
  };

  const handleSubmit = () => {
    if (project?.batchSize && remainingExperiments >= project?.batchSize)
      submit();
  };

  useEffect(() => {
    if (project) {
      const selection = inputsArray.reduce((prev, current) => {
        const object: any = {};
        const key = current.key;

        project.parameterSpace.forEach((param) => {
          const name: string = param['parameterName'];
          object[name] = (current.parameters as Record<string, any>)[name];
        });

        return Object.assign(prev, {
          [key]: { ...object },
        });
      }, {});
      setSelection(selection);
    }
  }, [project?.parameterSpace, input]);

  if (!project) return null;
  const { parameterSpace, targets } = project;

  return (
    <Card className=" mt-8 p-4 w-full relative">
      <>
        {isBusy && <LoadingOverlay />}
        <div className="w-full text-right">
          {!isBusy && getToolTipById('Inp_1')}
        </div>
        <div className={`w-full ${isBusy && 'blur-sm'}`}>
          <LdTable className="table-auto min-w-full">
            <LdTableHead>
              <LdTableRow>
                {parameterSpace.map((p) => (
                  <LdTableHeader
                    className="px-4 text-left"
                    key={`experiments_header_${p.parameterName}`}
                  >
                    {p.parameterName}
                  </LdTableHeader>
                ))}
                {targets.map((t) => (
                  <LdTableHeader className="px-4 text-left" key={t.targetName}>
                    {t.targetName}
                  </LdTableHeader>
                ))}
              </LdTableRow>
            </LdTableHead>
            <LdTableBody className="divide-y divide-sensitive-grey-darker">
              {inputsArray.map(({ key, results }, index) => {
                return (
                  <LdTableRow key={`inputs_${key}_${index}`}>
                    {parameterSpace.map((p) => (
                      <LdTableCell
                        className="py-1 px-4 text-left"
                        key={`inputs_${key}_${p.parameterName}`}
                      >
                        <ParameterInputSelection
                          parameter={p}
                          selected={selection?.[key]?.[
                            p.parameterName
                          ]?.toString()}
                          onChange={handleDropdownChange(key, p)}
                        />
                      </LdTableCell>
                    ))}
                    {targets.map(({ targetName }) => {
                      const fieldName = `${key}_${targetName}`;

                      return (
                        <LdTableCell
                          className="py-1 px-4 text-left"
                          key={`inputs_${fieldName}`}
                        >
                          <LdInput
                            className="w-12"
                            type="number"
                            size="sm"
                            name={targetName}
                            onInput={handleChange(
                              updateResults({ key, target: targetName })
                            )}
                            value={`${getResult(results, targetName)}`}
                            disabled={isSubmitLoading}
                          />
                        </LdTableCell>
                      );
                    })}
                    <LdTableCell className="py-1 text-right">
                      <LdButton
                        size="sm"
                        mode="danger"
                        onClick={() => removeInput(key)}
                        disabled={isSubmitLoading}
                      >
                        <LdIcon name="bin" />
                      </LdButton>
                    </LdTableCell>
                  </LdTableRow>
                );
              })}
              <AddInputForm
                projectId={projectId}
                parameters={parameterSpace}
                targets={targets}
                onAddParamChange={onAddParamChange}
              />
            </LdTableBody>
          </LdTable>

          <div className="py-4 flex justify-end">
            <LdButton
              onClick={() => setIsImportModalVisible(true)}
              type="button"
              className="px-4"
            >
              Import
            </LdButton>
            <LdButton
              onClick={!isSubmitLoading ? handleSubmit : undefined}
              disabled={isSubmitDisabled}
              type="button"
              progress={isSubmitLoading ? 'pending' : undefined}
            >
              Submit Results
            </LdButton>
          </div>

          <UploadInputValuesModal
            isVisible={isImportModalVisible}
            onClose={() => setIsImportModalVisible(false)}
          >
            <UploadInputValues
              // @ts-ignore
              parameters={project.parameterSpace}
              targets={targets}
              onCancel={() => setIsImportModalVisible(false)}
              onSubmit={(inputs) => {
                addOwnBulkData(inputs);
                setIsImportModalVisible(false);
              }}
            />
          </UploadInputValuesModal>
        </div>
      </>
    </Card>
  );
};

export default ExperimentsForm;
