import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'store';
import { listExperimentsForProject } from 'services/experiments';

import { Experiment, Project } from 'types/api/types';

import { SpaceDetails } from 'components/ChemicalSpace';

interface Props {
  project: Project;
  experiments?: Experiment[];
}

const ProjectItem: React.FC<Props> = ({ project }) => {
  const { projectId, ...rest } = project;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { experiments } = useSelector((state) => state.experiments);

  useEffect(() => {
    if (experiments[projectId] === undefined)
      dispatch(listExperimentsForProject(projectId));
  }, []);

  return (
    <SpaceDetails
      key={projectId}
      projectId={projectId}
      onClick={() => navigate(`/projects/${projectId}`)}
      experiments={experiments[projectId]}
      {...rest}
    />
  );
};

export default ProjectItem;
